import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';

import { MenuUnfoldOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MenuIcon as icons } from '../../atoms/Icon/MenuIcon';

// eslint-disable-next-line react/prop-types
function CmsLeftSideView({
  menues,
  menuData,
  collapsed,
  handleCollapse,
  handleMenuClick,
}) {
  return (
    <StyledSider
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      width={250}
    >
      <StyledLogo collapsed={collapsed}>
        <Link to="/cms/index">{collapsed === true ? 'BN' : 'BANEUNG CMS'}</Link>
      </StyledLogo>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuOutlined, {
        className: 'trigger',
        onClick: () => handleCollapse(!collapsed),
      })}
      <StyledMenu
        theme="dark"
        defaultSelectedKeys={['1']}
        mode="inline"
        onClick={handleMenuClick}
        inlineIndent={0}
      >
        {menues &&
          menuData(menues).map(m => {
            const MenuIcon = icons(m.menuNo);

            /* eslint no-else-return: "off" */
            if (
              m.children &&
              Array.isArray(m.children) &&
              m.children.length > 0
            ) {
              return (
                <SubMenu
                  key={m.menuNo}
                  title={m.menuNm}
                  icon={MenuIcon && <MenuIcon />}
                >
                  {m.children.map(c => (
                    <Menu.Item key={c.menuNo} selectedKeys={[c.menuNo]}>
                      <Link to={`/cms${c.menuUrl}`}>{c.menuNm}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item
                  key={m.menuNo}
                  selectedKeys={[m.menuNo]}
                  icon={MenuIcon && <MenuIcon />}
                >
                  <Link to={`/cms${m.menuUrl}`}>{m.menuNm}</Link>
                </Menu.Item>
              );
            }
          })}
        <Menu.Divider />
      </StyledMenu>
    </StyledSider>
  );
}

const { Sider } = Layout;
const { SubMenu } = Menu;

const StyledSider = styled(Sider)`
  height: 100vh;
  position: fixed;
  z-index: 15;
  .trigger {
    position: absolute;
    top: 1.6rem;
    right: -1.6rem;
    padding: 0.8rem;
    border-radius: 0.25rem;
    background: var(--color-white);
    box-shadow: 1px 1px 1px var(--color-black-rgba-10);
    svg {
      color: var(--color-black);
    }
  }
`;
const StyledLogo = styled.h1`
  margin-bottom: 0;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6.4rem;
    color: var(--color-white);
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    background-color: var(--color-primary);
    ${props =>
      props.collapsed &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  }
`;
const StyledMenu = styled(Menu)`
  &.ant-menu-vertical,
  &.ant-menu-inline {
    height: calc(100vh - 6rem);
    padding: 0 2rem;
    box-shadow: inset 0px 7px 13px var(--color-black-rgba-50);
    overflow-y: auto;
    li {
      padding-left: 0;
      & + li {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
      ul {
        li {
          padding-left: 16px !important;
        }
      }
    }
  }
  .ant-menu-item-divider {
    border-top-width: 0;
  }
`;

CmsLeftSideView.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  handleCollapse: PropTypes.func.isRequired,
};

export default CmsLeftSideView;
