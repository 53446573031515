import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select } from 'antd';

const { Option } = Select;

const selectItem = option => {
  const { name, placeholder, codes } = option;
  return (
    <Form.Item key={name} name={name}>
      <Select placeholder={placeholder} dropdownMatchSelectWidth={false}>
        {codes.map(code => {
          const { value, text } = code;
          return (
            <Option value={value} key={value}>
              {text}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

const inputItem = option => {
  const { name, placeholder } = option;
  return (
    <Form.Item key={name} name={name}>
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

const hiddenItem = option => {
  const { name } = option;
  return (
    <Form.Item key={name} name={name}>
      <Input type="hidden" />
    </Form.Item>
  );
};

function SearchBarView({ options, onSearch }) {
  const { initialValues, items } = options;
  return (
    <Form
      name="searchForm"
      onFinish={onSearch}
      initialValues={{
        ...initialValues,
      }}
      style={{ display: 'flex', gap: 5, height: 33 }}
    >
      {items.map(option => {
        const { type } = option;
        if (type === 'select') return selectItem(option);
        if (type === 'input') return inputItem(option);
        if (type === 'hidden') return hiddenItem(option);
        return null;
      })}
      <Button type="primary" htmlType="submit">
        조회
      </Button>
    </Form>
  );
}

export default SearchBarView;

SearchBarView.propTypes = {
  options: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ).isRequired,
  onSearch: PropTypes.func.isRequired,
};
