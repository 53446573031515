import React, { useEffect } from 'react';

/**
 * 이벤트 핸들러 바인딩 함수
 * @param {*} eventType 이벤트 구분 타입
 * @param {*} listener 핸들러
 */
function useStorageEvent(eventType, listener) {
  useEffect(() => {
    window.dispatchEvent(new Event('storage'));
  }, []);

  useEffect(() => {
    window.addEventListener(eventType, listener);

    return () => {
      window.removeEventListener(eventType, listener);
    };
  }, [eventType, listener]);
}
export default useStorageEvent;
