import React, { useEffect } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';

const RequireAuth = ({ children, redirectTo }) => {
  const { isAuthenticated } = useSession();

  if (!isAuthenticated) {
    const query = qs.stringifyUrl({
      url: redirectTo,
      query: {
        continueUrl: window.location.href,
      },
    });
    return <Navigate to={query} />;
  }

  return children;
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
  redirectTo: PropTypes.string,
};

RequireAuth.defaultProps = {
  redirectTo: '/login',
};

export default RequireAuth;
