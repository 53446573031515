import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Button,
  Form,
  Input,
  Switch,
  Popconfirm,
  Space,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import {
  DeleteOutlined,
  // PlusOutlined
} from '@ant-design/icons';

import SearchBar from '../../templates/SearchBar';
import StyledDrawer from '../../atoms/StyledDrawer';

function SiteView({
  form,
  list,
  paging,
  visible,
  selectedRows,
  isEdit,
  columnDefs,
  gridOptions,
  searchOption,
  onPaging,
  onRowClick,
  onCellClick,
  onGridReady,
  onDraw,
  onSelectionChanged,
  onFinish,
  onReset,
  onSearch,
  onDelete,
  onMoveList,
  onGridSizeChanged,
}) {
  return (
    <>
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={18}>
              {/* SearchBar */}
              <SearchBar options={searchOption} onSearch={onSearch} />
              {/* <Form
                name="searchForm"
                onFinish={values => {
                  onSearch(values);
                }}
                initialValues={{
                  searchCondition: '0',
                  searchKeyword: '',
                }}
                style={{ display: 'flex', gap: 5 }}
              >
                <Form.Item label="" name="searchCondition" noStyle>
                  <Select>
                    <Option value="0">사이트명</Option>
                    <Option value="1">도메인</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="" name="searchKeyword" noStyle>
                  <Input
                    placeholder="검색어를 입력하세요"
                    style={{ width: 200, minWidth: 150 }}
                  />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  조회
                </Button>
              </Form> */}
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Space>
                <Button
                  type="primary"
                  // icon={<PlusOutlined />}
                  onClick={() => {
                    onReset();
                    onDraw(true);
                  }}
                  ghost
                >
                  등록
                </Button>
                <Popconfirm
                  title="미사용 처리하시겠습니까?"
                  onConfirm={() => {
                    onDelete();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                  disabled={selectedRows.length < 1}
                >
                  <Button
                    type="danger"
                    htmlType="button"
                    disabled={selectedRows.length < 1}
                    icon={<DeleteOutlined />}
                  >
                    미사용
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* <div className="ag-theme-alpine" style={{ height: 470 }}> */}
          <div
            className="ag-theme-alpine"
            style={{ height: 'calc((100vh - 18.5rem) - 20.5rem)' }}
          >
            <AgGridReact
              rowData={list}
              columnDefs={columnDefs}
              {...gridOptions}
              onGridReady={onGridReady}
              onRowClicked={onRowClick}
              onCellClicked={onCellClick}
              onSelectionChanged={onSelectionChanged}
              onGridSizeChanged={onGridSizeChanged}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
          <Pagination
            pageSize={paging?.pageSize ?? 10}
            current={paging?.currentPageNo ?? 1}
            total={paging?.totalRecordCount ?? 0}
            onChange={onPaging}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'right' }}>
          <Button
            type="primary"
            onClick={() => {
              onReset();
              onDraw(true);
            }}
          >
            사이트 등록
          </Button>
        </Col>
      </Row> */}
      <StyledDrawer
        title={`사이트 ${isEdit ? '수정' : '등록'}`}
        placement="right"
        width="40vw"
        open={visible}
        onClose={() => {
          onDraw(false);
          onMoveList();
        }}
        footer={[
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                key="btn2"
                disabled={isEdit}
                htmlType="button"
                onClick={() => {
                  onReset(isEdit);
                }}
              >
                초기화
              </Button>
            </Col>
            <Col>
              <Space>
                <Popconfirm
                  key="btn1"
                  title="저장 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    form.submit();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    // onClick={() => form.submit()}
                  >
                    저장
                  </Button>
                </Popconfirm>
                <Button
                  key="btn3"
                  onClick={() => {
                    onDraw(false);
                    onMoveList();
                  }}
                >
                  닫기
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFieldsChange={changedFields => {
          //   console.log('🚀 ~ changedFields', changedFields);
          // }}
          // onValuesChange={changedValues => {
          //   console.log('🚀 ~ changedValues', changedValues);
          // }}
        >
          {/* Hidden value */}
          <Form.Item hidden name="siteId">
            <Input type="hidden" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="사이트명"
                name="siteNm"
                rules={[
                  { required: true, message: '사이트 이름을 입력해주세요.' },
                ]}
                tooltip="사이트 이름을 입력해주세요."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="도메인"
                name="domain"
                rules={[{ required: true, message: '도메인을 입력해주세요.' }]}
                tooltip="도메인을 입력해주세요."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="사이트 설명"
                name="siteDc"
                rules={[
                  { required: true, message: '사이트 설명을 입력해주세요.' },
                ]}
                tooltip="사이트 설명을 입력해주세요."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="활성여부"
                name="actvtyAt"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="사용여부" name="useAt" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDrawer>
      {/* <div>
        <pre>
          <div>selectedRows : {JSON.stringify(selectedRows, null, 2)}</div>
          <div>
            form.getFieldsValue(){' : '}
            {JSON.stringify(form.getFieldsValue(true), null, 2)}
          </div>
        </pre>
      </div> */}
    </>
  );
}

SiteView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  visible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
};

SiteView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  visible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
};

export default SiteView;
