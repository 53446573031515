/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import {
  getToken,
  decode,
  persistToken,
} from '../../config/utils/token-resolver';

/** 초기 값 */
const initialState = {
  accessToken: null,
  refreshToken: null,
  userinfo: null,
};

/** 슬라이스 인스턴스 */
const authSlice = createSlice({
  name: 'auth',
  initialState: () => {
    const { accessToken } = getToken();
    let userinfo = null;

    if (accessToken) {
      try {
        userinfo = decode(accessToken);
      } catch (e) {
        userinfo = null;
      }
    }
    return {
      ...initialState,
      accessToken,
      userinfo,
    };
  },
  reducers: {
    setToken(state, action) {
      state.accessToken = action.payload.accessToken;
      state.userinfo = action.payload.userinfo;
      persistToken({
        ...action.payload,
      });
    },
  },
});

/** actions */
export const { actions: authActions } = authSlice;

/** reducer */
export default authSlice.reducer;
