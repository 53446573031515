import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Select,
  Checkbox,
  Switch,
  Image,
  Button,
  Upload,
  Row,
  Col,
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';

const CheckboxGroup = Checkbox.Group;

function ManageForm({ form, authorList, sites, attach }) {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [menuImage, setMenuImage] = useState('');

  useEffect(() => {
    setMenuImage(form.getFieldValue('relateImagePath'));
  });

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="right"
      form={form}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="menuNo" key="menuNo" hidden />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="상위코드"
            name="upperMenuNo"
            key="upperMenuNo"
            hidden
          >
            <Input readOnly disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="메뉴명"
            tooltip="메뉴명을 입력 해주세요."
            name="menuNm"
            rules={[
              {
                required: true,
                message: '메뉴명을 입력 해주세요.',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="메뉴 권한"
            tooltip="메뉴 권한을 선택 해주세요."
            required
            name="menuRole"
            rules={[
              {
                required: true,
                message: '메뉴 권한을 선택 해주세요.',
              },
            ]}
          >
            <CheckboxGroup options={authorList} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="사이트 ID"
            tooltip="사이트 ID를 선택 해주세요."
            name="siteId"
          >
            <Select>
              {sites.map(site => (
                <Select.Option key={site.siteId}>{site.siteNm}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="메뉴 경로"
            tooltip="메뉴 경로를 입력 해주세요."
            name="menuUrl"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="메뉴 설명"
            tooltip="메뉴 설명을 입력 해주세요."
            name="menuDc"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="메뉴 순서"
            tooltip="메뉴 순서를 입력 해주세요."
            name="menuOrder"
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.atchFile !== curValues.atchFile
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const atchFile = getFieldValue('atchFile');
              const disabled = atchFile && atchFile.length > 0;
              return (
                <Form.Item
                  name="atchFile"
                  label="메뉴 상단 이미지"
                  valuePropName="fileList"
                  getValueFromEvent={e => e.fileList}
                >
                  <Upload
                    accept="image/*"
                    customRequest={data => {
                      setTimeout(() => {
                        data.onSuccess({ status: 'ok' }, null);
                      }, 0);
                    }}
                  >
                    <Button disabled={disabled}>
                      <UploadOutlined /> 클릭 업로드
                    </Button>
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      {menuImage && menuImage.length > 0 && (
        <Row>
          <Col span={24}>
            <Form.Item
              label="배경 이미지"
              tooltip="메뉴 배경 이미지"
              name="relateImagePath"
            >
              <Image src={menuImage} />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={8}>
          <Form.Item
            labelCol={{ span: 14 }}
            label="사이트 맵핑 여부"
            tooltip="사이트와 맵핑된 메뉴는 설정해 주세요."
            name="siteAt"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            labelCol={{ span: 14 }}
            label="GNB 표기"
            tooltip="GNB에 사용 여부를 설정해 주세요."
            name="gnbAt"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            labelCol={{ span: 14 }}
            label="사용여부"
            tooltip="사용 여부를 설정해 주세요."
            name="useAt"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

ManageForm.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  authorList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  sites: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

ManageForm.defaultProps = {
  form: {},
  authorList: [],
  sites: [],
};

export default ManageForm;
