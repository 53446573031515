/* eslint no-unneeded-ternary : 'off' */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Drawer,
  Button,
  Form,
  Input,
  Switch,
  Popconfirm,
  Space,
  DatePicker,
  Select,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import {
  DeleteOutlined,
  // PlusOutlined,
  // PlusSquareOutlined,
  // PlusCircleOutlined,
} from '@ant-design/icons';

import SearchBar from '../../templates/SearchBar';
import StyledDrawer from '../../atoms/StyledDrawer';
import JoditEditor from '../../molecules/JoditEditor';
import SurveyItemChoiceTable from '../../molecules/SurveyItemChoiceTable';

const { Option } = Select;
const { RangePicker } = DatePicker;

function SurveyView({
  form,
  list,
  paging,
  visible,
  selectedRows,
  isEdit,
  columnDefs,
  gridOptions,
  searchOption,
  choiceId,
  onPaging,
  onRowClick,
  onCellClick,
  onGridReady,
  onDraw,
  onSelectionChanged,
  onFinish,
  onReset,
  onSearch,
  onDelete,
  onMoveList,
  onGridSizeChanged,
  onChoiceId,
}) {
  return (
    <>
      <div style={{ display: choiceId ? 'none' : 'block' }}>
        <Row>
          <Col span={24}>
            <Row style={{ marginBottom: 10 }}>
              <Col span={18}>
                {/* SearchBar */}
                <SearchBar options={searchOption} onSearch={onSearch} />
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Space>
                  <Button
                    type="primary"
                    // icon={<PlusCircleOutlined />}
                    onClick={() => {
                      onReset();
                      onDraw(true);
                    }}
                    ghost
                  >
                    등록
                  </Button>
                  <Popconfirm
                    title="미사용 처리하시겠습니까?"
                    onConfirm={() => {
                      onDelete();
                    }}
                    onCancel={() => {}}
                    okText="저장"
                    cancelText="취소"
                    disabled={selectedRows.length < 1}
                  >
                    <Button
                      type="danger"
                      htmlType="button"
                      disabled={selectedRows.length < 1}
                      icon={<DeleteOutlined />}
                    >
                      미사용
                    </Button>
                  </Popconfirm>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="ag-theme-alpine" style={{ height: 470 }}>
              <AgGridReact
                rowData={list}
                columnDefs={columnDefs}
                {...gridOptions}
                onGridReady={onGridReady}
                onRowClicked={onRowClick}
                onCellClicked={onCellClick}
                onSelectionChanged={onSelectionChanged}
                onGridSizeChanged={onGridSizeChanged}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
            <Pagination
              pageSize={paging?.pageSize ?? 10}
              current={paging?.currentPageNo ?? 1}
              total={paging?.totalRecordCount ?? 0}
              onChange={onPaging}
            />
          </Col>
        </Row>
        <StyledDrawer
          title={`설문지관리 ${isEdit ? '수정' : '등록'}`}
          placement="right"
          width="40vw"
          open={visible}
          onClose={() => {
            onDraw(false);
            onMoveList();
          }}
          footer={[
            <Row align="middle" justify="space-between">
              <Col>
                <Button
                  key="btn2"
                  disabled={isEdit}
                  htmlType="button"
                  onClick={() => {
                    onReset(isEdit);
                  }}
                >
                  초기화
                </Button>
              </Col>
              <Col>
                <Space>
                  <Popconfirm
                    key="btn1"
                    title="저장 하시겠습니까?"
                    placement="topRight"
                    onConfirm={() => {
                      form.submit();
                    }}
                    onCancel={() => {}}
                    okText="저장"
                    cancelText="취소"
                  >
                    <Button
                      type="primary"
                      htmlType="button"
                      // onClick={() => form.submit()}
                    >
                      저장
                    </Button>
                  </Popconfirm>
                  <Button
                    key="btn3"
                    onClick={() => {
                      onDraw(false);
                      onMoveList();
                    }}
                  >
                    닫기
                  </Button>
                </Space>
              </Col>
            </Row>,
          ]}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            {/* Hidden Value */}
            <Form.Item name="qestnrId" noStyle>
              <Input hidden />
            </Form.Item>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="제목"
                  name="qustnrSj"
                  rules={[{ required: true, message: '제목을 입력해주세요.' }]}
                  tooltip="제목을 입력해주세요."
                >
                  <Input maxLength={120} disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="목적"
                  name="qustnrPurps"
                  rules={[{ required: true, message: '목적을 입력해주세요.' }]}
                  tooltip="목적을 입력해주세요."
                >
                  <Input maxLength={500} disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="대상"
                  name="qustnrTrget"
                  rules={[{ required: true, message: '대상을 입력해주세요.' }]}
                  tooltip="대상을 입력해주세요."
                >
                  <Input maxLength={500} disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="기간" name="qustnrRange">
                  <RangePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="진행상태" name="progrsSttus" initialValue="W">
                  <Select>
                    <Option value="W">대기</Option>
                    <Option value="P">진행</Option>
                    <Option value="E">종료</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="사용여부"
                  name="useAt"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label="안내내용"
                  name="qustnrWritngGuidanceCn"
                  rules={[{ max: 1000, message: '최대 길이는 1000자 입니다.' }]}
                  tooltip="안내내용을 입력해주세요."
                >
                  <JoditEditor visible={visible} removeButtons={['image']} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </StyledDrawer>
      </div>
      <div style={{ display: choiceId ? 'block' : 'none' }}>
        {/* 문항선택 Drawer */}
        {/* <Drawer
        title="문항선택"
        placement="bottom"
        height={500}
        visible={choiceId ? true : false}
        onClose={onChoiceId}
        closable={false}
        // maskClosable={true}
      > */}
        <SurveyItemChoiceTable choiceId={choiceId} onClose={onChoiceId} />
        {/* </Drawer> */}
      </div>
    </>
  );
}

SurveyView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  visible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  choiceId: PropTypes.string,
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
  onChoiceId: PropTypes.func,
};

SurveyView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  visible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  choiceId: '',
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
  onChoiceId: () => {},
};

export default SurveyView;
