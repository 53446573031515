import { createSlice } from '@reduxjs/toolkit';

import { defaultQuery as postApi } from '../../config/utils/network';

// initialStateValues
const initialStateValues = {
  sites: [],
  siteId: process.env.REACT_APP_DEFAULT_SITE_ID,
};

// slice
const siteSlice = createSlice({
  name: 'site',
  initialState: initialStateValues,
  reducers: {
    setSite: (state, action) => {
      state.sites = action.payload;
    },
    setSiteId: (state, action) => {
      state.siteId = action.payload;
    },
    perge: (state, action) => {
      state.sites = [];
      state.siteId = '';
    },
  },
});

// actions
export const { setSite, setSiteId, perge } = siteSlice.actions;

// selector
export const siteSelector = state => state.site;

// reducer
export default siteSlice.reducer;

// Asyncronous thunk action
export function fetchSites() {
  return async dispatch => {
    try {
      const { data } = await postApi('/api/admin/site/findSiteList', {});
      const sites = data.resultList;
      if (sites && Array.isArray(sites) && sites.length > 0) {
        dispatch(setSite(sites));
        dispatch(setSiteId(sites[0].siteId));
      }
    } catch (error) {
      console.error('🚀 ~ store/site/reducer.js fetchSites ~ error', error);
    }
  };
}
