/* eslint-disable import/no-cycle */
import axios from 'axios';
import * as jwt from 'jose';
import qs from 'query-string';
import { authActions } from '../../store/auth/reducer';
import store from '../../store/store';

import {
  ACCESS_TOKEN,
  JWT_ACCESS,
  JWT_REFRESH,
  JWT_LOGOUT,
  session,
} from '../contants';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URI, // API BaseUrl 지정
  timeout: 10000, // 타임아웃
  withCredentials: true, // 서로 다른 도메인에 요청을 보낼때 요청에 Credential 정보를 담아서 보낼지 결정하는 옵션
});

/**
 *
 * @returns jwt를 통해 검증 된 토큰 리턴
 */
export const getAccessToken = () => {
  try {
    const accessToken = session.getItem(ACCESS_TOKEN);
    decode(accessToken);
    return accessToken;
  } catch (e) {
    return null;
  }
};

/**
 * LocalStrage에 저장된 Token 정보를 리턴
 * @returns AccessToken, RefreshToken
 */
export const getToken = () => {
  const storeAccessToken = getAccessToken();
  return {
    accessToken: storeAccessToken,
  };
};

/**
 * localstore에 저장하는 정보
 * @param {*} accessToken accessToken 토큰 정보
 */
export const persistToken = ({ accessToken }) => {
  session.setItem(ACCESS_TOKEN, accessToken);
};

/**
 * jwt 토큰 decode
 * 만료시간 확인 후 문제시 Error Call
 * @param {*} jwtToken jwt 토큰
 * @returns decode 된 정보
 */
export const decode = jwtToken => {
  const decodeToken = jwt.decodeJwt(jwtToken);

  const { exp } = decodeToken;

  if (exp) {
    const currentTime = new Date().getTime();
    const expires = exp * 1000;

    if (expires < currentTime) {
      throw new Error('Jwt Expires must be greater than current time');
    }
  }

  return decodeToken;
};

/**
 * postAccessToken 요청 함수
 * @param {*} id 아이디
 * @param {*} password 패스워드
 * @param {*} useSe 유저 구분
 * @returns response
 */
export const postAccessToken = async ({ id, password, userSe }) => {
  const { data } = await client.post(
    `${JWT_ACCESS}`,
    qs.stringify({ id, password, userSe }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  return data;
};

/**
 * postRefreshToken 요청 함수
 * @returns response
 */
export const postRefreshToken = async () => {
  const { data } = await client.post(
    `${JWT_REFRESH}`,
    {},
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

  return data;
};

/**
 * postLogout 요청 함수
 * @returns response
 */
export const postLogout = async () => {
  const accessToken = getAccessToken(); // 토큰

  // 페이지 토큰 저장
  const { data } = await client
    .post(
      `${JWT_LOGOUT}`,
      {},
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    )
    .catch(function (error) {
      if (error.response) {
        // 현재 발생하고 있는 에러 2023-02-22 요청 후 응답을 못 받음.
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        // console.log(error.request);
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        // console.log('Error', error.message);
      }
      console.log(error.config);

      return error.config;
    });

  return data;
};

/**
 * AccessToken 검증부터 리프레시 토큰 발급까지...
 *
 * refresh token 유효검사까지 맞지 않으면 throw
 */
export const processGetAccessToken = async () => {
  try {
    const accessToken = getAccessToken();

    decode(accessToken);
    return accessToken;
  } catch (e) {
    console.warn(e);
  }

  try {
    const newToken = await postRefreshToken();

    store.dispatch(
      authActions.setToken({
        accessToken: newToken?.message?.accessToken ?? '',
        userinfo: decode(newToken?.message?.accessToken),
      }),
    );

    return newToken?.message?.accessToken;
  } catch (e) {
    window.location.href = '/login';
    throw new Error(`Invalid Token`);
  }
};
