import { RedoOutlined, SaveOutlined } from '@ant-design/icons';
import { Collapse, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const { Panel } = Collapse;

function ExpandIcon({ onSave, onRedo }) {
  const handleSave = e => {
    e.stopPropagation();
    if (onSave) {
      onSave();
    }
  };

  const handleRedo = e => {
    e.stopPropagation();
    if (onRedo) {
      onRedo();
    }
  };
  return (
    <>
      {onRedo && (
        <RedoOutlined
          style={{ paddingLeft: 10, fontSize: 14 }}
          onClick={handleRedo}
        />
      )}
      {onSave && (
        <Popconfirm
          title="저장하시겟습니까?"
          okText="저장"
          cancelText="취소"
          onConfirm={handleSave}
        >
          <SaveOutlined
            style={{ paddingLeft: 10, fontSize: 14 }}
            onClick={e => e.stopPropagation()}
          />
        </Popconfirm>
      )}
    </>
  );
}

ExpandIcon.propTypes = {
  onSave: PropTypes.func,
  onRedo: PropTypes.func,
};

ExpandIcon.defaultProps = {
  onSave: null,
  onRedo: null,
};

function CollapsePanel({ title, children, onSave, onRedo }) {
  const ExtraIcon = React.useCallback(
    (onSave, onRedo) => <ExpandIcon onSave={onSave} onRedo={onRedo} />,
    [],
  );

  return (
    <Collapse
      defaultActiveKey={['1']}
      expandIconPosition="right"
      collapsible="icon"
    >
      <Panel header={title} key={1} extra={ExtraIcon(onSave, onRedo)}>
        <div style={{ minHeight: 470 }}>{children}</div>
      </Panel>
    </Collapse>
  );
}

CollapsePanel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func,
  onRedo: PropTypes.func,
};

CollapsePanel.defaultProps = {
  onSave: null,
  onRedo: null,
};

export default CollapsePanel;
