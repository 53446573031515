import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Form, Modal, message } from 'antd';

import arrayToTree from 'array-to-tree';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import useStoreSite from '../../../hooks/useStoreSite';
import { fetchMenues } from '../../../store/menu/reducer';
import { getFileData } from '../../../config/utils/util';

import MenuView from './MenuView';

/**
 * 코드 관리
 * @returns
 */
function Menu() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [form] = Form.useForm();

  // initial search values
  const initialSearchValues = {
    menuNo: 1,
    pageIndex: 1,
    pageSize: 100,
    recordCountPerPage: 10,
    searchCondition: '',
    searchKeyword: '',
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);

  const [selectMenuNo, setSelectMenuNo] = useState();

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);
  // 상세정보
  const [detailData, setDetailData] = useState(null);

  /**
   * 사이트 목록 조회
   */
  const { sites = [] } = useStoreSite();

  /**
   * 메뉴 목록 조회
   */
  const { data = [], refetch: reloadList } = useQuery(
    '/api/admin/menu/selectMenuList',
    search,
    {
      cacheTime: 0,
      enabled: !!search,
      select: data => data.resultList,
      // onSuccess: () => {
      // form.setFieldsValue({
      //   upperMenuNo: '0',
      // });
      // },
    },
  );

  /**
   * 선택 된 메뉴정보 상세조회
   */
  const {
    data: nodeMenu,
    refetch: resetSelectMenu,
    dataUpdatedAt,
  } = useQuery(
    '/api/admin/menu/selectMenu',
    {
      menuNo: selectMenuNo,
    },
    {
      enabled: !!selectMenuNo,
      select: data => data.result,
      staleTime: 0,
    },
  );

  /**
   * 메뉴 권한정보를 가져온다.
   */
  const { data: authorList = [] } = useQuery(
    '/api/admin/author/authorList',
    {},
    {
      cacheTime: 0,
      select: data => data.resultList,
    },
  );

  /**
   * 메뉴 생성 Mutate
   */
  const addMutation = useMutation('/api/admin/menu/insertMenu');

  /**
   * 메뉴 저장 Mutate
   */
  const updateMutation = useMutation('/api/admin/menu/updateMenu');

  /**
   * API - 파일Upload
   * @TODO - 업로드 API 수정필요
   */
  const saveFilesMutation = useMutation(
    // '/api/admin/file/insertFiles',
    '/api/file/insertFiles',
    {},
    true,
  );

  /**
   * File Upload
   * @param {*} fileObj
   * @returns
   */
  const saveFiles = async files => {
    let res;
    try {
      const uploadFiles = files.map(file => {
        return file;
      });
      res = await saveFilesMutation.mutateAsync(uploadFiles);
    } catch (error) {
      console.error('saveFiles', error);
    }
    return res;
  };

  /**
   * 목록에서, 1개 메뉴를 선택한다.
   */
  const handleSelect = (_, { node }) => {
    setSelectMenuNo(node.menuNo);
  };

  /**
   * 작성 된 form 을 저장한다.
   */
  const handleSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    let imageFileId = '';
    const { atchFile, atchFileId, upperMenuNo } = values;

    let upperMenuNoYn = 'Y'; // 상위메뉴가 선택이 안됨 (1Depth 메뉴로 자동 세팅)
    if (upperMenuNo === undefined) {
      upperMenuNoYn = 'N';
    }

    if (upperMenuNo === 0) {
      message.warn('Root 메뉴는 수정 불가합니다.');
      return;
    }

    // File Upload
    if (atchFile && Array.isArray(atchFile)) {
      if (atchFile.length > 0) {
        if (atchFile[0].originFileObj) {
          try {
            const res = await saveFiles(atchFile);
            imageFileId = res.result.fileIds && res.result.fileIds[0];
          } catch (err) {
            console.error('saveFiles err', err.message);
            imageFileId = '';
          }
        }
      }
    }

    const dataValues = {
      ...values,
      upperMenuNo: upperMenuNoYn === 'Y' ? values.upperMenuNo : 1,
      imageFileId,
      menuRole: values.menuRole.map(a => {
        return {
          menuRole: a,
        };
      }),
      gnbAt: values.gnbAt ? 'Y' : 'N',
      siteAt: values.siteAt ? 'Y' : 'N',
      useAt: values.useAt ? 'Y' : 'N',
    };

    if (isEdit) {
      const sendValues = {
        ...dataValues,
        lastUpdusrId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // 리로드
                reloadList();
                resetSelectMenu();
                dispatch(fetchMenues());
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          // handleReset(isEdit); (수정 후, 화면에 입력한 값 유지)
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
        frstRegisterId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          // console.log('🚀 ~ addMutation ~ onSuccess: ~ res', res);
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                reloadList();
                dispatch(fetchMenues());
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          // handleReset(isEdit);
        },
      });
    }
  };

  /**
   * 상세정보 form 을 리셋 시킨다.
   */
  const handleReset = (_isEdit = false) => {
    if (_isEdit) {
      // 수정 -> 새로고침 -> 기존 데이터 셋
      form.setFieldsValue({ ...detailData });
    } else {
      // 초기 등록시
      form.resetFields();
      form.setFieldsValue({
        upperMenuNo: 0,
      });
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  /**
   * 하위메뉴를 추가하기 위해, 상위메뉴를 form 으로 설정한다.
   */
  const handleAddSubMenu = parentMenuId => {
    form.resetFields();
    form.setFields([
      {
        name: 'upperMenuNo',
        value: parentMenuId,
        errors: [''],
      },
    ]);

    // 하위 메뉴 추가는 생성
    setIsEdit(false);

    setTimeout(() => {
      const { input } = form.getFieldInstance('upperMenuNo');
      input.style.border = '2px solid red';
      setTimeout(() => {
        input.removeAttribute('style');
      }, 1000);
    }, 200);
  };

  /**
   * Array to Tree
   * 외부 라이브러리 사용하여 구현
   */
  const menuData = useMemo(() => {
    const convertTreeProperty = data.map(item => ({
      ...item,
      id: item.menuNo,
      parent_id: item.upperMenuNo,
    }));
    return arrayToTree(convertTreeProperty);
  }, [data]);

  /**
   * Array to Tree
   * 외부 라이브러리 사용하여 구현
   */
  const authors = useMemo(() => {
    const convertTreeProperty = authorList.map(author => ({
      ...author,
      label: author.authorNm,
      value: author.authorCode,
    }));
    return convertTreeProperty;
  });

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    if (nodeMenu) {
      const viewData = {
        ...nodeMenu,
        atchFile: getFileData(nodeMenu.imageFileId, nodeMenu.relateImagePath),
        menuRole: nodeMenu.menuRole.map(a => a.menuRole),
        relateImagePath: `${
          nodeMenu.relateImagePath.length > 0
            ? process.env.REACT_APP_STORAGE_URI.concat(nodeMenu.relateImagePath)
            : ''
        }`,

        gnbAt: nodeMenu.gnbAt === 'Y',
        siteAt: nodeMenu.siteAt === 'Y',
        useAt: nodeMenu.useAt === 'Y',
      };

      // Set form Fileds value
      form.setFieldsValue({
        ...viewData,
      });

      // 상세정보 세팅
      setDetailData({ ...viewData });
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [nodeMenu, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    list: (data || {}).resultList || [],
    form,
    sites,
    isEdit,
    menuData,
    nodeMenu,
    authorList: authors,
    onSelect: handleSelect,
    onSave: handleSave,
    onReset: handleReset,
    onAddSubMenu: handleAddSubMenu,
  };

  return <MenuView {...props} />;
}

export default Menu;
