import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import arrayToTree from 'array-to-tree';

import { menuSelector } from '../../../store/menu/reducer';
import CmsLeftSideView from './CmsLeftSideView';
import { setMenuInfo } from '../../../store/keep/reducer';

/**
 * 사이드 메뉴
 * @returns
 */
function CmsLeftSide({ collapsed, onCollapse, onSetNagation }) {
  const dispatch = useDispatch();
  const { menues } = useSelector(menuSelector);

  /**
   * Temp Move page
   * @param {*} param0
   */
  const handleMenuClick = ({ key }) => {
    const menu = menues.filter(m => m.menuNo.toString() === key);
    const parent =
      menu &&
      menu.length > 0 &&
      menues.filter(m => m.menuNo === menu[0].upperMenuNo);

    let menuInfo = {};

    if (parent && parent.length > 0 && parent[0].upperMenuNo) {
      onSetNagation && onSetNagation({ p: parent, m: menu });
      menuInfo = { p: parent[0], m: menu[0] };
    } else {
      onSetNagation && onSetNagation({ m: menu });
      menuInfo = { m: menu[0] };
    }

    // 메뉴 store 저장
    dispatch(setMenuInfo(menuInfo));
  };

  /**
   * 배열을 tree 구조로 변환
   */
  const menuData = data => {
    const convertTreeProperty = data.map(item => ({
      ...item,
      id: item.menuNo,
      parent_id: item.upperMenuNo,
    }));
    return arrayToTree(convertTreeProperty);
  };

  const props = {
    menues,
    menuData,
    collapsed,
    handleCollapse: onCollapse,
    handleMenuClick,
  };

  return <CmsLeftSideView {...props} />;
}

export default CmsLeftSide;
