// Grid Link Cell Style
export const GRID_LINK_CELL_STYLE = {
  // color: '#1890FF',
  color: '#2abfb8',
  // fontWeight: 'bold',
  cursor: 'pointer',
  // whiteSpace: 'normal',
  // lineHeight: '1.5rem',
};

export const BBS_ID = 'BBSMSTR_000000000091';

export const STORAGE = 'storage';
export const TOKEN_PREFIX = 'BN'; // 고유 구분자
export const ACCESS_TOKEN = `${TOKEN_PREFIX}.AccessToken`; // AccessToken 지정 상수
export const JWT_ACCESS = `${process.env.REACT_APP_API_URI}/api/auth/token`; // Access Token Url
export const JWT_REFRESH = `${process.env.REACT_APP_API_URI}/token/refresh`; // Refresh Token Url
export const JWT_LOGOUT = `${process.env.REACT_APP_API_URI}/token/logout`; // Refresh Token Url
export const session = sessionStorage; // 세션 Storage를 이용하여 로그인을 처리한다.
export const local = localStorage; // 로컬 Storage를 이용하여 로그인을 처리한다.

export const LOGIN_INFORM = `${TOKEN_PREFIX}.LOGIN_INFORM`; // 바능 로그인 저장 정보

// export const JWT_END_POINT = `${process.env.REACT_APP_API_URI}/api/auth/token`; // CMS API 서버 Url 정의

// LocalStorage 핸들링
export const REQUESTING_SHARED_CREDENTIALS = 'REQUESTING_SHARED_CREDENTIALS'; // CREDENTIALS 쉐어 트리거
export const CREDENTIALS_SHARING = 'CREDENTIALS_SHARING'; // CREDENTIALS 쉐어
export const CREDENTIALS_FLUSH = 'CREDENTIALS_FLUSH'; // CREDENTIALS 삭제
