import React from 'react';

const visitorIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 300"
      width="406"
      height="306"
    >
      <rect x="62.83" y="83.99" width="114.68" height="101.98" fill="#e6e6e6" />
      <path
        d="M140.53,132.29s-.69,24.54,10.71,29.4c14.2,6.05,30-7.54,36.78-14.47a12.78,12.78,0,0,1,7.36-3.73,10.37,10.37,0,0,0,6.48-3.2c3.88-4.34-4.57-8.23-8-5a2.17,2.17,0,0,0-1.14-3.11c-1.12-.6-2,.92-2.65,2.63a9,9,0,0,1-5.42,5.33l-15.51,5.5a6.29,6.29,0,0,1-8.49-6.92l1.32-7.77Z"
        fill="#f4a28c"
      />
      <path
        d="M145.17,94.59s20.92,8.92,17.72,41.59l-20.44-1.76Z"
        fill="#2abfb8"
      />
      <path
        d="M145.17,94.59s20.92,8.92,17.72,41.59l-20.44-1.76Z"
        fill="#fff"
        opacity="0.36"
      />
      <polygon
        points="123.4 66.61 117.61 87.05 129.1 89.44 128.71 73.61 123.4 66.61"
        fill="#f4a28c"
      />
      <path
        d="M128.94,77.07a7.64,7.64,0,0,1-3.5-2.7s-.3,3.88,3.49,8.2Z"
        fill="#ce8172"
        opacity="0.31"
      />
      <path
        d="M117.61,87.05l6.81,1.42s31.2,3.36,32.46,21S140.53,172,140.53,172H93.26S55.4,82.4,117.61,87.05Z"
        fill="#2abfb8"
      />
      <path
        d="M135.31,67.21s-.58,6.46-2.4,10.5a3.27,3.27,0,0,1-4.33,1.61c-2-.95-4.48-2.81-4.59-6.31l-.92-5.92a5.82,5.82,0,0,1,3.62-5.67C130.75,59.47,135.86,63.29,135.31,67.21Z"
        fill="#f4a28c"
      />
      <path
        d="M103.64,95.26s16.17,9.39,12.42,30.64-10,28.55,3.34,25.58,18.82-9.66,18.82-9.66,9.59,7.83,0,13.77S115.6,161.52,113.54,172H93.26s-1.64-4.6-3.81-10.41S103.64,95.26,103.64,95.26Z"
        opacity="0.09"
      />
      <path
        d="M133.68,67.19a25.85,25.85,0,0,1-5.81-1.5,5.39,5.39,0,0,1-1,5.84,4.41,4.41,0,0,1-5.55.8l1.56-8.18a6.61,6.61,0,0,1,4.33-5.21,23.33,23.33,0,0,1,3-.85c2.52-.53,5.7,1.66,8.1.44A1.57,1.57,0,0,1,140.53,60c-.08,2.56-1.22,6.41-4.84,7.17A6.16,6.16,0,0,1,133.68,67.19Z"
        fill="#ffd200"
      />
      <path
        d="M127.55,71.47s.39-2.47-1.49-2.61-2.46,3.42,0,4.2Z"
        fill="#f4a28c"
      />
      <path
        d="M78.71,132.81s-2.66,27.29,10.74,28.81c10.11,1.15,26.33-4.55,36.24-7.38,4.29-1.22,6.37-1.79,9.66-4.8,4-3.61,9.43-6.18,5.17-9.61-7.57-6.09-12.93,4.34-12.93,4.34a83.59,83.59,0,0,1-19.41,3.23,7.93,7.93,0,0,1-7.88-10l2.51-9.4S85.07,111.39,78.71,132.81Z"
        fill="#f4a28c"
      />
      <path
        d="M134.74,71.26,136.08,74a1,1,0,0,1-.93,1.49l-2.52,0Z"
        fill="#f4a28c"
      />
      <path
        d="M92.63,93.12c-6.92,5.35-17.44,17.17-17.24,39.48L104,134.42s8.26-24.49-.39-39.16A7.45,7.45,0,0,0,92.63,93.12Z"
        fill="#2abfb8"
      />
      <path
        d="M92.63,93.12c-6.92,5.35-17.44,17.17-17.24,39.48L104,134.42s8.26-24.49-.39-39.16A7.45,7.45,0,0,0,92.63,93.12Z"
        fill="#fff"
        opacity="0.36"
      />
      <polygon
        points="74.02 185.97 74.02 213.85 89.25 185.97 74.02 185.97"
        fill="#e6e6e6"
      />
      <rect
        x="222.9"
        y="130.09"
        width="114.33"
        height="89.65"
        transform="translate(560.13 349.82) rotate(-180)"
        fill="#e6e6e6"
      />
      <polygon
        points="325.34 219.73 325.34 247.61 310.12 219.73 325.34 219.73"
        fill="#e6e6e6"
      />
      <path
        d="M258.69,170.58l-8.41,8.78a6.28,6.28,0,0,1-9.95-1.17l-10.87-18.53a6.19,6.19,0,0,1-.66-1.65c-.48-2-1.78-6.83-3.23-7.37-1.83-.69-2.28,3.8-2.28,3.8s-5.26-3.51-7.54,0c-1.36,2.09,2,4.87,4.89,6.78a12.7,12.7,0,0,1,5.14,6.74c2.79,8.82,9.6,27.54,17.61,29.08,10.51,2,29.06-13.8,29.06-13.8Z"
        fill="#f4a28c"
      />
      <path
        d="M279.09,141.51s-33.46,5.42-29.35,28.54c3,16.76,11.71,15.56,11.26,28.34l-.2,8.11,35.61-1.07s-2.27-16,2.18-27.42S315.26,138.47,279.09,141.51Z"
        fill="#2abfb8"
      />
      <polygon
        points="286.85 121.52 288.4 141.71 277.21 141.77 280.57 127.06 286.85 121.52"
        fill="#f4a28c"
      />
      <path
        d="M289.13,145.82S271.73,151,277.21,166s14.22,24.48,5.42,29.26S260.8,206.5,260.8,206.5h30.05l7.16-7.81,6.19-22.53Z"
        opacity="0.09"
      />
      <path
        d="M279.7,130.24a7.35,7.35,0,0,0,3.78-1.85s-.46,3.68-4.81,7Z"
        fill="#ce8172"
        opacity="0.31"
      />
      <path
        d="M275.62,119.83s-.68,6.14.25,10.26a3.1,3.1,0,0,0,3.74,2.32,6.86,6.86,0,0,0,5.48-5l2-5.35a5.56,5.56,0,0,0-2.3-6C281.34,113.46,275.85,116.07,275.62,119.83Z"
        fill="#f4a28c"
      />
      <polygon
        points="260.8 206.5 256.43 219.73 301.66 219.73 296.41 205.43 260.8 206.5"
        fill="#24285b"
      />
      <path
        d="M282.06,125.27s.1-2.37,1.88-2.15,1.66,3.66-.78,3.93Z"
        fill="#f4a28c"
      />
      <path
        d="M275.39,123.72l-1.78,2.34a1,1,0,0,0,.58,1.57l2.35.5Z"
        fill="#f4a28c"
      />
      <path
        d="M283.94,123.12s-7.43,18.23-1.47,21.7,16.55,2.73,17.95-5-6.22-9.7-6.28-20.58-20.95-10.1-21.17-3.85S283.94,123.12,283.94,123.12Z"
        fill="#ffd200"
      />
      <path
        d="M289.13,145.82a8.89,8.89,0,0,1,13.15,2.05A47.77,47.77,0,0,1,310,176.66c-1.22,21.61,3.43,34.71-19.19,29.84-16.49-3.54-36.55-30.6-36.55-30.6s-6.7-4.44-1.52-11,9.13,9,9.13,9,27.11,22.62,32.6,16C299.32,184.15,275.06,157.72,289.13,145.82Z"
        fill="#f4a28c"
      />
      <ellipse cx="176.77" cy="54.45" rx="19.7" ry="16.25" fill="#24285b" />
      <polygon
        points="165.25 60.89 155.5 71.26 169.51 65.49 165.25 60.89"
        fill="#24285b"
      />
      <circle cx="168.37" cy="54.45" r="2.97" fill="#fff" />
      <circle cx="177.05" cy="54.45" r="2.97" fill="#fff" />
      <circle cx="185.5" cy="54.45" r="2.97" fill="#fff" />
      <ellipse cx="232.31" cy="98.57" rx="19.7" ry="16.25" fill="#24285b" />
      <polygon
        points="243.83 105 253.57 115.37 239.56 109.61 243.83 105"
        fill="#24285b"
      />
      <circle cx="240.71" cy="98.57" r="2.97" fill="#fff" />
      <circle cx="232.02" cy="98.57" r="2.97" fill="#fff" />
      <circle cx="223.57" cy="98.57" r="2.97" fill="#fff" />
      <polygon
        points="93.27 172.03 93.27 185.97 144.84 185.97 140.53 172.03 93.27 172.03"
        fill="#24285b"
      />
    </svg>
  );
};

export default visitorIcon;
