import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { defaultQuery as postApi } from '../../config/utils/network';

const initialStateValues = {
  menu: {},
  menues: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState: initialStateValues,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setMenues: (state, action) => {
      state.menues = action.payload;
    },
    perge: (state, action) => {
      state.menu = {};
      state.menues = [];
    },
  },
});

// actions
export const { setMenu, setMenues, perge } = menuSlice.actions;

// selector
export const menuSelector = state => state.menu;

// reducer
export default menuSlice.reducer;

export function fetchMenues() {
  return async dispatch => {
    try {
      const { data } = await postApi('/api/admin/menu/selectMyMenuList', {});
      const menues = data.resultList
        .filter(row => row.siteId === '')
        .filter(row => row.useAt === 'Y');
      if (menues && Array.isArray(menues) && menues.length > 0) {
        dispatch(setMenues(menues));
      }
    } catch (error) {
      console.error('🚀 ~ store/menu/reducer.js fetchMenues ~ error', error);
    }
  };
}
