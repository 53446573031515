import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import 'antd/dist/antd.less';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './assets/less/theme.less';
import './index.css';

const rootElement = document.getElementById('root');
const persistor = persistStore(store);

/** react-snap 적용 */
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <React.StrictMode>
        <ConfigProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </ConfigProvider>
      </React.StrictMode>
    </BrowserRouter>,
    rootElement,
  );
} else {
  render(
    <BrowserRouter>
      <React.StrictMode>
        <ConfigProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </Provider>
        </ConfigProvider>
      </React.StrictMode>
    </BrowserRouter>,
    rootElement,
  );
}

// ConfigProvider.config({
//   prefixCls: 'custom',
//   theme: {
//     primaryColor: '#5b44fb',
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
