import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLoading } from '../../../hooks/useLoading';
import useSession from '../../../hooks/useSession';
import LoginView from './LoginView';
import { local, LOGIN_INFORM } from '../../../config/contants';

function Login() {
  const [form] = Form.useForm();
  const { hide, show } = useLoading();
  const { signIn } = useSession();
  const [searchParams] = useSearchParams();
  const [initial, setInitial] = useState(() => {
    return (
      local.getItem(LOGIN_INFORM) && JSON.parse(local.getItem(LOGIN_INFORM))
    );
  });

  // initial values
  const initialLogin = {
    remember: false,
    id: 'pja',
    password: '1234',
  };

  const handleFinish = async () => {
    show();
    try {
      const { ...values } = form.getFieldsValue();

      // 저장시 설정
      if (values.remember) {
        local.setItem(
          LOGIN_INFORM,
          JSON.stringify({
            ...values,
            password: '',
          }),
        );
      } else {
        local.removeItem(LOGIN_INFORM);
      }

      signIn(values, {
        redirectTo: searchParams.get('continueUrl'),
      });

      // TODO: Success Login
    } catch (e) {
      console.error(e);
    } finally {
      hide();
    }
  };

  const props = {
    form,
    initial,
    onFinish: handleFinish,
  };

  return <LoginView {...props} />;
}

Login.propTypes = {};

export default Login;
