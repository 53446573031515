import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Drawer,
  Button,
  Form,
  Input,
  Switch,
  Popconfirm,
  Space,
  Select,
  Upload,
  DatePicker,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import {
  DeleteOutlined,
  UploadOutlined,
  // PlusOutlined,
  // PlusSquareOutlined,
  // PlusCircleOutlined,
} from '@ant-design/icons';

import JoditEditor from '../../molecules/JoditEditor';
import SearchBar from '../../templates/SearchBar';
import StyledDrawer from '../../atoms/StyledDrawer';

const { Option } = Select;

function PopupView({
  form,
  list,
  paging,
  visible,
  selectedRows,
  isEdit,
  columnDefs,
  gridOptions,
  searchOption,
  popupId,
  attach,
  onPaging,
  onRowClick,
  onCellClick,
  onGridReady,
  onDraw,
  onChangeType,
  onSelectionChanged,
  onFinish,
  onReset,
  onSearch,
  onDelete,
  onMoveList,
  onGridSizeChanged,
}) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={18} style={{ display: 'flex' }}>
              <SearchBar options={searchOption} onSearch={onSearch} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Space>
                <Button
                  type="primary"
                  // icon={<PlusCircleOutlined />}
                  onClick={() => {
                    onReset();
                    onDraw(true);
                  }}
                  ghost
                >
                  등록
                </Button>
                <Popconfirm
                  title="미사용 처리하시겠습니까?"
                  onConfirm={() => {
                    onDelete();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                  disabled={selectedRows.length < 1}
                >
                  <Button
                    type="danger"
                    htmlType="button"
                    disabled={selectedRows.length < 1}
                    icon={<DeleteOutlined />}
                  >
                    미사용
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ag-theme-alpine" style={{ height: 470 }}>
            <AgGridReact
              rowData={list}
              columnDefs={columnDefs}
              {...gridOptions}
              onGridReady={onGridReady}
              onRowClicked={onRowClick}
              onCellClicked={onCellClick}
              onSelectionChanged={onSelectionChanged}
              onGridSizeChanged={onGridSizeChanged}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
          <Pagination
            pageSize={paging?.pageSize ?? 10}
            current={paging?.currentPageNo ?? 1}
            total={paging?.totalRecordCount ?? 0}
            onChange={onPaging}
          />
        </Col>
      </Row>
      <StyledDrawer
        title={`팝업 ${isEdit ? '수정' : '등록'}`}
        placement="right"
        width="40vw"
        open={visible}
        onClose={() => {
          onDraw(false);
          onMoveList();
        }}
        footer={[
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                key="btn2"
                disabled={isEdit}
                htmlType="button"
                onClick={() => {
                  onReset(isEdit);
                }}
              >
                초기화
              </Button>
            </Col>
            <Col>
              <Space>
                <Popconfirm
                  key="btn1"
                  title="저장 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    form.submit();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    // onClick={() => form.submit()}
                  >
                    저장
                  </Button>
                </Popconfirm>
                <Button
                  key="btn3"
                  onClick={() => {
                    onDraw(false);
                    onMoveList();
                  }}
                >
                  닫기
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {/* Hidden Value */}
          <Form.Item name="popupId" noStyle>
            <Input hidden />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="팝업명"
                name="popupTitleNm"
                rules={[{ required: true, message: '팝업명을 입력해주세요.' }]}
                tooltip="팝업명을 입력해주세요."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="팝업 유형"
                name="popupTy"
                rules={[
                  { required: true, message: '팝업 유형을 선택해주세요.' },
                ]}
                tooltip="팝업 유형을 선택해주세요."
              >
                <Select
                  onChange={value => {
                    if (value === 'U') {
                      onChangeType(true);
                    } else {
                      onChangeType(false);
                    }
                  }}
                >
                  <Option value="U">업로드이미지</Option>
                  <Option value="O">외부파일</Option>
                  <Option value="C">컨텐츠</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}> </Col>
            {!attach && (
              <Col span={24}>
                <Form.Item
                  label="컨텐츠 Url"
                  name="linkUrl"
                  rules={[
                    {
                      required: true,
                      message: '컨텐츠 URL을 입력해주세요.',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item
                label="시작일"
                name="ntceBgnde"
                rules={[
                  {
                    required: true,
                    message: '시작일을 입력해주세요.',
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="종료일"
                name="ntceEndde"
                rules={[
                  {
                    required: true,
                    message: '종료일을 입력해주세요.',
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={8}> </Col>
            <Col span={4}>
              <Form.Item
                label="W OFFSET"
                name="popupWlc"
                rules={[
                  {
                    required: true,
                    message: 'W Offset 값을 입력해주세요.',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="H OFFSET"
                name="popupHlc"
                rules={[
                  {
                    required: true,
                    message: 'H Offset 값을 입력해주세요.',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="W 크기"
                name="popupWSize"
                rules={[
                  {
                    required: true,
                    message: 'W 크기 값을 입력해주세요.',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="H 크기"
                name="popupHSize"
                rules={[
                  {
                    required: true,
                    message: 'H 크기 값을 입력해주세요.',
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={4}> </Col>
            <Col span={4}> </Col>
            {attach && (
              <Col span={24}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.atchFile !== curValues.atchFile
                  }
                  noStyle
                >
                  {({ getFieldValue }) => {
                    const atchFile = getFieldValue('atchFile');
                    const disabled = atchFile && atchFile.length > 0;
                    return (
                      <Form.Item
                        required
                        rules={[
                          () => ({
                            validator(_rule, value) {
                              if (!value || !value.length) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  '팝업 이미지를 추가해주세요.',
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        name="atchFile"
                        label="첨부파일"
                        valuePropName="fileList"
                        getValueFromEvent={e => e.fileList}
                      >
                        <Upload
                          accept="image/*"
                          customRequest={data => {
                            setTimeout(() => {
                              data.onSuccess({ status: 'ok' }, null);
                            }, 0);
                          }}
                        >
                          <Button disabled={disabled}>
                            <UploadOutlined /> 클릭 업로드
                          </Button>
                        </Upload>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            )}
            <Col span={8}>
              <Form.Item
                label="중지여부"
                name="stopvewSetupAt"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="게시여부" name="ntceAt" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="사용여부" name="useAt" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="설명"
                name="popupCn"
                rules={[{ required: true, message: '설명을 입력해주세요.' }]}
                tooltip="설명을 입력해주세요."
              >
                <JoditEditor visible={visible} removeButtons={['image']} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDrawer>
    </div>
  );
}

PopupView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  visible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  popupId: PropTypes.string,
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
};

PopupView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  visible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  popupId: '',
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
};

export default PopupView;
