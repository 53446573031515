import React from 'react';
import PropTypes from 'prop-types';
import SearchBarView from './SearchBarView';

/**
 * 검색 Bar 공통
 * @param {*} param0
 * @returns
 */
function SearchBar({ options, onSearch }) {
  const props = {
    options,
    onSearch,
  };
  return <SearchBarView {...props} />;
}

export default SearchBar;

SearchBar.propTypes = {
  options: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onSearch: PropTypes.func,
};

SearchBar.defaultProps = {
  options: {},
  onSearch: () => {},
};
