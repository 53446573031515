import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchSites } from '../store/site/reducer';
import { selectKeep } from '../store/keep/reducer';

function useStoreSite() {
  const dispatch = useDispatch();
  const sites = useSelector(state => state.site.sites, shallowEqual);
  const { siteInfo } = useSelector(selectKeep, shallowEqual);

  useEffect(() => {
    if (sites.length < 1) {
      dispatch(fetchSites());
    }
  }, [dispatch]);

  return { sites, siteId: siteInfo?.siteId };
}

export default useStoreSite;
