import { processGetAccessToken } from './token-resolver';
import useSession from '../../hooks/useSession';

/**
 * axios 인스턴스에,
 * refresh_token 로직 을 수행 할 수 있도록 인터셉터를 연결
 *
 * @param {import('axios').AxiosInstance} axios
 */
const createTokenInterceptor = axios => {
  axios.interceptors.request.use(
    async config => {
      if (window.location.pathname.indexOf('login') > -1) {
        throw new axios.Cancel('Operation canceled by the user.');
      }
      const accessToken = await processGetAccessToken();

      // 페이지 토큰 저장
      config.headers['Authorization'] = `Bearer ${accessToken}`;
      return config;
    },
    err => {
      console.log(err);
    },
  );

  axios.interceptors.response.use(
    async response => {
      return response;
    },
    error => {
      if (error.response?.status === 401) {
        const { signOut } = useSession();
        alert('토큰이 만료되어 로그인 페이지로 이동합니다.');
        signOut({
          redirectTo: '/login',
        });
      }

      return Promise.reject(error);
    },
  );
};

// 응답(response) 인터셉터 추가

export default createTokenInterceptor;
