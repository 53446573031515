import React from 'react';
import { useSelector } from 'react-redux';

import CmsHeaderView from './CmsHeaderView';
import useSession from '../../../hooks/useSession';
import { selectKeep } from '../../../store/keep/reducer';

function CmsHeader({ collapsed, navigation }) {
  const { signOut } = useSession();

  const { menuInfo } = useSelector(selectKeep);

  const props = {
    signOut,
    collapsed,
    navigation,
    menu: menuInfo?.m,
  };

  return <CmsHeaderView {...props} />;
}

export default CmsHeader;
