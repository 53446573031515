import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import LoadingView from './LoadingView';
import { selectCommon } from '../../../store/common/reducer';

function Loading({ onClose }) {
  const { isLoading: visible } = useSelector(selectCommon);

  const props = {
    visible,
    onCancel: onClose,
  };

  return <LoadingView {...props} />;
}

export default Loading;

Loading.propTypes = {
  onClose: PropTypes.func,
};

Loading.defaultProps = {
  onClose: () => {},
};
