import React from 'react';
import PropTypes from 'prop-types';
import { Col, Dropdown, Menu, Row, Tree } from 'antd';
import styled from 'styled-components';
import CollapsePanel from './CollapsePanel';
import ManageForm from './ManageForm';

function MenuView({
  sites,
  menuData,
  form,
  authorList,
  isEdit,
  onSelect,
  onSave,
  onReset,
  onAddSubMenu,
}) {
  return (
    <Wrapper>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <CollapsePanel title="조회">
            <Tree
              blockNode
              showIcon
              onSelect={onSelect}
              titleRender={node =>
                ContextMenu({
                  ...node,
                  id: node.id,
                  title: node.menuNm,
                  onAddSubMenu,
                })
              }
              treeData={menuData}
            />
          </CollapsePanel>
        </Col>
        <Col md={12} xs={24}>
          <CollapsePanel
            title="등록"
            onSave={onSave}
            onRedo={() => {
              onReset(isEdit);
            }}
          >
            <ManageForm form={form} authorList={authorList} sites={sites} />
          </CollapsePanel>
        </Col>
      </Row>
    </Wrapper>
  );
}

MenuView.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  isEdit: PropTypes.bool.isRequired,
  authorList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  menuData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onSelect: PropTypes.func,
  onSave: PropTypes.func,
  onReset: PropTypes.func,
  onAddSubMenu: PropTypes.func.isRequired,
};

MenuView.defaultProps = {
  sites: [],
  menuData: [],
  form: {},
  authorList: [],
  onSelect: () => null,
  onSave: null,
  onReset: null,
};

const Wrapper = styled.div`
  .ant-tree-title {
    width: 100%;
    display: inline-block;
    .ant-dropdown-trigger {
      display: inline-block;
      width: 100%;
    }
  }
  .ant-collapse-content-box {
    height: calc(100vh - 32rem);
    overflow: auto;
  }
`;

function ContextMenu({ title, onAddSubMenu, id }) {
  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          <Menu.Item
            key="add-menu"
            onClick={e => {
              e.domEvent.stopPropagation();
              onAddSubMenu(id);
            }}
          >
            하위메뉴 추가
          </Menu.Item>
        </Menu>
      }
    >
      <span>{title}</span>
    </Dropdown>
  );
}

ContextMenu.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onAddSubMenu: PropTypes.func.isRequired,
};

ContextMenu.defaultProps = {};

export default MenuView;
