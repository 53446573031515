import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col, Layout, Select } from 'antd';

import CmsHeader from '../CmsHeader';
import CmsLeftSide from '../CmsLeftSide';
import CmsFooter from '../CmsFooter';

function CmsLayoutView(props) {
  const {
    children,
    menuInfo,
    siteInfo,
    menues,
    siteId,
    sites,
    navigation,
    onSiteId,
    ...rest
  } = props;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <CmsLeftSide {...rest} />
      <CmsContainerLayout className="site-layout" {...props}>
        <CmsHeader {...props} />
        <CmsContent>
          <Row
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Col>{navigation}</Col>
            <Col>
              {menuInfo && menuInfo.m && menuInfo?.m.siteAt === 'Y' && (
                <div className="site-box">
                  <span>
                    <i className="ri-dashboard-fill" /> 사이트
                  </span>
                  <Select
                    defaultValue={siteInfo ? siteInfo.siteId : siteId}
                    style={{
                      width: 250,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    bordered={false}
                    onSelect={val => {
                      onSiteId(val);
                    }}
                    // eslint-disable-next-line react/prop-types
                    options={sites?.map(item => {
                      return {
                        value: item.siteId,
                        label: item.siteNm,
                      };
                    })}
                  />
                </div>
              )}
            </Col>
          </Row>
          <div className="l-contents">{children}</div>
        </CmsContent>
        <CmsFooter />
      </CmsContainerLayout>
    </Layout>
  );
}

const { Content } = Layout;
const CmsContainerLayout = styled(Layout)`
  margin-left: ${props => (props.collapsed ? '8rem' : '25rem')};
  transition: all 0.3s;
`;
const CmsContent = styled(Content)`
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  margin-top: 6.4rem;
  .c-bredcrumb {
    margin: 1.6rem 0;
    i[class^='ri-'] {
      color: var(--color-black);
      font-size: 1.7rem;
      vertical-align: middle;
    }
    li {
      &:last-child {
        font-weight: 500;
      }
    }
  }
  .l-contents {
    min-height: 36rem;
    height: calc((100vh - 16.5rem) - 6rem);
    background: var(--color-white);
    padding: 2rem;
    overflow-y: auto;
    box-shadow: 0px 0.25rem 1.2rem var(--color-black-rgba-5);
    /* box-shadow: 0px 0.5rem 1.5rem rgba(136, 173, 222, 0.2); */
  }
  .site-box {
    display: flex;
    height: 4rem;
    padding: 0 0 0 1rem;
    border: 1px solid var(--color-gray-eaeaea);
    border-radius: 0.4rem;
    transition: all 0.3s;
    background-color: #fff;
    &:hover {
      border: 1px solid var(--color-primary);
      box-shadow: 0px 0px 15px 0 var(--color-black-rgba-10);
    }
    > span {
      line-height: 4rem;
      padding-right: 1rem;
      border-right: 1px solid var(--color-gray-eaeaea);
      i {
        font-size: 1.6rem;
        vertical-align: bottom;
      }
    }
  }
`;

export default CmsLayoutView;

CmsLayoutView.propTypes = {
  children: PropTypes.element.isRequired,
};
