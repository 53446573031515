import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import CmsLoginLayoutView from './CmsLoginLayoutView';

// Context Provider Receiver 테스트
// import { AuthContext } from '../../../providers/AuthProvider';

function CmsLoginLayout({ children }) {
  // Context Provider Receiver 테스트
  // const { status } = useContext(AuthContext);
  // console.log('CmsLoginLayout ==>', status);

  const props = {
    children,
  };

  return <CmsLoginLayoutView {...props} />;
}

export default CmsLoginLayout;

CmsLoginLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
