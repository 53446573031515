import { v4 } from 'uuid';

export const getFileData = (originFiles, imagePath) => {
  if (!originFiles) return [];
  let files = originFiles;
  if (typeof originFiles === 'string') files = [originFiles];
  const fileData = files.map(file =>
    typeof file === 'string'
      ? {
          uid: v4(),
          id: file.atchFileId,
          name: file,
          status: 'done',
          url: `${process.env.REACT_APP_STORAGE_URI}${imagePath}`,
        }
      : {
          uid: v4(),
          id: file.atchFileId,
          name: file.orignlFileNm,
          status: 'done',
          url: `${process.env.REACT_APP_STORAGE_URI}/${file.groupId}/${file.streFileNm}`,
        },
  );
  return fileData;
};
