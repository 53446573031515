import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Breadcrumb } from 'antd';
import styled from 'styled-components';

import CmsHeader from '../CmsHeader';
import CmsLeftSide from '../CmsLeftSide';
import CmsFooter from '../CmsFooter';
import useStoreSite from '../../../hooks/useStoreSite';
import useStoreMenu from '../../../hooks/useStoreMenu';

function CmsDefaultLayoutView({ children }) {
  const [siteId, setSiteId] = useState(process.env.REACT_APP_DEFAULT_SITE_ID);
  const { menues } = useStoreMenu();
  const { sites = [] } = useStoreSite();

  // const [collapsed, setCollapsed] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const props = {
    collapsed,
    onCollapse,
    sites,
    siteId,
    setSiteId,
    navigation: {
      key: 'M00',
      name: '메인',
      path: '/cms/index',
    },
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <CmsLeftSide {...props} />
      <CmsContainerLayout className="site-layout" {...props}>
        <CmsHeader {...props} />
        <CmsContent>
          <div>{children}</div>
        </CmsContent>
        <CmsFooter />
      </CmsContainerLayout>
    </Layout>
  );
}

const { Content } = Layout;
const CmsContainerLayout = styled(Layout)`
  margin-left: ${props => (props.collapsed ? '8rem' : '25rem')};
  transition: all 0.3s;
`;
const CmsContent = styled(Content)`
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  margin-top: 6.4rem;
  > div {
    height: calc((100vh - 16.5rem) - 6rem);
  }
  .c-bredcrumb {
    margin: 1.6rem 0;
    i[class^='ri-'] {
      color: var(--color-black);
      font-size: 1.7rem;
      vertical-align: middle;
    }
    li {
      &:last-child {
        font-weight: 500;
      }
    }
  }
  .l-contents {
    min-height: 36rem;
    height: calc((100vh - 16.5rem) - 6rem);
    background: var(--color-white);
    padding: 2rem;
    overflow-y: auto;
    box-shadow: 0px 0.25rem 1.2rem var(--color-black-rgba-5);
    /* box-shadow: 0px 0.5rem 1.5rem rgba(136, 173, 222, 0.2); */
  }
`;

export default CmsDefaultLayoutView;

CmsDefaultLayoutView.propTypes = {
  children: PropTypes.element.isRequired,
};
