/* eslint no-unneeded-ternary : 'off' */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Pagination, Button, Table } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import styled from 'styled-components';

import SearchBar from '../../templates/SearchBar';
import StyledDrawer from '../../atoms/StyledDrawer';
import SurveyRespondTable from '../../molecules/SurveyRespondTable';

function SurveyAnswerView({
  list,
  paging,
  visible,
  visibleRespond,
  detailData,
  columnDefs,
  gridOptions,
  searchOption,
  respondId,
  statusMap,
  onPaging,
  onRowClick,
  onCellClick,
  onGridReady,
  onDraw,
  onDrawRespond,
  onSelectionChanged,
  onSearch,
  onMoveList,
  onGridSizeChanged,
  onRespondId,
}) {
  return (
    <>
      <div>
        <Row>
          <Col span={24}>
            <Row style={{ marginBottom: 10 }}>
              <Col span={18}>
                {/* SearchBar */}
                <SearchBar options={searchOption} onSearch={onSearch} />
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                {' '}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="ag-theme-alpine" style={{ height: 470 }}>
              <AgGridReact
                rowData={list}
                columnDefs={columnDefs}
                {...gridOptions}
                onGridReady={onGridReady}
                onRowClicked={onRowClick}
                onCellClicked={onCellClick}
                onSelectionChanged={onSelectionChanged}
                onGridSizeChanged={onGridSizeChanged}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
            <Pagination
              pageSize={paging?.pageSize ?? 10}
              current={paging?.currentPageNo ?? 1}
              total={paging?.totalRecordCount ?? 0}
              onChange={onPaging}
            />
          </Col>
        </Row>
        <StyledDrawer
          title="응답자 목록"
          placement="right"
          width="40vw"
          open={visible}
          onClose={() => {
            onDraw(false);
            onMoveList();
          }}
          // footer={[
          //   <Button
          //     key="btn3"
          //     style={{ marginLeft: 10 }}
          //     onClick={() => {
          //       onDraw(false);
          //       onMoveList();
          //     }}
          //   >
          //     닫기
          //   </Button>,
          // ]}
        >
          <Container>
            <div className="survey-answer">
              <div className="survey-answer__header">
                <strong>{detailData?.info?.qustnrSj}</strong>
                <div className="survey-answer__detail">
                  <div>
                    <span>
                      {detailData?.info?.qustnrBgnde &&
                        moment(detailData?.info?.qustnrBgnde).format(
                          'YYYY-MM-DD',
                        )}
                    </span>
                    <span>~</span>
                    <span>
                      {detailData?.info?.qustnrBgnde &&
                        moment(detailData?.info?.qustnrEndde).format(
                          'YYYY-MM-DD',
                        )}
                    </span>
                  </div>
                  <span>
                    {detailData?.info?.progrsSttus &&
                      statusMap[detailData?.info?.progrsSttus]}
                  </span>
                </div>
              </div>
              <div className="survey-answer__body">
                <dl className="survey-answer__list">
                  <dt>목적</dt>
                  <dd>{detailData?.info?.qustnrPurps}</dd>
                  <dt>안내내용</dt>
                  <dd>
                    {detailData?.info?.qustnrWritngGuidanceCn && (
                      <FroalaEditorView
                        model={detailData?.info?.qustnrWritngGuidanceCn}
                      />
                    )}
                  </dd>
                </dl>
              </div>
            </div>
            {/* <Descriptions size="small" bordered column={2}>
              <Descriptions.Item label="제목" span={2}>
                {detailData?.info?.qustnrSj}
              </Descriptions.Item>
              <Descriptions.Item label="상태">
                {detailData?.info?.progrsSttus &&
                  statusMap[detailData?.info?.progrsSttus]}
              </Descriptions.Item>
              <Descriptions.Item label="기간">
                <span>
                  {detailData?.info?.qustnrBgnde &&
                    moment(detailData?.info?.qustnrBgnde).format('YYYY-MM-DD')}
                </span>
                <span>~</span>
                <span>
                  {detailData?.info?.qustnrBgnde &&
                    moment(detailData?.info?.qustnrEndde).format('YYYY-MM-DD')}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="목적" span={2}>
                {detailData?.info?.qustnrPurps}
              </Descriptions.Item>
              <Descriptions.Item label="안내내용" span={2}>
                {detailData?.info?.qustnrWritngGuidanceCn && (
                  <FroalaEditorView
                    model={detailData?.info?.qustnrWritngGuidanceCn}
                  />
                )}
              </Descriptions.Item>
            </Descriptions> */}
            <Table
              dataSource={detailData?.responds}
              scroll={{ y: `calc(100vh - 550px)` }}
              columns={[
                {
                  title: '성별',
                  dataIndex: 'sexdstnCode',
                  key: 'sexdstnCode',
                  align: 'center',
                  render: value => (value === 'M' ? '남' : '여'),
                  sorter: {
                    compare: (a, b) => a.sexdstnCode - b.sexdstnCode,
                    multiple: 3,
                  },
                },
                {
                  title: '응답자명',
                  dataIndex: 'respondNm',
                  key: 'respondNm',
                  align: 'center',
                  sorter: {
                    compare: (a, b) => a.respondNm - b.respondNm,
                    multiple: 3,
                  },
                },
                {
                  title: '생년월일',
                  dataIndex: 'brthdy',
                  key: 'brthdy',
                  align: 'center',
                  sorter: {
                    compare: (a, b) => a.brthdy - b.brthdy,
                    multiple: 3,
                  },
                },
                {
                  title: '전화번호',
                  dataIndex: 'areaNo',
                  key: 'areaNo',
                  align: 'center',
                  render: value => {
                    return value === 'NULL' ? '-' : value;
                  },
                  sorter: {
                    compare: (a, b) => a.areaNo - b.areaNo,
                    multiple: 3,
                  },
                },
                {
                  title: '답변',
                  key: 'qustnrRespondBtn',
                  align: 'center',
                  render: value => {
                    return (
                      <Button
                        size="small"
                        onClick={() => {
                          onRespondId(value.qustnrRespondId);
                          onDrawRespond(true);
                        }}
                      >
                        보기
                      </Button>
                    );
                  },
                },
              ]}
              rowKey="qustnrRespondId"
              pagination={false}
              size="small"
            />
          </Container>
        </StyledDrawer>
      </div>
      <StyledDrawer
        title={
          <Row align="middle" justify="space-between">
            <Col>답변보기</Col>
            <Col>
              <Button
                key="btn3"
                onClick={() => {
                  onDrawRespond(false);
                  onRespondId('');
                }}
                type="ghost"
                size="small"
                className="btn-goback"
              >
                <i className="ri-arrow-go-back-fill" />
                <span className="sr-only">돌아가기</span>
              </Button>
            </Col>
          </Row>
        }
        placement="right"
        width={600}
        open={visibleRespond}
        onClose={() => {
          onDrawRespond(false);
          onRespondId('');
        }}
        // footer={[
        //   <Button
        //     key="btn3"
        //     style={{ marginLeft: 10 }}
        //     onClick={() => {
        //       onDrawRespond(false);
        //       onRespondId('');
        //     }}
        //   >
        //     돌아가기
        //   </Button>,
        // ]}
      >
        <SurveyRespondTable respondId={respondId} onFinish={onRespondId} />
      </StyledDrawer>
      {/* </div> */}
    </>
  );
}

const Container = styled.div`
  .survey-answer {
    margin-bottom: 1.6rem;
    border: 4px solid var(--color-2abfb8-rgba-20);
    &__header {
      padding: 1rem 1.6rem;
      color: var(--color-primary);
      font-size: 1.6rem;
      background: var(--color-2abfb8-rgba-10);
      strong {
        flex: 1;
        font-weight: 500;
      }
    }
    &__detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.8rem;
      margin-top: 0.8rem;
      border-top: 1px dashed var(--color-2abfb8-rgba-20);
      > div {
        color: var(--color-black-rgba-85);
        font-size: 1.4rem;
        span {
          font-family: 'Roboto', sans-serif;
        }
      }
      > span {
        padding: 0.15rem 0.5rem;
        font-size: 1.3rem;
        color: var(--color-white);
        background: var(--color-primary);
      }
    }
    &__body {
      padding: 1.6rem;
      font-size: 1.4rem;
    }
    &__list {
      dt {
        color: var(--color-primary);
        font-size: 1.4rem;
      }
      dd {
        & + dt {
          padding-top: 1.6rem;
          margin-top: 1.6rem;
          border-top: 1px dashed var(--color-gray-d9d9d9);
          & + dd {
            max-height: 15rem;
            overflow-y: auto;
          }
        }
      }
    }
  }
  .ant-table-container {
    border: 1px solid var(--color-gray-333);

    .ant-table-thead {
      .ant-table-cell {
        background: var(--color-gray-333);
        color: var(--color-white);
        // border: 1px solid #ccc;
      }
    }
    .row-disabled {
      background: #eee;
    }
  }
`;

SurveyAnswerView.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
    ]),
  ),
  detailData: PropTypes.shape({
    info: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
      ]),
    ),
    responds: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      ),
    ),
  }),
  visible: PropTypes.bool,
  visibleRespond: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  respondId: PropTypes.string,
  statusMap: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string])),
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onDrawRespond: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,

  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
  onRespondId: PropTypes.func,
};

SurveyAnswerView.defaultProps = {
  list: [],
  paging: {},
  detailData: [],
  visible: false,
  visibleRespond: false,
  columnDefs: [],
  gridOptions: {},
  isEdit: false,
  searchOption: {},
  respondId: '',
  statusMap: {},
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onDrawRespond: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},

  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
  onRespondId: () => {},
};

export default SurveyAnswerView;
