import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setIsLoading } from '../store/common/reducer';

/**
 * Hook Loading
 * @returns
 */
export function useLoading() {
  const dispatch = useDispatch();

  const show = useCallback(() => {
    if (timef) {
      clearTimeout(timef);
    }
    timef = setTimeout(() => {
      dispatch(setIsLoading(true));
    }, 100);
  }, [dispatch]);

  const hide = useCallback(() => {
    if (timef) {
      clearTimeout(timef);
    }
    timef = setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 100);
  }, [dispatch]);

  return { show, hide };
}

let timef;
