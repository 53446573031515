import React from 'react';

const DashboardIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 300"
      width="406"
      height="306"
    >
      <title>#4 web development</title>
      <ellipse
        cx="88.4"
        cy="256.54"
        rx="56.71"
        ry="10.74"
        fill="#e6e6e6"
        opacity="0.45"
      />
      <rect x="91.17" y="76.3" width="209.1" height="137.4" fill="#e6e6e6" />
      <circle cx="101.28" cy="85.42" r="3.42" fill="#24285b" />
      <circle cx="111.44" cy="85.42" r="3.42" fill="#0087ff" />
      <circle cx="121.02" cy="85.42" r="3.42" fill="#ffd200" />
      <path
        d="M266.38,76.3l-49.25.2-7.88,11.09L135,155.78l6.1,11.85-20.85,8.22H99.93L97.24,213.7H121l3.36-22.1c19.89-2.65,33-20.88,33-20.88h0L244,133.67l-11-25.34,14.63-7,.81,4.59s7.91-2.18,7.58-9.55S255,79.85,266.38,76.3Z"
        opacity="0.08"
      />
      <path
        d="M225.74,48.37C224.53,58.06,219.53,78.08,199,87l3,5s28.57-9,35.46-32c3.09-10.35,1.12-14.84-2-16.66A6.58,6.58,0,0,0,225.74,48.37Z"
        fill="#0087ff"
      />
      <path
        d="M225.74,48.37C224.53,58.06,219.53,78.08,199,87l3,5s28.57-9,35.46-32c3.09-10.35,1.12-14.84-2-16.66A6.58,6.58,0,0,0,225.74,48.37Z"
        fill="#fff"
        opacity="0.2"
      />
      <polygon
        points="228.36 33.05 233.27 39.04 237.59 34.4 229.84 27.52 228.36 33.05"
        fill="#f4a28c"
      />
      <path
        d="M224.9,22.05a4.57,4.57,0,0,0-5.11,5.72c.91,3.5,2.87,8.27,6.61,6.63,5.71-2.5,2.71-10,2.71-10A8.35,8.35,0,0,0,224.9,22.05Z"
        fill="#f4a28c"
      />
      <path
        d="M232.49,68.94l-9.74,5a7,7,0,0,0-2.86,9.66l8.75,15.15,4.26-3-5-13.82a1.73,1.73,0,0,1,1.21-2.28l9.71-2.37-1.73,4.6a9.64,9.64,0,0,0,1,8.78l9,13.42,3.41-2.09L246.17,90.1a7.6,7.6,0,0,1,1.53-7.73l4.69-5.14Z"
        fill="#24285b"
      />
      <rect x="268.35" y="191" width="58.5" height="37" fill="#f2f2f2" />
      <rect x="258.99" y="191" width="9.51" height="22.7" opacity="0.08" />
      <path
        d="M217.61,23.32s-1.58,5.08,3.92,3.83,5.08-3,5.08-3,.42,6,3.17,4.67,1.17-4.6,0-6.84-1.86-2.91-5-3.24S218,20.24,217.61,23.32Z"
        fill="#24285b"
      />
      <path
        d="M226.7,27.65s-.23-1.71,1.42-1.38,1,2.71,0,2.88S226.7,27.65,226.7,27.65Z"
        fill="#f4a28c"
      />
      <polygon
        points="81.73 157.66 84.18 165.01 78.37 168.7 76.27 155.96 81.73 157.66"
        fill="#f4a28c"
      />
      <path
        d="M74.28,148.86a4.58,4.58,0,0,1,7.57-1.27c2.47,2.64,5.45,6.85,2.08,9.13-5.17,3.5-9.91-3-9.91-3A8.35,8.35,0,0,1,74.28,148.86Z"
        fill="#f4a28c"
      />
      <path
        d="M78.06,153.37s-1.33-1.11-1.93.46,1.74,2.34,2.44,1.54S78.06,153.37,78.06,153.37Z"
        fill="#f4a28c"
      />
      <path
        d="M232.23,37.77l5.36-3.37s17.41-5.95,22.91-.48,5.89,26.11-8.11,43.31l-19.9-8.29s.4-9.9-2.91-12.4S222.2,46.54,232.23,37.77Z"
        fill="#0087ff"
      />
      <path
        d="M229.64,98s-1.09,5.06.86,4.88,2.4-7.18,2.4-7.18Z"
        fill="#0087ff"
      />
      <path
        d="M247.19,103.85s-1.09,5.05.86,4.88,2.39-7.18,2.39-7.18Z"
        fill="#0087ff"
      />
      <path
        d="M274.81,73.77s-3.36,1.45-2.12,3.11,5.49-.58,5.49-.58l.26-2.53Z"
        fill="#f4a28c"
      />
      <path
        d="M199.41,87.59s-5.52.45-4.36,1.72a5.94,5.94,0,0,0,2.1,1.61s-4,4.47-1.09,5.36,5.75-4.68,5.75-4.68Z"
        fill="#f4a28c"
      />
      <rect
        x="116.77"
        y="105.85"
        width="107.16"
        height="42.5"
        transform="matrix(0.91, -0.42, 0.42, 0.91, -37.5, 82.72)"
        fill="#ffd200"
      />
      <rect
        x="155.02"
        y="111.38"
        width="51.5"
        height="7"
        transform="translate(-31.45 85.96) rotate(-24.68)"
        fill="#fff"
        opacity="0.58"
      />
      <rect
        x="161.07"
        y="124.56"
        width="51.5"
        height="7"
        transform="translate(-36.4 89.69) rotate(-24.68)"
        fill="#fff"
        opacity="0.58"
      />
      <circle cx="142.04" cy="141.07" r="11.12" fill="#fff" />
      <polyline
        points="288.24 202.33 280.44 209.91 288.24 217.5"
        fill="none"
        stroke="#a5a5a5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <polyline
        points="305.11 202.33 312.91 209.91 305.11 217.5"
        fill="none"
        stroke="#a5a5a5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M191.68,64.18S182.07,61.6,180,52.68c0,0,14.85-3.07,15.34,12.27Z"
        fill="#0087ff"
        opacity="0.58"
      />
      <path
        d="M192.85,63.22s-6.75-10.57-.89-20.5c0,0,11.33,7.13,6.37,20.5Z"
        fill="#0087ff"
        opacity="0.73"
      />
      <path
        d="M194.58,63.22s3.49-11.2,14.19-13.37c0,0,2,7.26-6.88,13.37Z"
        fill="#0087ff"
      />
      <line
        x1="299.71"
        y1="202.33"
        x2="292.23"
        y2="217.5"
        fill="none"
        stroke="#a5a5a5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <rect
        x="104.69"
        y="100.42"
        width="29.31"
        height="5.5"
        fill="#cecece"
        opacity="0.63"
      />
      <path
        d="M77.07,167.25s-17.17,10.4-19.42,34.8a8.46,8.46,0,0,0,6.41,9L84.18,216Z"
        fill="#0087ff"
      />
      <path
        d="M77.07,167.25s-17.17,10.4-19.42,34.8a8.46,8.46,0,0,0,6.41,9L84.18,216Z"
        fill="#fff"
        opacity="0.2"
      />
      <polygon
        points="187.62 63 189.62 76.3 201.87 76.3 203.62 63 187.62 63"
        fill="#24285b"
      />
      <path
        d="M78.79,151.46s2.74,7.86-2.05,7.37-2.16-3.87-4.54-7.37-1.12-5.86,2.5-8.93c1.67-1.41,2.79-2.06,3.45-2.81a2.57,2.57,0,0,1,4.31.61C83.7,143.35,83.71,147.39,78.79,151.46Z"
        fill="#24285b"
      />
      <rect x="256.33" y="112.62" width="43.94" height="69.09" opacity="0.08" />
      <rect
        x="50"
        y="198.25"
        width="34.18"
        height="24.5"
        transform="matrix(0.97, -0.25, 0.25, 0.97, -50.55, 23.49)"
        fill="#ffd200"
      />
      <polygon
        points="67.09 197.85 70.89 213.7 69.9 222.43 78.79 220.13 81.44 213.05 72.9 196.34 67.09 197.85"
        opacity="0.08"
      />
      <path
        d="M73.1,172.36a13.79,13.79,0,0,1,8.67-7.56c7.34-2.21,21.28-6.83,25.4,0,5.64,9.4-3,46.31-3,46.31l-24.67,1.91S65.89,188.58,73.1,172.36Z"
        fill="#0087ff"
      />
      <polygon
        points="104.13 211.14 100.88 253.55 95.75 253.55 91.17 220.6 79.83 213.05 104.13 211.14"
        fill="#24285b"
      />
      <polygon
        points="79.83 213.05 72.75 253.55 78.25 253.55 90.13 219.91 79.83 213.05"
        fill="#24285b"
      />
      <path
        d="M92.44,170.45A7,7,0,0,1,99,161.32c3.14-.1,6.75.44,9.52,2.68,6.17,5,26.4,8.4,44.66-8.31l3.51,5.39s-20.74,27-53.22,19.24a15.2,15.2,0,0,1-10.9-9.56C92.52,170.66,92.48,170.55,92.44,170.45Z"
        fill="#0087ff"
      />
      <path
        d="M92.44,170.45A7,7,0,0,1,99,161.32c3.14-.1,6.75.44,9.52,2.68,6.17,5,26.4,8.4,44.66-8.31l3.51,5.39s-20.74,27-53.22,19.24a15.2,15.2,0,0,1-10.9-9.56C92.52,170.66,92.48,170.55,92.44,170.45Z"
        fill="#fff"
        opacity="0.2"
      />
      <path
        d="M83.9,164.15s-11.54,37.17,21.78,39.63l-1.55,7.36-24.67,1.91a64,64,0,0,1-8.33-32.82s.21-9.48,6.89-13.67A15.09,15.09,0,0,1,83.9,164.15Z"
        opacity="0.08"
      />
      <path
        d="M73.75,253.55l-3.41,2.51a.75.75,0,0,0,.44,1.36H76.5a.76.76,0,0,0,.75-.75v-3.12Z"
        fill="#0087ff"
      />
      <path
        d="M100.74,253.55l3.21,2.51a.76.76,0,0,1-.47,1.36H99.41a2.18,2.18,0,0,1-2.17-2.18v-1.69Z"
        fill="#0087ff"
      />
      <rect x="266.38" y="99.17" width="72.63" height="74.58" fill="#f2f2f2" />
      <rect x="279.5" y="117.57" width="44.75" height="2.68" fill="#a5a5a5" />
      <rect x="279.5" y="134.5" width="44.75" height="2.68" fill="#a5a5a5" />
      <rect x="279.5" y="151.43" width="44.75" height="2.68" fill="#a5a5a5" />
      <rect
        x="318"
        y="112.62"
        width="7.85"
        height="12.58"
        rx="3.92"
        ry="3.92"
        fill="#24285b"
      />
      <rect
        x="300.27"
        y="129.55"
        width="7.85"
        height="12.58"
        rx="3.92"
        ry="3.92"
        fill="#0087ff"
      />
      <rect
        x="288.27"
        y="146.49"
        width="7.85"
        height="12.58"
        rx="3.92"
        ry="3.92"
        fill="#ffd200"
      />
      <rect x="163.38" y="177.79" width="81.97" height="18.68" fill="#fff" />
      <rect x="217.4" y="177.79" width="27.95" height="18.68" fill="#24285b" />
      <path d="M155.77,159.69s4.28,1.4,5.19-4.9l-7.27,1.71Z" fill="#f4a28c" />
      <path
        d="M240.69,72.35s8.7-1.66,11.7-13.66-3.39-21.53,2.78-23,8.73,14.18,8.73,14.18.24,12.24-11.51,27.38Z"
        opacity="0.08"
      />
      <path
        d="M259.17,32.85c5.12,1.82,13.12,5.44,18.52,11.57a16,16,0,0,1,3.81,12.89l-2.42,16.46h-5.75l.26-15a7.38,7.38,0,0,0-5.19-7.11c-6.21-1.9-15.74-6.12-15.75-14.12A4.9,4.9,0,0,1,259.17,32.85Z"
        fill="#0087ff"
      />
      <path
        d="M259.17,32.85c5.12,1.82,13.12,5.44,18.52,11.57a16,16,0,0,1,3.81,12.89l-2.42,16.46h-5.75l.26-15a7.38,7.38,0,0,0-5.19-7.11c-6.21-1.9-15.74-6.12-15.75-14.12A4.9,4.9,0,0,1,259.17,32.85Z"
        fill="#fff"
        opacity="0.2"
      />
      <rect x="207.67" y="164.15" width="37.33" height="4.54" opacity="0.08" />
    </svg>
  );
};

export default DashboardIcon;
