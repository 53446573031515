import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Button,
  Form,
  Input,
  Switch,
  Popconfirm,
  Space,
  Upload,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';

import JoditEditor from '../../molecules/JoditEditor';
import SearchBar from '../../templates/SearchBar';
import StyledDrawer from '../../atoms/StyledDrawer';

function ContentsView({
  form,
  list,
  paging,
  visible,
  selectedRows,
  isEdit,
  columnDefs,
  gridOptions,
  searchOption,
  cssFiles,
  jsFiles,
  imgFiles,
  onCssFiles,
  onJsFiles,
  onImgFiles,
  onPaging,
  onRowClick,
  onCellClick,
  onGridReady,
  onDraw,
  onSelectionChanged,
  onFinish,
  onReset,
  onSearch,
  onDelete,
  onMoveList,
  onGridSizeChanged,
}) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={18}>
              {/* SearchBar */}
              <SearchBar options={searchOption} onSearch={onSearch} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Space>
                <Button
                  type="primary"
                  // icon={<PlusCircleOutlined />}
                  onClick={() => {
                    onReset();
                    onDraw(true);
                  }}
                  ghost
                >
                  등록
                </Button>
                <Popconfirm
                  title="미사용 처리하시겠습니까?"
                  onConfirm={() => {
                    onDelete();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                  disabled={selectedRows.length < 1}
                >
                  <Button
                    type="danger"
                    htmlType="button"
                    disabled={selectedRows.length < 1}
                    icon={<DeleteOutlined />}
                  >
                    미사용
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ag-theme-alpine" style={{ height: 470 }}>
            <AgGridReact
              rowData={list}
              columnDefs={columnDefs}
              {...gridOptions}
              onGridReady={onGridReady}
              onRowClicked={onRowClick}
              onCellClicked={onCellClick}
              onSelectionChanged={onSelectionChanged}
              onGridSizeChanged={onGridSizeChanged}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
          <Pagination
            pageSize={paging?.pageSize ?? 10}
            current={paging?.currentPageNo ?? 1}
            total={paging?.totalRecordCount ?? 0}
            onChange={onPaging}
          />
        </Col>
      </Row>
      <StyledDrawer
        title={`컨텐츠 ${isEdit ? '수정' : '등록'}`}
        placement="right"
        width="40vw"
        open={visible}
        onClose={() => {
          onDraw(false);
          onMoveList();
        }}
        footer={[
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                key="btn2"
                disabled={isEdit}
                htmlType="button"
                onClick={() => {
                  onReset(isEdit);
                }}
              >
                초기화
              </Button>
            </Col>
            <Col>
              <Space>
                <Popconfirm
                  key="btn1"
                  title="저장 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    form.submit();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    // onClick={() => form.submit()}
                  >
                    저장
                  </Button>
                </Popconfirm>
                <Button
                  key="btn3"
                  onClick={() => {
                    onDraw(false);
                    onMoveList();
                  }}
                >
                  닫기
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFieldsChange={changedFields => {
          //   console.log('🚀 ~ changedFields', changedFields);
          // }}
          // onValuesChange={changedValues => {
          //   console.log('🚀 ~ changedValues', changedValues);
          // }}
        >
          {/* Hidden Value */}
          <Form.Item name="cntntsId" noStyle>
            <Input hidden />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                label="컨텐츠명"
                name="cntntsNm"
                rules={[
                  { required: true, message: '컨텐츠명을 입력해주세요.' },
                ]}
                tooltip="컨텐츠명을 입력해주세요."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="js 파일"
                name="jsFile"
                tooltip="js파일을 업로드 해주세요."
              >
                <Upload
                  accept=".js"
                  beforeUpload={file => {
                    onJsFiles([file]);
                    return false;
                  }}
                  onRemove={file => {
                    const jsss = [...jsFiles];

                    onJsFiles(
                      jsss.filter(item => {
                        return file.uid !== item.uid;
                      }),
                    );
                  }}
                  fileList={jsFiles}
                >
                  <Button>
                    <UploadOutlined /> 클릭 업로드
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="css 파일"
                name="cssFile"
                tooltip="css파일을 업로드 해주세요."
              >
                <Upload
                  accept=".css"
                  multiple={false}
                  beforeUpload={file => {
                    onCssFiles([file]);
                    return false;
                  }}
                  onRemove={file => {
                    const csss = [...cssFiles];
                    onCssFiles(
                      csss.filter(item => {
                        return file.uid !== item.uid;
                      }),
                    );
                  }}
                  fileList={cssFiles}
                >
                  <Button>
                    <UploadOutlined /> 클릭 업로드
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="thumbnail 파일"
                name="imgFile"
                tooltip="thumbnail 파일을 업로드 해주세요."
              >
                <Upload
                  accept=".jpg,.png"
                  multiple={false}
                  beforeUpload={file => {
                    onImgFiles([file]);
                    return false;
                  }}
                  onRemove={file => {
                    const imgss = [...imgFiles];
                    onImgFiles(
                      imgss.filter(item => {
                        return file.uid !== item.uid;
                      }),
                    );
                  }}
                  fileList={imgFiles}
                >
                  <Button>
                    <UploadOutlined /> 클릭 업로드
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="사용여부" name="useAt" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="내용"
                name="content"
                rules={[
                  {
                    required: true,
                    message: '컨텐츠를 입력해주세요.',
                  },
                ]}
                tooltip="컨텐츠를 입력해주세요."
              >
                <JoditEditor visible={visible} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDrawer>
    </div>
  );
}

ContentsView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  visible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  cssFiles: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  jsFiles: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  imgFiles: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  onCssFiles: PropTypes.func,
  onJsFiles: PropTypes.func,
  onImgFiles: PropTypes.func,
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
};

ContentsView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  visible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  cssFiles: [],
  jsFiles: [],
  imgFiles: [],
  onCssFiles: () => {},
  onJsFiles: () => {},
  onImgFiles: () => {},
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
};

export default ContentsView;
