import React from 'react';
import PropTypes from 'prop-types';
import CmsDefaultLayoutView from './CmsDefaultLayoutView';

function CmsDefaultLayout({ children }) {
  const props = { children };
  return <CmsDefaultLayoutView {...props} />;
}

export default CmsDefaultLayout;

CmsDefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
