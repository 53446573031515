import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Button,
  Form,
  Input,
  Switch,
  Popconfirm,
  Space,
  Select,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import {
  DeleteOutlined,
  // PlusOutlined,
  // PlusSquareOutlined,
  // PlusCircleOutlined,
} from '@ant-design/icons';

import JoditEditor from '../../molecules/JoditEditor';
import SearchBar from '../../templates/SearchBar';
import StyledDrawer from '../../atoms/StyledDrawer';

const { Option } = Select;

function BoardView({
  form,
  list,
  paging,
  visible,
  selectedRows,
  isEdit,
  columnDefs,
  gridOptions,
  searchOption,
  bbsTypes,
  bbsCategories,
  onPaging,
  onCellClick,
  onGridReady,
  onDraw,
  onSelectionChanged,
  onFinish,
  onReset,
  onSearch,
  onDelete,
  onMoveList,
  onGridSizeChanged,
}) {
  const atchPosblFileNumber = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div>
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={18}>
              {/* SearchBar */}
              <SearchBar options={searchOption} onSearch={onSearch} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Space>
                <Button
                  type="primary"
                  // icon={<PlusCircleOutlined />}
                  onClick={() => {
                    onReset();
                    onDraw(true);
                  }}
                  ghost
                >
                  등록
                </Button>
                <Popconfirm
                  title="미사용 처리하시겠습니까?"
                  onConfirm={() => {
                    onDelete();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                  disabled={selectedRows.length < 1}
                >
                  <Button
                    type="danger"
                    htmlType="button"
                    disabled={selectedRows.length < 1}
                    icon={<DeleteOutlined />}
                  >
                    미사용
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ag-theme-alpine" style={{ height: 470 }}>
            <AgGridReact
              rowData={list}
              columnDefs={columnDefs}
              {...gridOptions}
              onGridReady={onGridReady}
              // onRowClicked={onRowClick}
              onCellClicked={onCellClick}
              onSelectionChanged={onSelectionChanged}
              onGridSizeChanged={onGridSizeChanged}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
          <Pagination
            pageSize={paging?.pageSize ?? 10}
            current={paging?.currentPageNo ?? 1}
            total={paging?.totalRecordCount ?? 0}
            onChange={onPaging}
          />
        </Col>
      </Row>
      <StyledDrawer
        title={`게시판 마스터 ${isEdit ? '수정' : '등록'}`}
        placement="right"
        width="40vw"
        open={visible}
        onClose={() => {
          onDraw(false);
          onMoveList();
        }}
        destroyOnClose
        footer={[
          <Row align="middle" justify="space-between">
            <Col>
              <Space>
                <Button
                  key="btn2"
                  disabled={isEdit}
                  htmlType="button"
                  onClick={() => {
                    onReset(isEdit);
                  }}
                >
                  초기화
                </Button>
              </Space>
            </Col>
            <Col>
              <Space>
                <Popconfirm
                  key="btn1"
                  title="저장 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    form.submit();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    // onClick={() => form.submit()}
                  >
                    저장
                  </Button>
                </Popconfirm>
                <Button
                  key="btn3"
                  onClick={() => {
                    onDraw(false);
                    onMoveList();
                  }}
                >
                  닫기
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {/* Hidden Value */}
          <Form.Item name="bbsId" noStyle>
            <Input hidden />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="게시판명"
                name="bbsNm"
                rules={[
                  {
                    required: true,
                    message: '게시판명을 입력해주세요.',
                  },
                ]}
                tooltip="게시판명을 입력해주세요."
              >
                <Input maxLength={70} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="게시판 유형"
                name="bbsTyCode"
                rules={[
                  { required: true, message: '게시판 유형을 선택해주세요.' },
                ]}
                tooltip="게시판 유형을 선택해주세요."
              >
                <Select options={bbsTypes} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="카테고리 유형"
                name="bbsCtCode"
                tooltip="카테고리 유형을 선택해주세요."
              >
                <Select options={bbsCategories} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="파일첨부가능여부"
                name="fileAtchPosblAt"
                rules={[
                  {
                    required: true,
                    message: '파일첨부가능여부를 선택해주세요.',
                  },
                ]}
                tooltip="파일첨부가능여부를 선택해주세요."
              >
                <Select>
                  <Option value="Y">예</Option>
                  <Option value="N">아니오</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="첨부가능파일숫자"
                name="atchPosblFileNumber"
                rules={[
                  {
                    required: true,
                    message: '첨부가능파일숫자를 선택해주세요.',
                  },
                ]}
                tooltip="첨부가능파일숫자를 선택해주세요."
              >
                <Select>
                  {atchPosblFileNumber.map(item => (
                    <Option value={item}>{item === 0 ? '없음' : item}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="답장가능여부"
                name="replyPosblAt"
                rules={[
                  { required: true, message: '답장가능여부를 선택해주세요.' },
                ]}
                tooltip="답장가능여부를 선택해주세요."
              >
                <Select>
                  <Option value="Y">예</Option>
                  <Option value="N">아니오</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="댓글여부"
                name="commentAt"
                rules={[
                  {
                    required: true,
                    message: '댓글여부를 선택해주세요.',
                  },
                ]}
                tooltip="댓글여부를 선택해주세요."
              >
                <Select>
                  <Option value="Y">예</Option>
                  <Option value="N">아니오</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="사용여부" name="useAt" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="게시판소개내용"
                name="bbsIntrcn"
                // noStyle
                rules={[
                  { required: true, message: '게시판소개내용을 입력해주세요.' },
                ]}
                tooltip="게시판소개내용을 입력해주세요."
              >
                <JoditEditor visible={visible} removeButtons={['image']} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDrawer>
    </div>
  );
}

BoardView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  visible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
};

BoardView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  visible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
};

export default BoardView;
