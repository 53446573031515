import React from 'react';
import { Button, Checkbox, Form, Input, Radio, Divider } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LogoImg from '../../../assets/img/login/logo.png';
import LoginImg from '../../../assets/img/login/login-img.png';

function LoginView({ form, initial, onFinish }) {
  return (
    <CmsLogin className="c-login">
      <div className="c-login__header">
        <img src={LogoImg} alt="바능 REACT" />
      </div>
      <div className="c-login__body">
        <div className="c-login__form">
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={initial}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="아이디"
              name="id"
              rules={[{ required: true, message: '아이디를 입력 해주세요' }]}
            >
              <Input size="large" placeholder="아이디를 입력하세요." />
            </Form.Item>
            <Form.Item
              label="패스워드"
              name="password"
              rules={[{ required: true, message: '패스워드를 입력 해주세요' }]}
            >
              <Input.Password
                size="large"
                placeholder="패스워드를 입력하세요."
              />
            </Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{ span: 16 }}
            >
              <Checkbox>아이디 저장하기</Checkbox>
            </Form.Item>
            <Divider dashed style={{ borderColor: '#ccc' }} />
            {/* <Form.Item name="userSe">
              <Radio.Group>
                <Radio value="GNR">일반</Radio>
                <Radio value="ENT">기업</Radio>
                <Radio value="USR">업무</Radio>
              </Radio.Group>
            </Form.Item> */}
            <Form.Item wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                loading={false}
              >
                로그인
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="c-login__item">
          <img src={LoginImg} alt="" />
        </div>
      </div>
      <div className="c-login__footer">
        <p>
          바능 CMS는 뛰어난 기술력을 가진 웹 콘텐츠 관리 시스템 솔루션입니다.
        </p>
      </div>
    </CmsLogin>
  );
}

const CmsLogin = styled.article`
  display: flex;
  flex-direction: column;
  width: 80rem;
  .c-login {
    &__header {
      margin-bottom: 2.8rem;
    }
    &__body {
      display: flex;
      background: var(--color-white);
      height: 46.2rem;
      box-shadow: 0px 1.5rem 2.5rem var(--color-black-rgba-10);
    }
    &__form,
    &__item {
      width: calc(100% / 2);
    }
    &__form {
      display: flex;
      align-items: center;
      padding: 3rem;
      form {
        flex: 1;
        .ant-form-item-label
          > label.ant-form-item-required:not(
            .ant-form-item-required-mark-optional
          )::before {
          display: none;
        }
        .ant-input:not(textarea) {
          font-size: 1.4rem;
          border-color: var(--color-primary);
        }
        .ant-input-affix-wrapper {
          border-color: #666;
        }
      }
    }
    &__item {
      display: flex;
      align-items: center;
      background: #eefefd;
    }
    &__footer {
      margin-top: 4.4rem;
      p {
        text-align: center;
      }
    }
  }
`;

LoginView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  onFinish: PropTypes.func,
};

LoginView.defaultProps = {
  form: [],
  onFinish: () => {},
};

export default LoginView;
