import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Form, message, Modal } from 'antd';
import moment from 'moment';

import CodeCategoryView from './CodeCategoryView';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import { GRID_LINK_CELL_STYLE } from '../../../config/contants';

// const sleep = ms => new Promise(r => setTimeout(r, ms));
/**
 * 상세코드 관리
 * @returns
 */
function CodeCategory() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: '',
      headerName: '',
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'rnum',
      headerName: 'No',
      width: 60,
    },
    {
      field: 'clCode',
      headerName: '분류코드',
      width: 150,
      cellStyle: { ...GRID_LINK_CELL_STYLE },
    },
    {
      field: 'clCodeNm',
      headerName: '분류코드 명',
      width: 150,
    },
    {
      field: 'useAt',
      headerName: '사용여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.useAt === 'Y' ? '사용' : '미사용';
      },
    },
    { field: 'frstRegisterId', headerName: '등록자', width: 100 },
    {
      field: 'frstRegistPnttm',
      headerName: '등록일',
      width: 100,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
  ]);

  const [visible, setVisible] = useState(false);

  // selectedRows
  const [selectedRows, setSelectedRows] = useState([]);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchCondition: '1',
      searchKeyword: '',
    },
    items: [
      {
        type: 'select',
        name: 'searchCondition',
        placeholder: '선택하세요.',
        codes: [
          { value: '1', text: '분류코드' },
          { value: '2', text: '분류코드명' },
        ],
      },
      {
        name: 'searchKeyword',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/cmmn/code/selectCcmCmmnClCodeList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/cmmn/code/selectCcmCmmnClCodeDetail',
    {
      clCode: searchParams.get('clCode'),
    },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('clCode'),
    },
  );

  /**
   * API - 정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation('/api/admin/cmmn/code/insertCcmCmmnClCode');

  /**
   * API - 정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation(
    '/api/admin/cmmn/code/deleteCcmCmmnClCode',
  );

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation(
    '/api/admin/cmmn/code/updateCcmCmmnClCode',
  );

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {
    console.log('🚀 ~ handleRowClick ~ params', params);
    /*
    const { data } = params;
    if (data?.siteId) {
      navigate({
        pathname: '/cms/site',
        search: `?${createSearchParams({
          id: data.siteId,
        })}`,
      });

      // Open Drawer
      if (!visible) handleDraw(true);
    }
    */
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    // console.log('🚀 handleCellClick ~ params', params);
    const { data, colDef } = params;
    if (colDef?.field === 'clCode' && data?.clCode) {
      // go Navigate
      navigate({
        pathname: '/cms/codeCategory',
        search: `?${createSearchParams({
          clCode: data.clCode,
        })}`,
      });

      // refetchDetail
      if (searchParams.get('clCode')) refetchDetail();

      // Open Drawer
      // if (!visible) handleDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDraw = bool => {
    setVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/codeCategory');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Row Selection Checked
   * @param {*} bool
   */
  const handleSelectionChanged = ({ api }) => {
    const _selectedRows = api.getSelectedRows();
    // Set SelectedRows
    setSelectedRows(_selectedRows);
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = values => {
    const dataValues = {
      ...values,
      useAt: values.useAt ? 'Y' : 'N',
      frstRegisterId: 'admin', // 등록자 @TODO - 세션적용여부
    };

    //  return;
    if (isEdit) {
      const sendValues = {
        ...dataValues,
      };
      // API Call - 권한 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
      };
      // API Call - 권한 정보보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          // console.log('🚀 ~ addMutation ~ onSuccess: ~ res', res);
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * 권한 정보삭제 처리
   */
  const handleDelete = () => {
    if (selectedRows && Array.isArray(selectedRows)) {
      if (selectedRows.length < 1) {
        Modal.warning({
          content: '삭제할 대상을 선택해주세요.',
        });
        return;
      }
      const clCodes = selectedRows.map(record => {
        return { clCode: record.clCode };
      });
      const sendDataValues = {
        clCodes,
      };

      // API Call - 권한정보 삭제하기
      deleteMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '삭제 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // deselect
          if (gridApi) gridApi.deselectAll();
        },
      });
    }
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    console.log('🚀 ~ handleReset ~ _isEdit, detailData', _isEdit, detailData);
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    if (detail?.result) {
      const viewData = {
        ...detail?.result,
        useAt: detail.result.useAt === 'Y',
      };
      // Set form Fileds value
      form.setFieldsValue({
        ...viewData,
      });

      // Open Drawer
      if (!visible) handleDraw(true);
      // 상세정보 세팅
      setDetailData({ ...viewData });
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [detail, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.result,
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    visible,
    // detail,
    selectedRows,
    isEdit,
    searchOption,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onDraw: handleDraw,
    onGridReady: handleGridOption,
    onSelectionChanged: handleSelectionChanged,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
  };

  return <CodeCategoryView {...props} />;
}

export default CodeCategory;
