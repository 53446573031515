import React from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
function SiteView({ children, ...rest }) {
  return <StyledDrawer {...rest}>{children}</StyledDrawer>;
}

const StyledDrawer = styled(Drawer)`
  .ant-drawer {
    &-header {
      background: var(--color-primary);
      /* 뒤로가기 버튼 */
      .btn-goback {
        color: var(--color-white);
        font-size: 1.6rem;
        border: 0;
      }
    }
    &-title {
      color: var(--color-white);
    }
    &-close {
      order: 2;
      margin-right: -1rem;
      color: var(--color-white);
    }
    &-body {
      .form-item__text {
        .ant-form-item-control-input {
          padding: 4px 11px;
          background-color: var(--color-gray-f7f7f7);
        }
      }
    }
  }
`;

export default SiteView;
