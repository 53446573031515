import React, { useState, useEffect } from 'react';
import { Form, message, Modal } from 'antd';
import moment from 'moment';

import CommentListView from './CommentListView';
import { useMutation, useQuery } from '../../../../hooks/useQuery';
import useStoreSite from '../../../../hooks/useStoreSite';
import { GRID_LINK_CELL_STYLE } from '../../../../config/contants';

/**
 * 게시판 관리
 * @returns
 */
function CommentList({ bbsId, nttId }) {
  const [form] = Form.useForm();

  /**
   * @todo-사이트ID 확인 필요
   * store state - siteId
   */
  const { siteId: storeSiteId } = useStoreSite();
  const SITE_ID = !storeSiteId
    ? process.env.REACT_APP_DEFAULT_SITE_ID
    : storeSiteId;

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
    siteId: SITE_ID,
    bbsId,
    nttId,
  };

  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();
  const [commentNo, setCommentNo] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: 'commentCn',
      headerName: '댓글',
      width: 280,
      cellStyle: { ...GRID_LINK_CELL_STYLE },
    },
    { field: 'wrterId', headerName: '등록자', width: 100 },
    {
      field: 'frstRegisterPnttm',
      headerName: '등록일',
      width: 100,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
  ]);

  // 상세 drawer 출력 여부
  const [detailVisible, setDetailVisible] = useState(false);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/comment/selectCommentList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * API - 상세정보 조회
   */
  const { data: detail, refetch: refetchDetail } = useQuery(
    '/api/admin/comment/selectCommentDetail',
    { commentNo, siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!commentNo,
      onSuccess: res => {
        if (res?.resultList) {
          form.setFieldsValue({ ...res.resultList });
          setIsEdit(true);
        }
      },
    },
  );

  /**
   * SITE_ID(리덕스 Store)의 정보가 변경 될 시
   * 목록 리로드
   */
  useEffect(() => {
    if (!!SITE_ID && SITE_ID.length > 0) {
      setSearch(() => {
        return { ...initialSearchValues, siteId: SITE_ID };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SITE_ID]);

  /**
   * API - 정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation('/api/admin/comment/insertComment');

  /**
   * API - 정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation('/api/admin/comment/deleteComment');

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation('/api/admin/comment/updateComment');

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    const { data, colDef } = params;
    if (colDef?.field === 'commentCn' && data?.commentNo) {
      setCommentNo(data?.commentNo);
      refetchDetail();
      handleDetailDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDetailDraw = bool => {
    setDetailVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    refetchList();
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = async values => {
    const dataValues = {
      ...values,
      useAt: values.useAt ? 'Y' : 'N',
    };

    if (isEdit) {
      const sendValues = {
        ...dataValues,
        siteId: SITE_ID,
        lastUpdusrId: 'admin',
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          // eslint-disable-next-line no-console
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Close Drawer
          handleDetailDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
        siteId: SITE_ID,
        bbsId,
        nttId,
        wrterId: 'admin',
        wrterNm: 'admin',
        frstRegisterId: 'admin',
      };
      // API Call - 정보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          // eslint-disable-next-line no-console
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Close Drawer
          handleDetailDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * 정보삭제 처리
   */
  const handleDelete = () => {
    if (!commentNo) {
      Modal.warning({
        content: '삭제할 대상을 선택해주세요.',
      });
      return;
    }
    const sendDataValues = {
      commentNo,
      lastUpdusrId: 'admin',
    };
    // API Call - 정보 삭제하기
    deleteMutation.mutate(sendDataValues, {
      onSuccess: res => {
        if (res?.status === 200) {
          // Message
          Modal.success({
            content: '삭제 하였습니다.',
            onOk: () => {
              // Refetch
              refetchList();
            },
          });
        } else {
          const { status, statusText } = res;
          message.error(`[${status} - ${statusText}]`);
        }
      },
      onError: async error => {
        // eslint-disable-next-line no-console
        console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
      },
      onSettled: async () => {
        // Close Drawer
        handleDetailDraw(false);
        // Move List page
        handleMoveList();
      },
    });
  };

  /**
   * 정보 초기화
   * @param {*} _isEdit
   */
  const handleReset = (_isEdit = false) => {
    if (_isEdit) {
      // 수정시
      form.setFieldsValue({ ...detail.resultList });
    } else {
      // 등록시
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.resultList,
    columnDefs,
    gridOptions: {
      rowSelection: 'single',
      headerHeight: 40,
    },
    detailVisible,
    detail,
    isEdit,
    onPaging: handlePagination,
    onCellClick: handleCellClick,
    onDetailDraw: handleDetailDraw,
    onGridReady: handleGridOption,
    onFinish: handleFinish,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onReset: handleReset,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
  };

  return <CommentListView {...props} />;
}

export default CommentList;
