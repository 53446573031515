import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import DashboardIcon from '../../atoms/Icon/DashboardIcon';
import VisitorIcon from '../../atoms/Icon/visitorIcon';

function DashboardView() {
  return (
    <Row gutter={[24, 24]}>
      <Col span={16}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <StyledInfoBox>
              <DashboardIcon />
              <div>
                <strong>Playground</strong>
                <p>
                  ReactJS + Spring Boot + 전자정부프레임워크 기반의 CMS 솔루션
                </p>
              </div>
            </StyledInfoBox>
          </Col>
          <Col span={8}>
            <StyledBox className="site-info">
              <div className="box__header">
                <strong>사이트 정보</strong>
                <i className="ri-computer-line" />
              </div>
              <div className="box__body">
                <div>사이트명 : 데모사이트</div>
                <div>도메인 : demo.com</div>
                <div className="site-info__list">
                  <div>
                    <strong>활성여부</strong>
                    <i className="ri-toggle-fill" />
                  </div>
                  <div>
                    <strong>사용여부</strong>
                    <i className="ri-toggle-line" />
                  </div>
                </div>
              </div>
            </StyledBox>
          </Col>
          <Col span={8}>
            <StyledBox className="popular-menu">
              <div className="box__header">
                <strong>인기 메뉴</strong>
                <i className="ri-menu-2-fill" />
              </div>
              <div className="box__body">
                <ul className="popular-menu__list">
                  <li>
                    <Link to="/">회사소개</Link>
                  </li>
                  <li>
                    <Link to="/">컨텐츠 관리</Link>
                  </li>
                  <li>
                    <Link to="/">개인정보 보호정책 관리</Link>
                  </li>
                </ul>
              </div>
            </StyledBox>
          </Col>
          <Col span={8}>
            <StyledBox className="visitor-info">
              <div className="box__header">
                <strong>방문자수</strong>
                <i className="ri-user-3-line" />
              </div>
              <div className="box__body">
                <ul className="visitor-info__list">
                  <li>
                    <strong>Today</strong>
                    <em>405</em>
                  </li>
                  <li>
                    <strong>Total</strong>
                    <em>30,405</em>
                  </li>
                </ul>
                <VisitorIcon />
              </div>
            </StyledBox>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <StyledBox className="new-post">
          <div className="box__header">
            <strong>신규 게시글</strong>
          </div>
          <div className="box__body">
            <ul className="new-post__list">
              <li>
                <Link to="/">
                  <strong>홈페이지 신규 게시글 테스트입니다.</strong>
                  <span>2022-12-15</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <strong>홈페이지 신규 게시글 테스트입니다.</strong>
                  <span>2022-12-15</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <strong>홈페이지 신규 게시글 테스트입니다.</strong>
                  <span>2022-12-15</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <strong>
                    홈페이지 신규 게시글 테스트입니다 홈페이지 신규 게시글
                    테스트입니다 홈페이지 신규 게시글 테스트입니다.
                  </strong>
                  <span>2022-12-15</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <strong>홈페이지 신규 게시글 테스트입니다.</strong>
                  <span>2022-12-15</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <strong>홈페이지 신규 게시글 테스트입니다.</strong>
                  <span>2022-12-15</span>
                </Link>
              </li>
            </ul>
          </div>
        </StyledBox>
      </Col>
      <Col span={16}>
        <StyledBox>
          <div className="box__header">
            <strong>통계챠트</strong>
          </div>
          <div className="box__body">
            <div style={{ height: 250 }}>챠트 영역</div>
          </div>
        </StyledBox>
      </Col>
      <Col span={8}>
        <StyledBox>
          <div className="box__header">
            <strong>가입자 현황</strong>
          </div>
          <div className="box__body">
            <div style={{ height: 250 }}>챠트 영역</div>
          </div>
        </StyledBox>
      </Col>
    </Row>
  );
}

const StyledInfoBox = styled.div`
  display: flex;
  align-items: center;
  height: 12.8rem;
  margin-top: 4rem;
  padding: 2rem 2.4rem;
  color: var(--color-white);
  border-radius: 1rem;
  background: var(--color-primary);
  box-shadow: 0px 0px 35px 0px rgba(154, 171, 169, 0.15);
  svg {
    width: 30rem;
    height: auto;
    margin-left: -3rem;
  }
  strong {
    font-size: 2.5rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 0;
  }
`;
const StyledBox = styled.div`
  position: relative;
  min-height: 100%;
  padding: 2rem 2.4rem;
  font-size: 1.4rem;
  background: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0px 0px 35px 0px rgba(154, 171, 169, 0.15);
  .box {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      strong {
        color: var(--color-black);
        font-size: 1.5rem;
        font-weight: 500;
      }
      i {
        font-size: 2rem;
        color: var(--color-primary);
        padding: 0.4rem 1rem;
        border-radius: 0.5rem;
        background: var(--green-color-rgba-10);
      }
    }
  }
  /* 사이트 정보 */
  .site-info {
    &__list {
      display: flex;
      justify-content: space-between;
      margin-top: 1.6rem;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% / 2 - 0.8rem);
        padding: 0.5rem 1rem;
        border: 1px dashed var(--color-gray-d9d9d9);
        strong {
          color: var(--color-gray-3c3c3c);
          font-weight: 500;
        }
      }
      i {
        font-size: 2.7rem;
        /* 활성 */
        &[class^='ri-toggle-fill'] {
          color: var(--color-primary);
        }
        /* 비활성 */
        &[class^='ri-toggle-line'] {
          color: var(--color-gray-c8c8c8);
        }
      }
    }
  }
  /* 인기메뉴 */
  .popular-menu {
    &__list {
      li {
        & + li {
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px dashed var(--color-gray-d9d9d9);
        }
      }
    }
  }
  /* 방문자수 */
  &.visitor-info {
    svg {
      position: absolute;
      right: -0.25rem;
      bottom: 0rem;
      width: 170px;
      height: auto;
    }
  }
  .visitor-info {
    &__list {
      position: absolute;
      right: 50%;
      left: 2.4rem;
      bottom: 2rem;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & + li {
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px dashed var(--color-gray-d9d9d9);
        }
        strong {
          font-weight: 300;
        }
        em {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-size: 2.2rem;
        }
      }
    }
  }
  /* 신규게시글 */
  &.new-post {
    margin-top: 4rem;
    min-height: calc(100% - 4rem);
    .box__header {
      margin-bottom: 2.4rem;
    }
  }
  .new-post {
    &__list {
      li {
        & + li {
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px dashed var(--color-gray-d9d9d9);
        }
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          strong {
            flex: 1;
            margin-right: 1rem;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          span {
            color: var(--color-gray-737373);
            font-size: 1.3rem;
          }
        }
      }
    }
  }
`;

export default DashboardView;
