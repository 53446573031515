import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Form, message, Modal } from 'antd';
import moment from 'moment';
import { v4 } from 'uuid';

import ListView from './ListView';
import { useMutation, useQuery } from '../../../../hooks/useQuery';
import useStoreSite from '../../../../hooks/useStoreSite';
import { GRID_LINK_CELL_STYLE } from '../../../../config/contants';
import { getFileData } from '../../../../config/utils/util';

// const sleep = ms => new Promise(r => setTimeout(r, ms));
/**
 * 게시판 관리
 * @returns
 */
function List() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  /**
   * @todo-사이트ID 확인 필요
   * store state - siteId
   */
  // const storeSiteId = useSelector(state => state.site.siteId, shallowEqual);
  const { siteId: storeSiteId } = useStoreSite();
  const SITE_ID = !storeSiteId
    ? process.env.REACT_APP_DEFAULT_SITE_ID
    : storeSiteId;

  const [bbsId, setBbsId] = useState('');
  const [bbsTyCode, setBbsTyCode] = useState('');
  const [bbses, setBbses] = useState([]);
  const [codeId, setCodeId] = useState('');
  const [nttId, setNttId] = useState('');
  const [bbsTypes, setBbsTypes] = useState();

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
    siteId: SITE_ID,
    bbsId,
  };

  // 게시판 마스터 아이디
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: '',
      headerName: '',
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'rnum',
      headerName: 'No',
      width: 60,
    },
    {
      field: 'nttSj',
      headerName: '제목',
      cellStyle: { ...GRID_LINK_CELL_STYLE },
      width: 250,
    },
    {
      field: 'mainAt',
      headerName: '메인노출',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.mainAt === 'Y' ? '노출' : '미노출';
      },
    },
    {
      field: 'useAt',
      headerName: '사용여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.useAt === 'Y' ? '사용' : '미사용';
      },
    },
    { field: 'frstRegisterId', headerName: '등록자', width: 100 },
    {
      field: 'frstRegisterPnttm',
      headerName: '등록일',
      width: 120,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
  ]);

  const [detailVisible, setDetailVisible] = useState(false);
  const [commentVisible, setCommentVisible] = useState(false);

  // selectedRows
  const [selectedRows, setSelectedRows] = useState([]);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchWrd: '',
    },
    items: [
      {
        name: 'searchWrd',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  // 삭제 파일 목록
  const [removedFiles, setRemovedFiles] = useState(null);
  // 삭제 파일 목록(썸네일)
  const [removedFiles2, setRemovedFiles2] = useState(null);
  /**
   * API - 게시글 목록 조회
   */
  const { data: bbsList, refetch: refetchBbs } = useQuery(
    '/api/admin/board/selectBBSMasterInfs',
    { siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!SITE_ID,
      onSuccess: res => {
        if (res) {
          if (res.resultList) {
            setCodeId(res.resultList[0]?.codeId ?? '');
            setBbsId(res.resultList[0]?.bbsId ?? '');
            setBbses(res.resultList?.filter(item => item.useAt === 'Y'));
            setSearch(prevState => {
              return {
                ...prevState,
                bbsId: res.resultList[0]?.bbsId ?? '',
              };
            });
            refetchCode();
          }
        }
      },
    },
  );

  /**
   * API - 상세 코드 가져오기
   */
  const { data: detailCodes, refetch: refetchCode } = useQuery(
    '/api/admin/cmmn/code/selectSpecificDetailCode',
    { codeId },
    {
      cacheTime: 0,
      enabled: !!codeId,
      onSuccess: res => {
        if (res) {
          setBbsTypes(
            res.resultList.map(item => {
              return {
                value: item.code,
                label: item.codeNm,
              };
            }),
          );
        }
      },
    },
  );

  /**
   * API - 게시판 마스터 상세정보 조회
   */
  const { data: bbsMstrDtl } = useQuery(
    '/api/admin/board/selectBBSMasterDetail',
    { bbsId },
    {
      cacheTime: 0,
      enabled: !!bbsId,
      onSuccess: res => {
        if (res) {
          if (res?.result?.bbsCtCode) setCodeId(res?.result?.bbsCtCode);
          else setBbsTypes({});

          return res.result;
        }
      },
    },
  );

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/article/selectArticleList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * SITE_ID(리덕스 Store)의 정보가 변경 될 시
   * 목록 리로드
   */
  useEffect(() => {
    if (!!SITE_ID && SITE_ID.length > 0) {
      refetchBbs();
      setSearch(() => {
        return { ...initialSearchValues, bbsId, siteId: SITE_ID };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SITE_ID]);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    bbsMstrDtl?.result?.bbsTyCode === 'BBST05'
      ? '/api/bbsAnno/selectBbsAnnoDetail'
      : '/api/admin/article/selectArticleDetail',
    { nttId: searchParams.get('nttId'), bbsId, siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('nttId'),
    },
  );

  /**
   * API - 파일 목록 조회
   */
  const { data: fileList } = useQuery('/api/file/selectFileList', {
    groupId: detail?.result?.atchGroupId,
    dataUpdatedAt,
  });

  /**
   * API - 정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation(
    bbsMstrDtl?.result?.bbsTyCode === 'BBST05'
      ? '/api/admin/bbsAnno/insertBbsAnno'
      : '/api/admin/article/insertArticle',
  );

  /**
   * API - 정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation('/api/admin/article/deleteArticle');

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation(
    bbsMstrDtl?.result?.bbsTyCode === 'BBST05'
      ? '/api/admin/bbsAnno/updateBbsAnno'
      : '/api/admin/article/updateArticle',
  );

  /**
   * API - 파일Upload
   * @TODO - 업로드 API 수정필요
   */
  const saveFilesMutation = useMutation(
    '/api/file/insertFiles',
    { params: { groupId: isEdit ? detail?.result?.atchGroupId ?? '' : '' } },
    true,
  );

  /**
   * API - 썸네일 Upload
   * @TODO - 업로드 API 수정필요
   */
  const saveFilesMutation2 = useMutation(
    '/api/file/insertFiles',
    { params: { groupId: '' } },
    true,
  );

  /**
   * API - 파일삭제
   */
  const deleteFilesMutation = useMutation('/api/file/deleteFiles');

  /**
   * File Upload
   * @param {*} fileObj
   * @returns
   */
  const saveFiles = async (files, id) => {
    let res;
    try {
      const uploadFiles = files
        .filter(file => file.originFileObj)
        .map(file => {
          return file;
        });

      res = await saveFilesMutation.mutateAsync(uploadFiles);
    } catch (error) {
      console.error('saveFiles', error);
    }
    return res;
  };

  /**
   * File Upload2  (썸네일)
   * @param {*} fileObj
   * @returns
   */
  const saveFiles2 = async (files, id) => {
    let res;
    try {
      const uploadFiles = files
        .filter(file => file.originFileObj)
        .map(file => {
          return file;
        });

      res = await saveFilesMutation2.mutateAsync(uploadFiles);
    } catch (error) {
      console.error('saveFiles', error);
    }
    return res;
  };

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 게시판 마스터 셋
   * @param {*} value
   */
  const handleBbsId = value => {
    setBbsId(value);

    navigate({
      pathname: '/cms/board/list',
      search: '',
    });

    setSearch(() => {
      return { ...initialSearchValues, bbsId: value };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭 (사용안함)
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {
    // console.log('🚀 ~ handleRowClick ~ params', params);
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    // console.log('🚀 handleCellClick ~ params', params);
    const { data, colDef } = params;
    if (colDef?.field === 'nttSj' && data?.nttId) {
      // go Navigate
      navigate({
        pathname: '/cms/board/list',
        search: `?${createSearchParams({
          nttId: data.nttId,
        })}`,
      });

      if (searchParams.get('nttId')) refetchDetail();
    }
  };

  /**
   * draw 핸들러 => detail
   * @param {*} bool
   */
  const handleDetailDraw = bool => {
    setDetailVisible(bool);
  };

  /**
   * draw 핸들러 => comment
   * @param {*} bool
   */
  const handleCommentDraw = bool => {
    setCommentVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/board/list');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Row Selection Checked
   * @param {*} bool
   */
  const handleSelectionChanged = ({ api }) => {
    const _selectedRows = api.getSelectedRows();
    // Set SelectedRows
    setSelectedRows(_selectedRows);
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = async values => {
    const { atchFile, thumFile } = values;
    let atchGroupId;
    let atchFileIds; // 첨부파일 ID (사용안하는거 같음)
    let atchFileId = ''; // 썸네일

    // 첨부파일 Upload
    if (atchFile && atchFile.length > 0) {
      const newAtchFile = atchFile.filter(d => d.originFileObj);
      atchFileIds = atchFile.map(d => d.name);
      if (newAtchFile && newAtchFile.length > 0) {
        try {
          const res = await saveFiles(newAtchFile);
          atchGroupId = res.result.groupId;
        } catch (err) {
          console.error('saveFiles err', err.message);
        }
      }
    }

    // 썸네일 Upload
    if (thumFile && thumFile.length > 0) {
      const newThumFile = thumFile.filter(d => d.originFileObj);
      if (newThumFile && newThumFile.length > 0) {
        try {
          const res = await saveFiles2(newThumFile);
          if (res) {
            atchFileId = res?.result?.fileIds[0]; // 기존처럼 배열로 -> (수정) 1개 String
          }
        } catch (err) {
          console.error('saveFiles2 err', err.message);
        }
      }
    }

    if (atchFileId === '') {
      atchFileId = detail?.result?.atchFileId;
    }

    // 삭제 첨부파일
    if (removedFiles && removedFiles.length > 0) {
      deleteFilesMutation.mutate(removedFiles);
    }
    // 삭제 썸네일
    if (removedFiles2 && removedFiles2.length > 0) {
      deleteFilesMutation.mutate(removedFiles2);
    }
    // 물건공고
    const iemList = [];
    if (bbsMstrDtl?.result?.bbsTyCode === 'BBST05') {
      iemList.push(
        {
          iemCd: 'IEM001',
          iemDc: values.IEM001 === undefined ? '' : values.IEM001,
          registSeCd: 'REG001',
        },
        {
          iemCd: 'IEM002',
          iemDc: values.IEM002 === undefined ? '' : values.IEM002,
          registSeCd: 'REG001',
        },
        {
          iemCd: 'IEM003',
          iemDc: values.IEM003 === undefined ? '' : values.IEM003,
          registSeCd: 'REG001',
        },
        {
          iemCd: 'IEM004',
          iemDc: values.IEM004 === undefined ? '' : values.IEM004,
          registSeCd: 'REG001',
        },
        {
          iemCd: 'IEM005',
          iemDc: values.IEM005 === undefined ? '' : values.IEM005,
          registSeCd: 'REG002',
        },
        {
          iemCd: 'IEM006',
          iemDc: values.IEM006 === undefined ? '' : values.IEM006,
          registSeCd: 'REG002',
        },
        {
          iemCd: 'IEM007',
          iemDc: values.IEM007 === undefined ? '' : values.IEM007,
          registSeCd: 'REG002',
        },
        {
          iemCd: 'IEM008',
          iemDc: values.IEM008 === undefined ? '' : values.IEM008,
          registSeCd: 'REG002',
        },
        {
          iemCd: 'IEM009',
          iemDc: values.IEM009 === undefined ? '' : values.IEM009,
          registSeCd: 'REG002',
        },
      );
    }

    const dataValues = {
      ...values,
      mainAt: values.mainAt === undefined || values.mainAt ? 'Y' : 'N',
      useAt: values.useAt === undefined || values.useAt ? 'Y' : 'N',
      ntceBgnde: values.ntceRange
        ? values.ntceRange[0].format('YYYY-MM-DD')
        : '',
      ntceEndde: values.ntceRange
        ? values.ntceRange[1].format('YYYY-MM-DD')
        : '',
      atchGroupId,
      atchFileIds,
      atchFileId,
      iemList,
    };

    /* eslint no-unreachable: "off" */
    // return;
    // console.info(dataValues);

    if (isEdit) {
      const sendValues = {
        ...dataValues,
        siteId: SITE_ID,
        bbsId,
        lastUpdusrId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDetailDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
        siteId: SITE_ID,
        bbsId,
        frstRegisterId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDetailDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * 정보삭제 처리
   */
  const handleDelete = () => {
    if (selectedRows && Array.isArray(selectedRows)) {
      if (selectedRows.length < 1) {
        Modal.warning({
          content: '삭제할 대상을 선택해주세요.',
        });
        return;
      }
      const nttIds = selectedRows.map(board => {
        return {
          nttId: board.nttId,
        };
      });
      const sendDataValues = {
        bbsId,
        nttIdList: nttIds,
        lastUpdusrId: 'admin',
      };

      // API Call - 정보 삭제하기
      deleteMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '삭제 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // deselect
          if (gridApi) gridApi.deselectAll();
        },
      });
    }
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    setRemovedFiles(null); // 삭제 첨부파일 목록
    setRemovedFiles2(null); // 삭제 썸네일 목록
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  // 첨부파일 삭제
  const handleFileRemove = e => {
    setRemovedFiles([...(removedFiles ?? []), { atchFileId: e.id }]);
  };
  // 썸네일 삭제
  const handleFileRemove2 = e => {
    setRemovedFiles2([...(removedFiles2 ?? []), { atchFileId: e.id }]);
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    if (detail?.result) {
      const formData = {
        ...detail.result,
        mainAt: detail.result.mainAt === 'Y',
        useAt: detail.result.useAt === 'Y',
        ntceRange: [
          detail.result.ntceBgnde ? moment(detail.result.ntceBgnde) : null,
          detail.result.ntceEndde ? moment(detail.result.ntceEndde) : null,
        ],
        IEM001: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM001',
        )[0]?.iemDc,
        IEM002: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM002',
        )[0]?.iemDc,
        IEM003: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM003',
        )[0]?.iemDc,
        IEM004: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM004',
        )[0]?.iemDc,
        IEM005: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM005',
        )[0]?.iemDc,
        IEM006: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM006',
        )[0]?.iemDc,
        IEM007: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM007',
        )[0]?.iemDc,
        IEM008: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM008',
        )[0]?.iemDc,
        IEM009: detail?.result?.iemList?.filter(
          item => item.iemCd === 'IEM009',
        )[0]?.iemDc,
        thumFile: detail.result.orignlFileNm
          ? [
              {
                name: detail.result.orignlFileNm, // 썸네잂 파일명
                id: detail.result.atchFileId, // 썸네잂 파일ID
                uid: v4(),
                status: 'done',
                url: `${process.env.REACT_APP_STORAGE_URI}${detail.result.orignlFileUrl}`,
              },
            ]
          : null,
      };
      // const iemList = detail?.result.iemList;
      // console.info(iemList);
      // const result = iemList.filter(item => item.iemCd === 'IEM002')[0].iemDc;
      // console.info(result);

      // Set form Fileds value
      form.setFieldsValue({
        ...formData,
      });

      // Open Drawer
      if (!detailVisible) handleDetailDraw(true);
      // 상세정보 세팅
      setDetailData({ ...formData });
      // 수정여부-수정
      setIsEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, dataUpdatedAt, form]);

  useEffect(() => {
    setRemovedFiles(null);
    if (fileList && fileList.fileList.length > 0) {
      form.setFieldsValue({
        atchFile: getFileData(fileList.fileList),
      });
      if (bbsTyCode === 'BBST02') {
        form.setFieldValue(
          'relateImagePath',
          getFileData(fileList.fileList)[0].url,
        );
      }
    } else {
      form.setFieldsValue({
        atchFile: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    bbsId,
    bbsTyCode,
    nttId,
    list: data?.resultList,
    detail, // 게시글 상세정보
    bbsMstrDtl, // 게시판 상세정보
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    detailVisible,
    commentVisible,
    selectedRows,
    isEdit,
    searchOption,
    bbses,
    bbsTypes,
    onBbsId: handleBbsId,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onDetailDraw: handleDetailDraw,
    onCommentDraw: handleCommentDraw,
    onGridReady: handleGridOption,
    onSelectionChanged: handleSelectionChanged,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
    onFileRemove: handleFileRemove,
    onFileRemove2: handleFileRemove2,
  };

  return <ListView {...props} />;
}

export default List;
