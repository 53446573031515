import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Descriptions } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import styled from 'styled-components';

import { useQuery } from '../../../hooks/useQuery';

const statusMap = {
  W: '대기',
  P: '진행',
  E: '종료',
};

const SurveyRespondTable = ({ respondId }) => {
  // console.log('🚀 ~ SurveyRespondTable ~ respondId', respondId);
  const [answerItem, setAnswerItem] = useState({
    info: {
      qustnrSj: '',
      progrsSttus: '',
      qustnrBgnde: '',
      qustnrEndde: '',
      qustnrPurps: '',
    },
    qustnrWritngGuidanceCn: '',
    respond: {
      brthdy: '',
      respondNm: '',
      areaNo: '',
      middleTelno: '',
      endTelno: '',
    },
    answers: [],
  });

  /**
   * API - 상세정보 조회
   */
  const {
    data: answerDetail,
    // refetch: refetchDetail,
    dataUpdatedAt,
    isLoading,
    isFetching,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/question/result/selectAnswer',
    { qustnrRespondId: respondId },
    {
      cacheTime: 0,
      enabled: !!respondId,
    },
  );

  /**
   * useEffect
   */
  useEffect(() => {
    if (answerDetail) {
      setAnswerItem({ ...answerDetail });
    }
  }, [answerDetail, dataUpdatedAt]);

  return (
    <Container>
      <div className="survey-answer">
        <div className="survey-answer__header">
          <strong>{answerItem?.info?.qustnrSj || '-'}</strong>
          <div className="survey-answer__detail">
            <div>
              {answerItem?.info?.qustnrBgnde &&
              answerItem?.info?.qustnrEndde ? (
                <div>
                  <span>
                    {moment(answerItem?.info?.qustnrBgnde).format('YYYY-MM-DD')}
                  </span>
                  <span>~</span>
                  <span>
                    {moment(answerItem?.info?.qustnrEndde).format('YYYY-MM-DD')}
                  </span>
                </div>
              ) : null}
            </div>
            <span>{statusMap[answerItem?.info?.progrsSttus] || '-'}</span>
          </div>
        </div>
        <div className="survey-answer__body">
          <dl className="survey-answer__list">
            <dt>목적</dt>
            <dd> {answerItem?.info?.qustnrPurps || '-'}</dd>
            <dt>안내내용</dt>
            <dd>
              {answerItem?.info?.qustnrWritngGuidanceCn && (
                <FroalaEditorView
                  model={answerItem?.info?.qustnrWritngGuidanceCn}
                />
              )}
            </dd>
          </dl>
        </div>
      </div>
      <div className="survey-respondent">
        <Row gutter={[16, 8]}>
          <Col span={12}>
            <em>성별</em> {answerItem?.respond?.sexdstnCode}
          </Col>
          <Col span={12}>
            <em>응답자명</em> {answerItem?.respond?.respondNm}
          </Col>
          <Col span={12}>
            <em>생년월일</em> {answerItem?.respond?.brthdy}
          </Col>
          <Col span={12}>
            <em>전화번호</em>
            <span>
              {answerItem?.respond?.areaNo === 'NULL'
                ? '-'
                : answerItem?.respond?.areaNo}
            </span>
          </Col>
        </Row>
      </div>
      {/* <Row style={{ marginBottom: '1rem' }}>
        <Col sm={24} xs={24}>
          <Descriptions size="small" bordered column={2}>
            <Descriptions.Item label="제목" span={2}>
              {answerItem?.info?.qustnrSj || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="상태">
              {statusMap[answerItem?.info?.progrsSttus] || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="기간">
              {answerItem?.info?.qustnrBgnde &&
              answerItem?.info?.qustnrEndde ? (
                <div>
                  <span>
                    {moment(answerItem?.info?.qustnrBgnde).format('YYYY-MM-DD')}
                  </span>
                  <span>~</span>
                  <span>
                    {moment(answerItem?.info?.qustnrEndde).format('YYYY-MM-DD')}
                  </span>
                </div>
              ) : null}
            </Descriptions.Item>
            <Descriptions.Item label="목적" span={2}>
              {answerItem?.info?.qustnrPurps || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="안내내용" span={2}>
              {answerItem?.info?.qustnrWritngGuidanceCn && (
                <FroalaEditorView
                  model={answerItem?.info?.qustnrWritngGuidanceCn}
                />
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Row style={{ marginBottom: '1rem' }}>
        <Col sm={24} xs={24}>
          <Descriptions size="small" bordered column={4} layout="vertical">
            <Descriptions.Item label="성별">
              {answerItem?.respond?.sexdstnCode}
            </Descriptions.Item>
            <Descriptions.Item label="응답자명">
              {answerItem?.respond?.respondNm}
            </Descriptions.Item>
            <Descriptions.Item label="생년월일">
              {answerItem?.respond?.brthdy}
            </Descriptions.Item>
            <Descriptions.Item label="전화번호">
              <span>
                {answerItem?.respond?.areaNo === 'NULL'
                  ? '-'
                  : answerItem?.respond?.areaNo}
              </span>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
       */}
      <Row style={{ marginBottom: '1rem' }}>
        <Col sm={24} xs={24}>
          <Table
            columns={[
              {
                title: 'No',
                key: 'rnum',
                dataIndex: 'rnum',
                align: 'center',
              },
              {
                title: '유형',
                key: 'qestnTyCode',
                dataIndex: 'qestnTyCode',
                render: value => (value === 'O' ? '객관식' : '주관식'),
                align: 'center',
                width: 60,
              },
              {
                title: '문항',
                key: 'qestnCn',
                dataIndex: 'qestnCn',
                align: 'left',
              },
              {
                title: '답변',
                key: 'respondAnswerCn',
                dataIndex: 'respondAnswerCn',
                align: 'center',
                width: 80,
              },
              {
                title: '기타답변',
                dataIndex: 'etcAnswerCn',
                key: 'etcAnswerCn',
                align: 'center',
                width: 80,
              },
            ]}
            dataSource={answerItem?.answers}
            rowKey="qustnrRespondId"
            pagination={false}
            size="small"
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  .survey-answer {
    margin-bottom: 1.6rem;
    border: 4px solid var(--color-2abfb8-rgba-20);
    &__header {
      padding: 1rem 1.6rem;
      color: var(--color-primary);
      font-size: 1.6rem;
      background: var(--color-2abfb8-rgba-10);
      strong {
        flex: 1;
        font-weight: 500;
      }
    }
    &__detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.8rem;
      margin-top: 0.8rem;
      border-top: 1px dashed var(--color-2abfb8-rgba-20);
      > div {
        color: var(--color-black-rgba-85);
        font-size: 1.4rem;
        span {
          font-family: 'Roboto', sans-serif;
        }
      }
      > span {
        padding: 0.15rem 0.5rem;
        font-size: 1.3rem;
        color: var(--color-white);
        background: var(--color-primary);
      }
    }
    &__body {
      padding: 1.6rem;
      font-size: 1.4rem;
    }
    &__list {
      dt {
        color: var(--color-primary);
        font-size: 1.4rem;
      }
      dd {
        & + dt {
          padding-top: 1.6rem;
          margin-top: 1.6rem;
          border-top: 1px dashed var(--color-gray-d9d9d9);
          & + dd {
            max-height: 15rem;
            overflow-y: auto;
          }
        }
      }
    }
  }
  .survey-respondent {
    padding: 1rem 1.6rem;
    margin-bottom: 1.6rem;
    border: 1px solid;
    border-top-color: var(--color-gray-eaeaea);
    border-right-color: var(--color-gray-d9d9d9);
    border-bottom-color: var(--color-gray-d9d9d9);
    border-left-color: var(--color-gray-eaeaea);
    background: var(--color-gray-f7f7f7);
    em {
      display: inline-block;
      width: 7rem;
      color: var(--color-gray-737373);
      font-style: normal;
    }
  }
  .ant-table-container {
    border: 1px solid var(--color-gray-333);
    .ant-table-thead {
      .ant-table-cell {
        background: var(--color-gray-333);
        color: var(--color-white);
        // border: 1px solid #ccc;
      }
    }
    .row-disabled {
      background: #eee;
    }
  }
`;

export default SurveyRespondTable;

SurveyRespondTable.propTypes = {
  respondId: PropTypes.string,
};
SurveyRespondTable.defaultProps = {
  respondId: '',
};
