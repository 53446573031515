import React from 'react';
import { Layout, Button, Tooltip } from 'antd';
import styled from 'styled-components';

/**
 * Header
 * @returns
 */
function CmsHeaderView({ collapsed, signOut, menu }) {
  return (
    <StyledHeader className="site-layout__header" collapsed={collapsed}>
      <div className="c-header">
        <div className="c-header__title">
          <span>{(menu && menu.menuNm) || '사이트 관리'}</span>
        </div>
        <div className="c-header__box">
          <Tooltip placement="bottom" title="로그아웃">
            <Button
              type="link"
              className="btn-logout"
              onClick={() => {
                signOut({
                  redirectTo: '/login',
                });
              }}
            >
              <i className="ri-logout-box-r-line" />
              <span className="sr-only">로그아웃</span>
            </Button>
          </Tooltip>
        </div>
      </div>
    </StyledHeader>
  );
}

const { Header } = Layout;
const StyledHeader = styled(Header)`
  position: fixed;
  width: ${props =>
    props.collapsed ? 'calc(100% - 8rem)' : 'calc(100% - 25rem)'};
  height: 6.5rem;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  border-bottom: 1px solid var(--color-gray-c8c8c8);
  background: var(--color-white);
  z-index: 10;
  transition: all 0.3s;
  .c-header {
    display: flex;
    justify-content: space-between;
    &__title {
      padding-left: 1rem;
      span {
        position: relative;
        padding-left: 1.4rem;
        font-size: 2rem;
        font-weight: 500;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 6px;
          height: 16px;
          display: inline-block;
          border-radius: 5px;
          background: var(--color-primary);
        }
      }
    }
    &__box {
      display: flex;
      align-items: center;
    }
  }
  .btn-logout {
    width: 7rem;
    color: #4a4d51;
    font-size: 1.9rem;
  }
`;

export default CmsHeaderView;
