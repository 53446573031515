import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenues, menuSelector } from '../store/menu/reducer';

function useStoreMenu() {
  const dispatch = useDispatch();
  const { menues } = useSelector(menuSelector);

  useEffect(() => {
    if (menues.length < 1) {
      dispatch(fetchMenues());
    }
  }, [dispatch]);

  return { menues };
}

export default useStoreMenu;
