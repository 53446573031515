import {
  TeamOutlined,
  CodeOutlined,
  CommentOutlined,
  MessageOutlined,
  PartitionOutlined,
  WechatOutlined,
  PlusOutlined,
  MenuOutlined,
  SnippetsOutlined,
  ContactsOutlined,
  ContainerOutlined,
  FormOutlined,
  ReadOutlined,
  FormatPainterOutlined,
  WindowsOutlined,
  QuestionOutlined,
} from '@ant-design/icons';

/**
 * MenuNo와 MenuIcon 맵핑
 * 임시 방편
 * @param {*} menuNo MenuNo
 * @returns MenuIcon Element
 */
export const MenuIcon = menuNo => {
  const icons = {
    1: PlusOutlined,
    31: PartitionOutlined,
    39: CodeOutlined,
    32: MenuOutlined,
    34: TeamOutlined,
    35: ReadOutlined,
    38: CommentOutlined,
    40: MessageOutlined,
    41: WechatOutlined,
    43: SnippetsOutlined,
    46: FormOutlined,
    54: ContactsOutlined,
    55: ContainerOutlined,
    56: FormatPainterOutlined,
    77: QuestionOutlined,
    9110001: WindowsOutlined,
  };

  return icons[menuNo] ? icons[menuNo] : icons[1];
};
