import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Button, Card } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SearchTable = ({
  title,
  loading,
  searchKey,
  rowKey,
  columns,
  dataSource,
  rowSelection,
}) => {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const handleFilter = () => {
    const filteredData = dataSource.filter(d =>
      d[searchKey].includes(searchValue),
    );
    setData(filteredData);
  };
  useEffect(() => {
    setData(dataSource);
    setSearchValue('');
  }, [dataSource]);

  return (
    <Container>
      <Card title={title} className="question-search">
        <Row align="middle" className="question-search__inner">
          <Col flex="1" className="question-search__total">
            <span>
              총 <strong>{dataSource ? dataSource.length : 0}</strong>건
            </span>
          </Col>
          <Col flex="30rem" className="question-search__search">
            <Input
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onPressEnter={handleFilter}
            />
            <Button type="primary" onClick={handleFilter}>
              검색
            </Button>
          </Col>
        </Row>
        <Table
          size="small"
          // bordered
          columns={columns}
          dataSource={data}
          rowKey={rowKey}
          pagination={{
            pageSize: 10,
            total: data.length,
          }}
          rowSelection={rowSelection}
          loading={loading}
        />
      </Card>
    </Container>
  );
};

const Container = styled.div`
  .question-search {
    height: 450px;
    &__inner {
      padding: 1rem 1.6rem;
      border-bottom: 1px solid var(--color-gray-eaeaea);
      background: var(--color-gray-f7f7f7);
    }
    &__total {
      span {
        strong {
          font-weight: 500;
        }
      }
    }
    &__search {
      display: flex;
      input {
        flex: 1;
        margin-right: 5px;
      }
    }
  }
  .ant-card-head {
    padding: 0.5rem 1.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    .ant-card-head-title {
      padding: 5px 0;
    }
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-table-wrapper {
    height: 348px;
    padding: 1.6rem;
    overflow-y: auto;
    .ant-table-container {
      border: 1px solid var(--color-gray-333);
      .ant-table-thead {
        .ant-table-cell {
          background: var(--color-gray-333);
          color: var(--color-white);
        }
      }
      .row-disabled {
        background: #eee;
      }
    }
  }
`;

export default SearchTable;

SearchTable.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  searchKey: PropTypes.string,
  rowKey: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  dataSource: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  rowSelection: PropTypes.shape({
    type: PropTypes.string,
    selectedRowKeys: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
    onChange: PropTypes.func,
  }),
};

SearchTable.defaultProps = {
  title: '',
  loading: false,
  searchKey: '',
  rowKey: '',
  columns: [],
  dataSource: [],
  rowSelection: {
    type: 'checkbox',
    selectedRowKeys: [],
    onChange: () => {},
  },
};
