import React, { useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';

const sleep = ms => new Promise(r => setTimeout(r, ms));

function StyledEditor({
  value,
  onChange,
  visible,
  removeButtons = [],
  newConfig = {},
  config = {},
}) {
  const editorRef = useRef(null);

  const handleChange = data => {
    onChange && onChange(data);
  };

  // Editor initialization
  useEffect(() => {
    /* eslint no-promise-executor-return: "off" */
    async function syncEditor(_ref, _visible) {
      await sleep(500);
    }
    syncEditor(editorRef, visible);
  }, [editorRef.current, visible]);

  return (
    <JoditEditor
      ref={editorRef}
      value={value || ''}
      config={{
        ...config,
        toolbarSticky: false,
        placeholder: 'Start typings...',
        tabIndex: -1,
        enter: 'P',
        i18n: 'ko',
        height: 300,
        width: 'auto', // autosize
        removeButtons,
        // toolbarButtonSize: 'small',
        uploader: {
          insertImageAsBase64URI: true,
          // url: `${process.env.REACT_APP_API_URI}/api/file/insertFiles`,
          // insertImageAsBase64URI: false,
          // imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
          // filesVariableName: () => 'files',
          // isSuccess: e => !!e.result,
          // process: resp => ({
          //   fileNm: resp.result.fileNms[0],
          //   groupId: resp.result.groupId,
          // }),
          // defaultHandlerSuccess(result) {
          //   if (!result.groupId || !result.fileNm) return;
          //   // eslint-disable-next-line react/no-this-in-sfc
          //   const joditSelection = this.jodit
          //     ? // eslint-disable-next-line react/no-this-in-sfc
          //       this.jodit.selection
          //     : // eslint-disable-next-line react/no-this-in-sfc
          //       this.selection;
          //   const image = joditSelection.j.createInside.element('img');
          //   image.setAttribute(
          //     'src',
          //     `${process.env.REACT_APP_STORAGE_URI}/${result.groupId}/${result.fileNm}`,
          //   );
          //   // eslint-disable-next-line react/no-this-in-sfc
          //   joditSelection.j.s.insertImage(image, null, 300);
          // },
        },
        ...newConfig,
      }}
      onBlur={data => {
        handleChange(data);
      }} // preferred to use only this option to update the content for performance reasons
      // onChange={data => {
      //   handleChange(data);
      // }}
    />
  );
}

export default StyledEditor;
