import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

/**
 * Footer
 * @returns
 */
function CmsFooterView() {
  return <StyledFooter>주식회사 바능 ©2022 Created by 개발팀</StyledFooter>;
}

const { Footer } = Layout;
const StyledFooter = styled(Footer)`
  text-align: center;
`;

export default CmsFooterView;
