import React, { useRef, useState, useEffect } from 'react';
import DataGrid from 'react-data-grid';
import Froala from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { Button, Upload, Form } from 'antd';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '../../../hooks/useQuery';

ChartJS.register(ArcElement, Tooltip, Legend);

function Sample() {
  const ref = useRef({ editor: null });
  const [form] = Form.useForm();
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const [editor, setEditor] = useState(undefined);
  const [model, setModel] = useState('');

  /**
   * API - 파일Upload
   * @TODO - 업로드 API 수정필요
   */
  const saveFilesMutation = useMutation(
    // '/api/admin/file/insertFiles',
    '/api/file/insertFiles',
    {},
    true,
  );

  const handleModelChange = model => {
    setModel(model);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onFinish = async values => {
    const { upload } = values;

    let res;
    try {
      res = await saveFilesMutation.mutateAsync(
        upload.map(file => file.originFileObj),
      );
    } catch (error) {
      console.error('saveFiles', error);
    }
  };

  const saveFiles = async files => {
    let res;
    try {
      const uploadFiles = files.map(file => {
        return file;
      });

      res = await saveFilesMutation.mutateAsync(uploadFiles);
    } catch (error) {
      console.error('saveFiles', error);
    }
    return res;
  };

  const normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const customUploadAdapter = loader => {
    // (2)
    return {
      upload() {
        return new Promise((resolve, reject) => {
          // const data = new FormData();
          // loader.file.then(file => {
          // });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    // (3)
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return customUploadAdapter(loader);
    };
  }

  // Editor initialization
  useEffect(() => {
    if (ref.current?.editor?.data?._init) {
      ref.current.editor.data._init = null;
      setEditor(ref.current.editor);
      if (editor) {
        setIsFroalaInitialized(true);
      }
    }
  }, [ref.current]);

  // Do after initialization
  useEffect(() => {
    if (isFroalaInitialized) {
      editor.html.set(model);
    }
  }, [isFroalaInitialized]);

  return (
    <div>
      <div>
        <h1>[antd]</h1>
        <div>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="upload"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload name="logoList" multiple listType="picture">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <Button type="primary" onClick={handleSubmit}>
              저장
            </Button>
          </Form>
        </div>
        <h1>[그리드]</h1>
        <div>
          <DataGrid columns={columns} rows={rows} />
        </div>
        <h1>[에디터]</h1>
        <div>
          <Froala
            ref={ref}
            model={model}
            onModelChange={handleModelChange}
            tag="textarea"
            config={{
              attribution: false,
              placeholder: 'Start typing...',
              toolbarButtons: {
                moreText: {
                  buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    'fontFamily',
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'inlineClass',
                    'inlineStyle',
                    'clearFormatting',
                  ],
                },
                moreParagraph: {
                  buttons: [
                    'alignLeft',
                    'alignCenter',
                    'formatOLSimple',
                    'alignRight',
                    'alignJustify',
                    'formatOL',
                    'formatUL',
                    'paragraphFormat',
                    'paragraphStyle',
                    'lineHeight',
                    'outdent',
                    'indent',
                    'quote',
                  ],
                },
                moreRich: {
                  buttons: [
                    'insertLink',
                    'insertImage',
                    'insertVideo',
                    'insertTable',
                    'emoticons',
                    'fontAwesome',
                    'specialCharacters',
                    'embedly',
                    'insertFile',
                    'insertHR',
                  ],
                },
                moreMisc: {
                  buttons: [
                    'undo',
                    'redo',
                    'fullscreen',
                    'print',
                    'getPDF',
                    'spellChecker',
                    'selectAll',
                    'html',
                    'help',
                  ],
                  align: 'right',
                  buttonsVisible: 2,
                },
              },
              pluginsEnabled: [
                'table',
                'spell',
                'quote',
                'save',
                'quickInsert',
                'paragraphFormat',
                'paragraphStyle',
                'help',
                'draggable',
                'align',
                'link',
                'lists',
                'file',
                'image',
                'emoticons',
                'url',
                'video',
                'embedly',
                'colors',
                'entities',
                'inlineClass',
                'inlineStyle',
                // 'codeBeautif '
                // 'spellChecker',
                'imageTUI',
              ],
            }}
          />
        </div>
        <h1>[Froala Editor Viewer]</h1>
        <div>
          <FroalaEditorView model={model} />
        </div>
        <h1>[react-chart2]</h1>
        <div>
          <Pie data={data} />
        </div>
      </div>
    </div>
  );
}

const columns = [
  { key: 'id', name: 'ID' },
  { key: 'title', name: 'Title' },
];

const rows = [
  { id: 0, title: 'Example' },
  { id: 1, title: 'Demo' },
];

const data = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export default Sample;
