import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';

import CmsLayoutView from './CmsLayoutView';
import useStoreSite from '../../../hooks/useStoreSite';
import useStoreMenu from '../../../hooks/useStoreMenu';
import { selectKeep, setSiteInfo } from '../../../store/keep/reducer';
import { setSiteId } from '../../../store/site/reducer';

function CmsLayout({ children }) {
  const dispatch = useDispatch();
  const { menuInfo, siteInfo } = useSelector(selectKeep);

  const [siteId] = useState(process.env.REACT_APP_DEFAULT_SITE_ID);

  const { sites = [] } = useStoreSite();
  const { menues } = useStoreMenu();
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const getNavigation = () => {
    return (
      menuInfo && (
        <Breadcrumb className="c-bredcrumb">
          <Breadcrumb.Item>
            <i className="ri-home-4-line" />
          </Breadcrumb.Item>
          {menuInfo?.p && (
            <Breadcrumb.Item>
              {menuInfo?.p?.menuNm ?? '사이트 관리'}
            </Breadcrumb.Item>
          )}
          {menuInfo?.m ? (
            <Breadcrumb.Item>
              {menuInfo?.m?.menuNm ?? '사이트 관리'}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>사이트 관리</Breadcrumb.Item>
          )}
        </Breadcrumb>
      )
    );
  };

  const handleDispatchSiteId = siteId => {
    dispatch(
      setSiteInfo({
        siteId,
      }),
    );
    dispatch(setSiteId(siteId));
  };

  const props = {
    children,
    menues,
    siteId,
    sites,
    menuInfo,
    siteInfo,
    collapsed,
    navigation: getNavigation(),
    onCollapse: handleCollapse,
    onSiteId: handleDispatchSiteId,
  };
  return <CmsLayoutView {...props} />;
}

export default CmsLayout;
