import React, { useCallback, useEffect } from 'react';

import Provider from '../providers';
import useStorageEvent from '../hooks/useStorageEvent';

import {
  REQUESTING_SHARED_CREDENTIALS,
  CREDENTIALS_SHARING,
  CREDENTIALS_FLUSH,
  ACCESS_TOKEN,
  session,
  local,
  STORAGE,
} from '../config/contants';

export const withInitializeProvider = WrapperdComponent => {
  const Component = props => {
    // 이벤트 핸들러 처리
    const handler = useCallback(event => {
      const credentials = session.getItem(ACCESS_TOKEN);
      // 로그인 Credentials 정보를 쉐어 해달라는 트리거 호출
      if (event.key === REQUESTING_SHARED_CREDENTIALS && credentials) {
        session.setItem('target_url', window.location.pathname);
        local.setItem(CREDENTIALS_SHARING, credentials);
        local.removeItem(CREDENTIALS_SHARING);
      }

      // 로그인 Credentials 정보를 쉐어하고 나서 쉐어 했다는 이벤트 트리거 호출
      if (event.key === CREDENTIALS_SHARING && !credentials) {
        session.setItem(ACCESS_TOKEN, event.newValue);
        window.location.href = session.getItem('target_url');
      }

      // Credentials 정보 삭제 후 이벤트 트리거 호출
      if (event.key === CREDENTIALS_FLUSH && credentials) {
        session.removeItem(ACCESS_TOKEN);
        window.location.href = '/login';
      }
    }, []);

    // storage 이벤트 바인딩
    useStorageEvent(STORAGE, handler);

    // 페이지 최초 호출 시 Credentials 공유 요청 처리
    useEffect(() => {
      local.setItem(REQUESTING_SHARED_CREDENTIALS, Date.now().toString());
      local.removeItem(REQUESTING_SHARED_CREDENTIALS);
    }, []);

    return (
      <Provider>
        <WrapperdComponent {...props} />
      </Provider>
    );
  };

  return Component;
};
