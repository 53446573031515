import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

import {
  decode,
  postAccessToken,
  postLogout,
} from '../config/utils/token-resolver';
import { authActions } from '../store/auth/reducer';
import { perge as commonPerge } from '../store/common/reducer';
import { perge as keepPerge } from '../store/keep/reducer';
import { perge as menuPerge } from '../store/menu/reducer';
import { perge as sitePerge } from '../store/site/reducer';
import {
  CREDENTIALS_FLUSH,
  ACCESS_TOKEN,
  session,
  local,
} from '../config/contants';

const useSession = () => {
  const dispatch = useDispatch();
  const { userinfo, accessToken, refreshToken } = useSelector(
    state => state.auth,
  );
  const navigate = useNavigate();

  const handleSignIn = async (
    loginParams,
    callbackOptions = {
      redirectTo: '/',
    },
  ) => {
    const { redirectTo } = callbackOptions;
    const data = await postAccessToken(loginParams);

    if (!data?.message?.accessToken) {
      Modal.error({
        title: '로그인 실패',
        content: '아이디와 비밀번호가 일치하지 않습니다.',
      });

      throw new Error('SignIn Failed');
    }

    dispatch(
      authActions.setToken({
        accessToken: data?.message?.accessToken ?? '',
        userinfo: decode(data?.message?.accessToken ?? ''),
      }),
    );

    const query = new URL(redirectTo ?? '/', process.env.REACT_APP_API_URI);
    navigate({
      pathname: query.pathname,
      search: query.search,
    });
  };

  const handleSignOut = async (
    callbackOptions = {
      redirectTo: '/login',
    },
  ) => {
    const { redirectTo } = callbackOptions;

    const data = await postLogout();

    dispatch(
      authActions.setToken({
        accessToken: '',
        userinfo: '',
      }),
    );

    session.removeItem(ACCESS_TOKEN);
    local.setItem(CREDENTIALS_FLUSH, Date.now().toString());
    local.removeItem(CREDENTIALS_FLUSH);

    const query = new URL(
      redirectTo ?? '/login',
      process.env.REACT_APP_API_URI,
    );

    dispatch(commonPerge());
    dispatch(keepPerge());
    dispatch(menuPerge());
    dispatch(sitePerge());

    navigate({
      pathname: query.pathname,
      search: query.search,
    });
  };

  return {
    signIn: handleSignIn,
    signOut: handleSignOut,
    userinfo,
    accessToken,
    refreshToken,
    isAuthenticated: userinfo,
  };
};

export default useSession;
