import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Form, message, Modal } from 'antd';
import moment from 'moment';
import { v4 } from 'uuid';

import ContentsView from './ContentsView';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import useStoreSite from '../../../hooks/useStoreSite';
import { GRID_LINK_CELL_STYLE } from '../../../config/contants';

// const sleep = ms => new Promise(r => setTimeout(r, ms));
/**
 * 컨텐츠 관리
 * @returns
 */
function Contents() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // const storeSiteId = useSelector(state => state.site.siteId, shallowEqual);
  const { siteId: storeSiteId } = useStoreSite();
  const SITE_ID = !storeSiteId
    ? process.env.REACT_APP_DEFAULT_SITE_ID
    : storeSiteId;

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
    siteId: SITE_ID,
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: '',
      headerName: '',
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'rnum',
      headerName: 'No',
      width: 60,
    },
    {
      field: 'cntntsNm',
      headerName: '컨텐츠명',
      cellStyle: { ...GRID_LINK_CELL_STYLE },
      width: 200,
    },
    { field: 'jsFileNm', headerName: 'js 파일', width: 150 },
    { field: 'cssFileNm', headerName: 'css 파일', width: 150 },
    { field: 'thumbnailNm', headerName: 'thumbnail', width: 150 },
    {
      field: 'useAt',
      headerName: '사용여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.useAt === 'Y' ? '사용' : '미사용';
      },
    },
    { field: 'frstRegisterId', headerName: '등록자', width: 100 },
    {
      field: 'frstRegistPnttm',
      headerName: '등록일',
      width: 120,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
  ]);

  const [visible, setVisible] = useState(false);

  // selectedRows
  const [selectedRows, setSelectedRows] = useState([]);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);
  const [cssFiles, setCssFiles] = useState([]);
  const [jsFiles, setJsFiles] = useState([]);
  const [imgFiles, setImgFiles] = useState([]);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchCondition: 'CNTNTS_NM',
      searchKeyword: '',
    },
    items: [
      {
        type: 'select',
        name: 'searchCondition',
        placeholder: '선택하세요.',
        codes: [
          { value: 'CNTNTS_NM', text: '컨텐츠명' },
          { value: 'FRST_REGISTER_ID', text: '등록자 ID' },
        ],
      },
      {
        name: 'searchKeyword',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/contents/selectContentsList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * SITE_ID(리덕스 Store)의 정보가 변경 될 시
   * 목록 리로드
   */
  useEffect(() => {
    if (!!SITE_ID && SITE_ID.length > 0) {
      setSearch(() => {
        return { ...initialSearchValues, siteId: SITE_ID };
      });
    }
  }, [SITE_ID]);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/contents/selectContents',
    { cntntsId: searchParams.get('cntntsId'), siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('cntntsId'),
    },
  );

  /**
   * API - 정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation('/api/admin/contents/insertContents');

  /**
   * API - 정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation('/api/admin/contents/deleteContents');

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation('/api/admin/contents/updateContents');

  /**
   * API - 파일Upload
   * @TODO - 업로드 API 수정필요
   */
  const saveFilesMutation = useMutation(
    // '/api/admin/file/insertFiles',
    '/api/file/insertFiles',
    {},
    true,
  );

  /**
   * File Upload
   * @param {*} fileObj
   * @returns
   */
  const saveFiles = async files => {
    let res;
    try {
      const uploadFiles = files.map(file => {
        // return { file: file.originFileObj };
        return file;
      });

      res = await saveFilesMutation.mutateAsync(uploadFiles);
    } catch (error) {
      console.error('saveFiles', error);
    }
    return res;
  };

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {
    console.log('🚀 ~ handleRowClick ~ params', params);
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    // console.log('🚀 handleCellClick ~ params', params);
    const { data, colDef } = params;
    if (colDef?.field === 'cntntsNm' && data?.cntntsId) {
      // go Navigate
      navigate({
        pathname: '/cms/contents',
        search: `?${createSearchParams({
          cntntsId: data.cntntsId,
        })}`,
      });

      // refetchDetail
      if (searchParams.get('cntntsId')) refetchDetail();

      // Open Drawer
      // if (!visible) handleDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDraw = bool => {
    setVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/contents');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Row Selection Checked
   * @param {*} bool
   */
  const handleSelectionChanged = ({ api }) => {
    const _selectedRows = api.getSelectedRows();
    // console.log('🚀 ~ handleSelectionChanged ~ selectedRows', selectedRows);
    // Set SelectedRows
    setSelectedRows(_selectedRows);
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = async values => {
    console.log('🚀 ~ handleFinish ~ values', values);
    // console.log(
    //   '🚀 ~handleFinish ~ atchFile[0]',
    //   typeof values.atchFile[0].originFileObj,
    //   values.atchFile[0].originFileObj instanceof File,
    // );

    let cssFileId = cssFiles.length > 0 ? cssFiles[0].uid : '';
    let jsFileId = jsFiles.length > 0 ? jsFiles[0].uid : '';
    let thumbnail = imgFiles.length > 0 ? imgFiles[0].uid : '';

    if (jsFiles && jsFiles.length > 0) {
      if (jsFileId.startsWith('rc-upload')) {
        if (jsFiles.length > 1) {
          message.error('js파일은 1개만 등록 가능 합니다.');
          return;
        }
        const response = await saveFiles(jsFiles);
        if (response.result) {
          const { fileIds } = response.result;
          [jsFileId] = fileIds;
        }
      } else {
        jsFileId = jsFiles[0].fileId;
      }
    } else {
      [jsFileId] = '';
      delete values.jsFile;
    }

    if (cssFiles && cssFiles.length > 0) {
      if (cssFileId.startsWith('rc-upload')) {
        if (cssFiles.length > 1) {
          message.error('css파일은 1개만 등록 가능 합니다.');
          return;
        }

        const response = await saveFiles(cssFiles);

        if (response.result) {
          const { fileIds } = response.result;
          [cssFileId] = fileIds;
        }
      } else {
        cssFileId = cssFiles[0].fileId;
      }
    } else {
      [cssFileId] = '';
      delete values.cssFile;
    }

    if (imgFiles && imgFiles.length > 0) {
      if (thumbnail.startsWith('rc-upload')) {
        if (imgFiles.length > 1) {
          message.error('image파일은 1개만 등록 가능 합니다.');
          return;
        }
        const response = await saveFiles(imgFiles);
        const { fileIds } = response.result;
        [thumbnail] = fileIds;
      } else {
        thumbnail = imgFiles[0].fileId;
      }
    } else {
      [thumbnail] = '';
      delete values.imgFile;
    }

    const dataValues = {
      ...values,
      useAt: values.useAt ? 'Y' : 'N',
      cssFileId,
      jsFileId,
      thumbnail,
      // content,
    };

    /* eslint no-unreachable: "off" */
    // return;

    if (isEdit) {
      const sendValues = {
        ...dataValues,
        siteId: SITE_ID,
        lastUpdusrId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
        siteId: SITE_ID,
        frstRegisterId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          // console.log('🚀 ~ addMutation ~ onSuccess: ~ res', res);
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * 정보삭제 처리
   */
  const handleDelete = () => {
    if (selectedRows && Array.isArray(selectedRows)) {
      if (selectedRows.length < 1) {
        Modal.warning({
          content: '삭제할 대상을 선택해주세요.',
        });
        return;
      }
      const cntntsIds = selectedRows.map(item => {
        return {
          cntntsId: item.cntntsId,
        };
      });
      const sendDataValues = {
        removeList: cntntsIds,
      };

      // API Call - 정보 삭제하기
      deleteMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '삭제 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // deselect
          if (gridApi) gridApi.deselectAll();
        },
      });
    }
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      setCssFiles([]);
      setJsFiles([]);
      setImgFiles([]);
      // setContent();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  const handleCssFiles = values => {
    setCssFiles(values);
  };
  const handleJsFiles = values => {
    setJsFiles(values);
  };
  const handleImgFiles = values => {
    setImgFiles(values);
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    if (detail?.result) {
      const formData = {
        ...detail.result,
        useAt: detail.result.useAt === 'Y',
      };
      // Set form Fileds value
      form.setFieldsValue({
        ...formData,
      });

      // Open Drawer
      if (!visible) handleDraw(true);
      // 상세정보 세팅
      setDetailData({ ...formData });

      // 파일
      const {
        cssFileNm,
        cssFileId,
        jsFileId,
        jsFileNm,
        thumbnail,
        thumbnailNm,
        content,
      } = detail.result;
      let csss = [];
      let jsss = [];
      let thumss = [];
      // 파일
      if (cssFileId) {
        csss = [{ name: cssFileNm, fileId: cssFileId, uid: v4() }];
      }
      if (jsFileId) {
        jsss = [{ name: jsFileNm, fileId: jsFileId, uid: v4() }];
      }
      if (thumbnail) {
        thumss = [{ name: thumbnailNm, fileId: thumbnail, uid: v4() }];
      }
      setCssFiles(csss);
      setJsFiles(jsss);
      setImgFiles(thumss);
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [detail, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.resultList,
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    visible,
    // detail,
    selectedRows,
    isEdit,
    searchOption,
    cssFiles,
    jsFiles,
    imgFiles,
    onCssFiles: handleCssFiles,
    onJsFiles: handleJsFiles,
    onImgFiles: handleImgFiles,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onDraw: handleDraw,
    onGridReady: handleGridOption,
    onSelectionChanged: handleSelectionChanged,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
  };

  return <ContentsView {...props} />;
}

export default Contents;
