import axios from 'axios';

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

client.defaults.withCredentials = true;

/**
 * react-query 사용 시 기본으로 호출되는 api
 * @param {*} uri
 * @param {*} data
 * @returns
 */
export const defaultQuery = async (uri, data, options = {}) => {
  return await client.post(process.env.REACT_APP_API_URI + uri, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  });
};

/**
 * 파일 등의 전송을 위한 폼 데이터 전송
 * @param {*} _file
 * @returns
 */
export const fileUpload = async (uri, _file, params) => {
  const { REACT_APP_UPLOAD_URI } = process.env;

  // if (!(_file instanceof File)) {
  //   throw new Error("Invalid 'file'");
  // }

  if (!REACT_APP_UPLOAD_URI) {
    throw new Error("not defind 'REACT_APP_UPLOAD_URI'");
  }

  const formData = new FormData();

  Array.from(_file).map(e =>
    formData.append('files', e.originFileObj ? e.originFileObj : e),
  );

  if (params) {
    Object.entries(params).map(([k, v]) => formData.append(k, v));
  }

  const { data } = await client.post(
    `${REACT_APP_UPLOAD_URI}${uri}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return data;
};
