import { produce } from 'immer';
import { createSlice } from '@reduxjs/toolkit';

/** 초기 값 */
const initialState = {
  isLoading: false,
};

/** 슬라이스 인스턴스 */
const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsLoading: (state, action) =>
      produce(state, draft => {
        draft.isLoading = action.payload === true;
      }),
    perge: (state, action) =>
      produce(state, draft => {
        draft.isLoading = false;
      }),
  },
});

/** actions */
export const { setIsLoading, perge } = slice.actions;

export const selectCommon = state => state.common;
/** reducer */
export default slice.reducer;
