import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import RequireAuth from './components/atoms/RequireAuth';
import Login from './components/pages/Login';
import Dashboard from './components/pages/Dashboard';
import CmsLayout from './components/templates/CmsLayout';
import CmsDefaultLayout from './components/templates/CmsDefaultLayout';
import CmsLoginLayout from './components/templates/CmsLoginLayout';
import routes from './config/router/route';
import createTokenInterceptor from './config/utils/createTokenInterceptor';
import { client } from './config/utils/network';
import { withInitializeProvider } from './hoc/withInitializeProvider';

function App() {
  // axios interceptor 처리
  createTokenInterceptor(client);

  return (
    <div className="App">
      <Routes>
        {routes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <RequireAuth>
                <CmsLayout>
                  <route.element />
                </CmsLayout>
              </RequireAuth>
            }
          >
            {route?.children?.map(subroute => (
              <Route
                key={subroute.path}
                path={subroute.path}
                element={<subroute.element />}
              />
            ))}
          </Route>
        ))}

        <Route
          path="/login"
          element={
            <CmsLoginLayout>
              <Login />
            </CmsLoginLayout>
          }
        />

        <Route
          path="/cms/index"
          element={
            <CmsDefaultLayout>
              <Dashboard />
            </CmsDefaultLayout>
          }
        />
        <Route path="*" element={<Navigate to="/cms/site" />} />
      </Routes>
    </div>
  );
}

export default withInitializeProvider(App);
