import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Button,
  Form,
  Input,
  Switch,
  Popconfirm,
  Space,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import { DeleteOutlined } from '@ant-design/icons';

import JoditEditor from '../../molecules/JoditEditor';
import SearchBar from '../../templates/SearchBar';
import StyledDrawer from '../../atoms/StyledDrawer';

function InqueryView({
  form,
  list,
  paging,
  visible,
  selectedRows,
  isEdit,
  columnDefs,
  gridOptions,
  searchOption,
  onPaging,
  onCellClick,
  onGridReady,
  onDraw,
  onSelectionChanged,
  onFinish,
  onReset,
  onSearch,
  onConfirm,
  onDelete,
  onDeleteSingle,
  onMoveList,
  onGridSizeChanged,
}) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={18}>
              {/* SearchBar */}
              <SearchBar options={searchOption} onSearch={onSearch} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Space>
                <Popconfirm
                  title="삭제 처리 하시겠습니까?"
                  onConfirm={() => {
                    onDelete(false);
                  }}
                  onCancel={() => {}}
                  okText="삭제"
                  cancelText="취소"
                  disabled={selectedRows.length < 1}
                >
                  <Button
                    type="danger"
                    htmlType="button"
                    disabled={selectedRows.length < 1}
                    icon={<DeleteOutlined />}
                  >
                    삭제 처리
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title="확인 처리 하시겠습니까?"
                  onConfirm={() => {
                    onConfirm();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                  disabled={selectedRows.length < 1}
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    disabled={selectedRows.length < 1}
                    icon={<DeleteOutlined />}
                  >
                    관리자 확인
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ag-theme-alpine" style={{ height: 470 }}>
            <AgGridReact
              rowData={list}
              columnDefs={columnDefs}
              {...gridOptions}
              onGridReady={onGridReady}
              onCellClicked={onCellClick}
              onSelectionChanged={onSelectionChanged}
              onGridSizeChanged={onGridSizeChanged}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
          <Pagination
            pageSize={paging?.pageSize ?? 10}
            current={paging?.currentPageNo ?? 1}
            total={paging?.totalRecordCount ?? 0}
            onChange={onPaging}
          />
        </Col>
      </Row>
      <StyledDrawer
        title="문의사항 상세보기"
        placement="right"
        width="40vw"
        open={visible}
        onClose={() => {
          onDraw(false);
          onMoveList();
        }}
        footer={[
          <Row align="middle" justify="space-between">
            <Col>
              <Space>
                <Popconfirm
                  key="btn2"
                  title="삭제 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    onDeleteSingle();
                  }}
                  onCancel={() => {}}
                  okText="삭제"
                  cancelText="취소"
                >
                  <Button type="danger" htmlType="button">
                    삭제
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
            <Col>
              <Space>
                <Popconfirm
                  key="btn1"
                  title="저장 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    form.submit();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                >
                  <Button type="primary" htmlType="button">
                    저장
                  </Button>
                </Popconfirm>
                <Button
                  key="btn3"
                  onClick={() => {
                    onDraw(false);
                    onMoveList();
                  }}
                >
                  닫기
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFieldsChange={changedFields => {
          //   console.log('🚀 ~ changedFields', changedFields);
          // }}
          // onValuesChange={changedValues => {
          //   console.log('🚀 ~ changedValues', changedValues);
          // }}
        >
          {/* Hidden Value */}
          <Form.Item name="cttSeq" noStyle>
            <Input hidden />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                label="회사명"
                name="company"
                disabled
                tooltip="회사 정보"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label="이메일"
                name="email"
                disabled
                tooltip="이메일 정보"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="전화번호"
                name="phoneNumber"
                disabled
                tooltip="전화번호 정보"
              >
                <Input disabled />
              </Form.Item>
            </Col> */}
            <Col span={24}>
              <Form.Item
                label="문의 내용"
                name="words"
                rules={[
                  { required: true, message: '문의 내용을 입력해주세요.' },
                ]}
                tooltip="문의 내용을 입력해주세요."
              >
                <JoditEditor
                  visible={visible}
                  maxHeight={200}
                  removeButtons={['image']}
                  config={{
                    toolbar: false,
                    readonly: true,
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="관리자 확인 여부"
                name="confirmAt"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDrawer>
    </div>
  );
}

InqueryView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  visible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onConfirm: PropTypes.func,
  onDelete: PropTypes.func,
  onDeleteSingle: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
};

InqueryView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  visible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  onPaging: () => {},
  onGridReady: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onConfirm: () => {},
  onDelete: () => {},
  onDeleteSingle: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
};

export default InqueryView;
