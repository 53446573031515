import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Row,
  Col,
  Descriptions,
  Space,
  Modal,
  Popconfirm,
  message,
} from 'antd';
import {
  SaveOutlined,
  ReloadOutlined,
  RightOutlined,
  LeftOutlined,
  DownSquareOutlined,
  UpSquareOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

import { useMutation, useQuery } from '../../../hooks/useQuery';
import SearchTable from '../SearchTable';

// 상태
const statusMap = {
  W: '대기',
  P: '진행',
  E: '종료',
};

/**
 * Arrow Buttons
 * @param {*} props
 * @returns
 */
const ArrowIcons = props => {
  const { onAdd, onDelete, selectedBRowKeys, selectedARowKeys } = props;
  return (
    <>
      <Space
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Button
          icon={<RightOutlined />}
          onClick={onAdd}
          disabled={selectedBRowKeys.length < 1}
        />
        <Button
          icon={<LeftOutlined />}
          onClick={onDelete}
          disabled={selectedARowKeys.length < 1}
        />
      </Space>
      {/* <Col sm={2} xs={0} style={{ textAlign: 'center', margin: 'auto' }}>
        <div style={{ marginBottom: '1rem' }}>
          <Button
            icon={<RightOutlined />}
            onClick={onAdd}
            disabled={selectedBRowKeys.length < 1}
          />
        </div>
        <div>
          <Button
            icon={<LeftOutlined />}
            onClick={onDelete}
            disabled={selectedARowKeys.length < 1}
          />
        </div>
      </Col>
      <Col sm={0} xs={24} style={{ textAlign: 'center', margin: 'auto' }}>
        <Button
          icon={<DownSquareOutlined />}
          onClick={onAdd}
          disabled={selectedBRowKeys.length < 1}
        />
        <Button
          icon={<UpSquareOutlined />}
          onClick={onDelete}
          disabled={selectedARowKeys.length < 1}
        />
      </Col>
      */}
    </>
  );
};

ArrowIcons.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  selectedBRowKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  selectedARowKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};
ArrowIcons.defaultProps = {
  onAdd: () => {},
  onDelete: () => {},
  selectedBRowKeys: [],
  selectedARowKeys: [],
};

/**
 * 설문지관리 > 문항 선택
 * @param {*} param0
 * @returns
 */
function SurveyItemChoiceTable({ choiceId, onClose }) {
  const [isChanged, setIsChanged] = useState(false);
  const [survey, setSurvey] = useState({
    qustnrSj: '',
    progrsSttus: '',
    qustnrBgnde: '',
    qustnrEndde: '',
    qustnrPurps: '',
    qustnrWritngGuidanceCn: '',
  });
  const [beforeRows, setBeforeRows] = useState([]);
  const [afterRows, setAfterRows] = useState([]);
  const [selectedBRowKeys, setSelectedBRowKeys] = useState([]);
  const [selectedARowKeys, setSelectedARowKeys] = useState([]);

  const beforeColumns = [
    {
      title: '유형',
      key: 'qestnTyCode',
      dataIndex: 'qestnTyCode',
      render: value => (value === 'O' ? '객관식' : '주관식'),
      align: 'center',
    },
    {
      title: '문항',
      dataIndex: 'qestnCn',
      key: 'qestnCn',
      align: 'left',
    },
  ];

  const afterColumns = [
    {
      title: '유형',
      key: 'qestnTyCode',
      dataIndex: 'qestnTyCode',
      render: value => (value === 'O' ? '객관식' : '주관식'),
      align: 'center',
      width: '60px',
    },
    {
      title: '문항',
      dataIndex: 'qestnCn',
      key: 'qestnCn',
      align: 'left',
    },
    {
      title: '순서',
      key: 'order',
      align: 'left',
      width: '60px',
      render: (_value, _record, index) => (
        <Space size={5}>
          <UpSquareOutlined
            className="fs-18 mr-5"
            onClick={() => handleChangeOrder(index, 'up')}
          />

          <DownSquareOutlined
            className="fs-18"
            onClick={() => handleChangeOrder(index, 'down')}
          />
        </Space>
      ),
    },
  ];

  /**
   * API - 문항상세정보
   */
  const { data, isLoading, dataUpdatedAt, isFetching, refetch } = useQuery(
    '/api/admin/question/info/selectItem',
    { qestnrId: choiceId },
    { cacheTime: 0, enabled: !!choiceId },
  );

  /**
   * API - 문항저장
   */
  const saveMutation = useMutation('/api/admin/question/info/saveItem');

  const handleChangeOrder = (index, type) => {
    if (index === 0 && type === 'up') return;
    if (index === afterRows.length - 1 && type === 'down') return;

    const newAfterRows = [...afterRows];
    if (type === 'up') {
      const temp = newAfterRows[index - 1];
      newAfterRows[index - 1] = newAfterRows[index];
      newAfterRows[index] = temp;
    } else {
      const temp = newAfterRows[index + 1];
      newAfterRows[index + 1] = newAfterRows[index];
      newAfterRows[index] = temp;
    }
    setAfterRows(newAfterRows);
  };

  const handleAddItem = () => {
    if (selectedBRowKeys.length === 0) return;
    const selectedRows = beforeRows.filter(row =>
      selectedBRowKeys.includes(row.qustnrItmId),
    );
    const notSelectedRows = beforeRows.filter(
      row => !selectedBRowKeys.includes(row.qustnrItmId),
    );
    setBeforeRows([...notSelectedRows]);
    setAfterRows([...afterRows, ...selectedRows]);
    setSelectedBRowKeys([]);
    setSelectedARowKeys([]);
    setIsChanged(true);
  };

  const handleDeleteItem = () => {
    if (selectedARowKeys.length === 0) return;
    const selectedRows = afterRows.filter(row =>
      selectedARowKeys.includes(row.qustnrItmId),
    );
    const notSelectedRows = afterRows.filter(
      row => !selectedARowKeys.includes(row.qustnrItmId),
    );
    setBeforeRows([...beforeRows, ...selectedRows]);
    setAfterRows([...notSelectedRows]);
    setSelectedBRowKeys([]);
    setSelectedARowKeys([]);
    setIsChanged(true);
  };

  const handleSave = () => {
    const sendValues = {
      itemList: afterRows.map(row => row.qustnrItmId) || [],
      qestnrId: choiceId,
    };
    saveMutation.mutate(sendValues, {
      onSuccess: res => {
        if (res?.status === 200) {
          Modal.success({
            content: '저장 하였습니다.',
            onOk: () => {
              // init();
            },
          });
        } else {
          const { status, statusText } = res;
          message.error(`[${status} - ${statusText}]`);
        }
      },
      onError: async error => {
        console.error('🚀 ~ saveMutation : error2', JSON.stringify(error));
        // message.error(error.message);
      },
      onSettled: async () => {
        console.log('🚀 ~ saveMutation onSettled!!!');
        init();
      },
    });
  };

  const handleRefresh = () => {
    init();
  };

  const init = () => {
    refetch(); // refetch
    setIsChanged(false);
    setSelectedBRowKeys([]);
    setSelectedARowKeys([]);
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    if (data) {
      setBeforeRows(data.item);
      setAfterRows(data.selected);
      setSurvey(data.info);
    }
  }, [data, dataUpdatedAt]);

  return (
    <>
      <StyledSurvey className="survey-answer">
        <div className="survey-answer__header">
          <strong>{survey.qustnrSj}</strong>
          <div className="survey-answer__detail">
            <div>
              <span>
                {survey.qustnrBgnde &&
                  moment(survey.qustnrBgnde).format('YYYY-MM-DD')}
              </span>
              <span>~</span>
              <span>
                {survey.qustnrBgnde &&
                  moment(survey.qustnrEndde).format('YYYY-MM-DD')}
              </span>
            </div>
            <span>{statusMap[survey.progrsSttus]}</span>
          </div>
        </div>
        <div className="survey-answer__body">
          <ul className="survey-answer__list">
            <li>
              <dl>
                <dt>목적</dt>
                <dd> {survey.qustnrPurps}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>안내내용</dt>
                <dd>
                  {survey.qustnrWritngGuidanceCn && (
                    <FroalaEditorView model={survey.qustnrWritngGuidanceCn} />
                  )}
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </StyledSurvey>
      {/* <Row style={{ paddingBottom: '0.5rem' }}>
        <Col span={24}>
          <Descriptions size="small" bordered column={2}>
            <Descriptions.Item label="제목">
              {survey.qustnrSj}
            </Descriptions.Item>
            <Descriptions.Item label="상태">
              {statusMap[survey.progrsSttus]}
            </Descriptions.Item>
            <Descriptions.Item label="기간">
              <span>
                {survey.qustnrBgnde &&
                  moment(survey.qustnrBgnde).format('YYYY-MM-DD')}
              </span>
              <span>~</span>
              <span>
                {survey.qustnrBgnde &&
                  moment(survey.qustnrEndde).format('YYYY-MM-DD')}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="목적">
              {survey.qustnrPurps}
            </Descriptions.Item>
            <Descriptions.Item label="안내내용">
              {survey.qustnrWritngGuidanceCn && (
                <FroalaEditorView model={survey.qustnrWritngGuidanceCn} />
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row> */}
      <Row align="middle">
        <Col flex="47%">
          <SearchTable
            title="문항목록"
            searchKey="qestnCn"
            rowKey="qustnrItmId"
            columns={beforeColumns}
            dataSource={beforeRows}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedBRowKeys,
              onChange: setSelectedBRowKeys,
            }}
            loading={isLoading || isFetching}
          />
        </Col>
        <Col flex="6%">
          <ArrowIcons
            onAdd={handleAddItem}
            onDelete={handleDeleteItem}
            selectedBRowKeys={selectedBRowKeys}
            selectedARowKeys={selectedARowKeys}
          />
        </Col>
        <Col flex="47%">
          <SearchTable
            title="선택한 문항"
            searchKey="qestnCn"
            rowKey="qustnrItmId"
            columns={afterColumns}
            dataSource={afterRows}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedARowKeys,
              onChange: setSelectedARowKeys,
            }}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row>

      {/* <Row>
        <Col sm={11} xs={24}>
          <SearchTable
            title="문항목록"
            searchKey="qestnCn"
            rowKey="qustnrItmId"
            columns={beforeColumns}
            dataSource={beforeRows}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedBRowKeys,
              onChange: setSelectedBRowKeys,
            }}
            loading={isLoading || isFetching}
          />
        </Col>
        <ArrowIcons
          onAdd={handleAddItem}
          onDelete={handleDeleteItem}
          selectedBRowKeys={selectedBRowKeys}
          selectedARowKeys={selectedARowKeys}
        />
        <Col sm={11} xs={24}>
          <SearchTable
            title="선택한 문항"
            searchKey="qestnCn"
            rowKey="qustnrItmId"
            columns={afterColumns}
            dataSource={afterRows}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedARowKeys,
              onChange: setSelectedARowKeys,
            }}
            loading={isLoading || isFetching}
          />
        </Col>
      </Row> */}
      <Row style={{ marginTop: '2.4rem' }}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Space>
            <Button
              type="primary"
              onClick={handleRefresh}
              icon={<ReloadOutlined />}
              ghost
            >
              초기화
            </Button>
            <Popconfirm
              title="저장하시겠습니까?"
              onConfirm={handleSave}
              onCancel={event => event.stopPropagation()}
              okText="저장"
              cancelText="취소"
            >
              <Button
                type="primary"
                icon={<SaveOutlined />}
                disabled={!isChanged}
              >
                저장
              </Button>
            </Popconfirm>
            <Button onClick={onClose}>돌아가기</Button>
          </Space>
        </Col>
      </Row>
    </>
  );
}

const StyledSurvey = styled.div`
  display: flex;
  height: 190px;
  margin-bottom: 1.6rem;
  border: 1px solid var(--color-2abfb8-rgba-40);
  .survey-answer {
    &__header {
      display: flex;
      flex-direction: column;
      flex: 0 0 30%;
      padding: 1.6rem;
      color: var(--color-primary);
      font-size: 1.6rem;
      background: var(--color-2abfb8-rgba-10);
      strong {
        flex: 1;
        font-weight: 500;
      }
    }
    &__detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.8rem;
      margin-top: 0.8rem;
      border-top: 1px dashed var(--color-2abfb8-rgba-20);
      > div {
        color: var(--color-black-rgba-85);
        font-size: 1.4rem;
        span {
          font-family: 'Roboto', sans-serif;
        }
      }
      > span {
        padding: 0.15rem 0.5rem;
        font-size: 1.3rem;
        color: var(--color-white);
        background: var(--color-primary);
      }
    }
    &__body {
      flex: 1;
      padding: 1.6rem;
      font-size: 1.4rem;
    }
    &__list {
      li {
        dl {
          display: flex;
          dt {
            flex: 0 1 100px;
            color: var(--color-primary);
            font-size: 1.4rem;
          }
          dd {
            flex: 1;
          }
        }
        & + li {
          padding-top: 1.6rem;
          margin-top: 1.6rem;
          border-top: 1px dashed var(--color-gray-d9d9d9);
          dd {
            max-height: 100px;
            overflow-y: auto;
          }
        }
      }
    }
  }
`;

export default SurveyItemChoiceTable;

SurveyItemChoiceTable.propTypes = {
  choiceId: PropTypes.string,
  onClose: PropTypes.func,
};

SurveyItemChoiceTable.defaultProps = {
  choiceId: '',
  onClose: () => {},
};
