import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';

import './Loading.scss';

function LoadingView({ visible, onCancel }) {
  return (
    <Modal
      visible={visible}
      footer={false}
      header={false}
      maskStyle={{ backgroundColor: 'hsl(0 0% 100% / 50%' }}
      onCancel={onCancel}
    >
      <Spin size="large" />
    </Modal>
  );
}

export default LoadingView;

LoadingView.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
