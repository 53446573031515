import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function CmsLoginLayoutView({ children }) {
  return <CmsLoginLayout className="login-layout">{children}</CmsLoginLayout>;
}

const CmsLoginLayout = styled(Layout)`
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default CmsLoginLayoutView;

CmsLoginLayoutView.propTypes = {
  children: PropTypes.element.isRequired,
};
