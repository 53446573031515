import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Drawer,
  Button,
  Form,
  Input,
  Switch,
  Popconfirm,
  Space,
  Select,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';
import {
  DeleteOutlined,
  // PlusOutlined,
  // PlusSquareOutlined,
  // PlusCircleOutlined,
} from '@ant-design/icons';

import SearchBar from '../../templates/SearchBar';
import SurveyItemTable from '../../molecules/SurveyItemTable';
import StyledDrawer from '../../atoms/StyledDrawer';

const { Option } = Select;

function SurveyItemView({
  form,
  list,
  paging,
  visible,
  selectedRows,
  isEdit,
  columnDefs,
  gridOptions,
  searchOption,
  itemTableRef,
  choices,
  onPaging,
  onRowClick,
  onCellClick,
  onGridReady,
  onDraw,
  onSelectionChanged,
  onFinish,
  onReset,
  onSearch,
  onDelete,
  onMoveList,
  onGridSizeChanged,
}) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={18}>
              {/* SearchBar */}
              <SearchBar options={searchOption} onSearch={onSearch} />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <Space>
                <Button
                  type="primary"
                  // icon={<PlusCircleOutlined />}
                  onClick={() => {
                    onReset();
                    onDraw(true);
                  }}
                  ghost
                >
                  등록
                </Button>
                <Popconfirm
                  title="미사용 처리하시겠습니까?"
                  onConfirm={() => {
                    onDelete();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                  disabled={selectedRows.length < 1}
                >
                  <Button
                    type="danger"
                    htmlType="button"
                    disabled={selectedRows.length < 1}
                    icon={<DeleteOutlined />}
                  >
                    미사용
                  </Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ag-theme-alpine" style={{ height: 470 }}>
            <AgGridReact
              rowData={list}
              columnDefs={columnDefs}
              {...gridOptions}
              onGridReady={onGridReady}
              onRowClicked={onRowClick}
              onCellClicked={onCellClick}
              onSelectionChanged={onSelectionChanged}
              onGridSizeChanged={onGridSizeChanged}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
          <Pagination
            pageSize={paging?.pageSize ?? 10}
            current={paging?.currentPageNo ?? 1}
            total={paging?.totalRecordCount ?? 0}
            onChange={onPaging}
          />
        </Col>
      </Row>
      <StyledDrawer
        title={`설문문항 ${isEdit ? '수정' : '등록'}`}
        placement="right"
        width="40vw"
        open={visible}
        onClose={() => {
          onDraw(false);
          onMoveList();
        }}
        footer={[
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                key="btn2"
                disabled={isEdit}
                htmlType="button"
                onClick={() => {
                  onReset(isEdit);
                }}
              >
                초기화
              </Button>
            </Col>
            <Col>
              <Space>
                <Popconfirm
                  key="btn1"
                  title="저장 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    form.submit();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    // onClick={() => form.submit()}
                  >
                    저장
                  </Button>
                </Popconfirm>
                <Button
                  key="btn3"
                  onClick={() => {
                    onDraw(false);
                    onMoveList();
                  }}
                >
                  닫기
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          // onFieldsChange={changedFields => {
          //   console.log('🚀 ~ changedFields', changedFields);
          // }}
          // onValuesChange={changedValues => {
          //   console.log('🚀 ~ changedValues', changedValues);
          // }}
        >
          {/* Hidden Value */}
          <Form.Item name="qustnrItmId" noStyle>
            <Input hidden />
          </Form.Item>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="유형"
                name="qestnTyCode"
                rules={[{ required: true, message: '유형을 입력해주세요.' }]}
                tooltip="유형을 입력해주세요."
              >
                <Select>
                  <Option value="O">객관식</Option>
                  <Option value="S">주관식</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="문항"
                name="qestnCn"
                rules={[{ required: true, message: '문항을 입력해주세요.' }]}
                tooltip="문항을 입력해주세요."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.qestnTyCode !== curValues.qestnTyCode
                }
                noStyle
              >
                {({ getFieldValue }) => {
                  const qestnTyCode = getFieldValue('qestnTyCode');
                  if (qestnTyCode === 'S') return null;
                  return (
                    <Form.Item
                      name="mxmmChoiseCo"
                      label="항목선택갯수"
                      initialValue="1"
                    >
                      <Select style={{ width: '100px' }}>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3">3</Option>
                        <Option value="4">4</Option>
                        <Option value="5">5</Option>
                        <Option value="6">6</Option>
                        <Option value="7">7</Option>
                        <Option value="8">8</Option>
                        <Option value="9">9</Option>
                        <Option value="10">10</Option>
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="사용여부" name="useAt" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.qestnTyCode !== curValues.qestnTyCode ||
                  prevValues.mxmmChoiseCo !== curValues.mxmmChoiseCo
                }
                noStyle
              >
                {({ getFieldValue }) => {
                  const qestnTyCode = getFieldValue('qestnTyCode');
                  const mxmmChoiseCo = getFieldValue('mxmmChoiseCo');
                  if (qestnTyCode === 'S') return null;
                  return (
                    <SurveyItemTable
                      ref={itemTableRef}
                      choices={choices}
                      maxItemLength={+mxmmChoiseCo}
                    />
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDrawer>
    </div>
  );
}

SurveyItemView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  visible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  itemTableRef: PropTypes.oneOfType([
    PropTypes.any,
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
};

SurveyItemView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  visible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  itemTableRef: null,
  choices: [],
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
};

export default SurveyItemView;
