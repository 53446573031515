import Menu from '../../components/pages/Menu';
import CodeBase from '../../components/pages/CodeBase';
import CodeCategory from '../../components/pages/CodeCategory';
import CodeDetail from '../../components/pages/CodeDetail';
import Sample from '../../components/pages/Sample';
import Site from '../../components/pages/Site';
import Auth from '../../components/pages/Auth';
import Board from '../../components/pages/Board';
import List from '../../components/pages/Board/List';
import Faq from '../../components/pages/Faq';
import Qna from '../../components/pages/Qna';
import QnaAnswer from '../../components/pages/QnaAnswer';
import Stplat from '../../components/pages/Stplat';
import Privacy from '../../components/pages/Privacy';
import Copyright from '../../components/pages/Copyright';
import Survey from '../../components/pages/Survey';
import SurveyItem from '../../components/pages/SurveyItem';
import SurveyAnswer from '../../components/pages/SurveyAnswer';
import Community from '../../components/pages/Community';
import Contents from '../../components/pages/Contents';
import BannerGroup from '../../components/pages/BannerGroup';
import Banner from '../../components/pages/Banner';
import Inquery from '../../components/pages/Inquery';
import Popup from '../../components/pages/Popup';

const Routes = [
  {
    element: Menu,
    path: '/cms/menu',
  },
  {
    element: Sample,
    path: '/cms/sample',
  },
  // 코드분류 관리
  {
    element: CodeCategory,
    path: '/cms/codeCategory',
  },
  // // 공통코드
  {
    element: CodeBase,
    path: '/cms/code',
  },
  // 상세코드 관리
  {
    element: CodeDetail,
    path: '/cms/codeDetail',
  },
  // 사이트 관리
  {
    element: Site,
    path: '/cms/site',
  },
  // 권한 관리
  {
    element: Auth,
    path: '/cms/auth',
  },
  // 게시판 마스터 관리
  {
    element: Board,
    path: '/cms/board',
  },
  // 게시글 관리
  {
    element: List,
    path: '/cms/board/list',
  },
  // FAQ 관리
  {
    element: Faq,
    path: '/cms/faq',
  },
  // QnA 관리 > 질문관리
  {
    element: Qna,
    path: '/cms/qna',
  },
  // QnA 관리 > 답변관리
  {
    element: QnaAnswer,
    path: '/cms/qnaAnswer',
  },
  // 약관관리
  {
    element: Stplat,
    path: '/cms/stplat',
  },
  // 개인정보보호정책관리
  {
    element: Privacy,
    path: '/cms/privacy',
  },
  // 저작권보호정책관리
  {
    element: Copyright,
    path: '/cms/copyright',
  },
  // 설문관리 > 설문지관리
  {
    element: Survey,
    path: '/cms/survey',
  },
  // 설문관리 > 설문문항관리
  {
    element: SurveyItem,
    path: '/cms/surveyItem',
  },
  // 설문관리 > 설문응답관리
  {
    element: SurveyAnswer,
    path: '/cms/surveyAnswer',
  },
  // 커뮤니티 관리
  {
    element: Community,
    path: '/cms/community',
  },
  // 컨텐츠 관리
  {
    element: Contents,
    path: '/cms/contents',
  },
  // 배너관리 > 배너그룹관리
  {
    element: BannerGroup,
    path: '/cms/bannerGroup',
  },
  // 배너관리 > 상세배너관리
  {
    element: Banner,
    path: '/cms/banner',
  },
  {
    element: Inquery,
    path: '/cms/inquery',
  },
  {
    element: Popup,
    path: '/cms/popup',
  },
];

export default Routes;
