import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Collapse, Table, Input, Switch, message, Space, Button } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  DownSquareOutlined,
  UpSquareOutlined,
} from '@ant-design/icons';
import { v4 } from 'uuid';
import styled from 'styled-components';

const Container = styled.div`
  .ant-table-container {
    border: 1px solid var(--color-gray-333);

    .ant-table-thead {
      .ant-table-cell {
        background: var(--color-gray-333);
        color: var(--color-white);
      }
    }
    .row-disabled {
      background: #eee;
    }
  }
`;

const { Panel } = Collapse;

const Icons = ({ onAdd, onDelete }) => {
  return (
    <Space>
      <PlusOutlined
        className="pl-10"
        onClick={event => {
          event.stopPropagation();
          onAdd();
        }}
      />
      <MinusOutlined
        className="pl-10"
        onClick={event => {
          event.stopPropagation();
          onDelete();
        }}
      />
    </Space>
  );
};

Icons.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
};

Icons.defaultProps = {
  onAdd: () => {},
  onDelete: () => {},
};

/**
 * SurveyItemTable
 * @param {*} props
 * @param {*} ref
 * @returns
 */
const SurveyItemTable = ({ choices, maxItemLength }, ref) => {
  const [items, setItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const buttonRef = useRef(null);

  useEffect(() => {
    setItems(choices || []);
    setSelectedRowKeys([]);
  }, [choices]);

  const handleChangeItem = (index, data) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], ...data };
    if (newItems[index].status !== 'C') newItems[index].status = 'U';
    setItems(newItems);
  };

  const handleAddItem = () => {
    if (items.length === maxItemLength) {
      message.error(`항목선택갯수는 최대 ${maxItemLength}개 입니다.`);
      return;
    }
    const newItem = {
      qustnrIemId: v4(),
      iemCn: '',
      etcAnswerAt: 'N',
      status: 'C',
    };
    setItems([...items, newItem]);
  };

  const handleDeleteItem = () => {
    const newItems = items.filter(
      item => !selectedRowKeys.includes(item.qustnrIemId),
    );
    setItems(newItems);
    setSelectedRowKeys([]);
  };

  const handleChangeOrder = (index, type) => {
    if (index === 0 && type === 'up') return;
    if (index === items.length - 1 && type === 'down') return;

    const newItems = [...items];
    if (type === 'up') {
      const temp = newItems[index - 1];
      newItems[index - 1] = newItems[index];
      newItems[index] = temp;
    } else {
      const temp = newItems[index + 1];
      newItems[index + 1] = newItems[index];
      newItems[index] = temp;
    }
    // 정렬순번 적용
    const cvtItems = newItems.map((item, index) => ({
      ...item,
      iemSn: index,
      status: 'U',
    }));
    setItems(cvtItems);
  };

  const getData = () => {
    const finalItems = [...items];
    choices.forEach(choice => {
      const index = finalItems.findIndex(
        item => item.qustnrIemId === choice.qustnrIemId,
      );
      if (index < 0) finalItems.push({ ...choice, status: 'D' });
    });
    const groupData = finalItems.reduce(
      (result, item) => {
        result.total += 1;
        const newItem = { ...item };
        if (!newItem.iemCn) result.emptyItem = true;
        const { status } = newItem;
        if (status === 'C') {
          delete newItem.qustnrIemId;
        }
        delete newItem.status;
        if (!status) return result;
        return {
          ...result,
          [status]: result[status].concat([newItem]),
        };
      },
      {
        C: [],
        U: [],
        D: [],
        total: 0,
        emptyItem: false,
      },
    );
    return groupData;
  };

  useImperativeHandle(ref, () => ({
    getData,
  }));

  return (
    <Container ref={buttonRef}>
      <Collapse defaultActiveKey={['1']} expandIconPosition="right">
        <Panel
          header="항목추가"
          key="1"
          extra={<Icons onAdd={handleAddItem} onDelete={handleDeleteItem} />}
        >
          <Table
            size="small"
            columns={[
              {
                title: '항목',
                key: 'iemCn',
                dataIndex: 'iemCn',
                align: 'center',
                render: (value, _record, index) => (
                  <Input
                    value={value}
                    onChange={e =>
                      handleChangeItem(index, { iemCn: e.target.value })
                    }
                  />
                ),
              },
              {
                title: '기타답변여부',
                dataIndex: 'etcAnswerAt',
                key: 'etcAnswerAt',
                align: 'center',
                render: (value, _record, index) => (
                  <Switch
                    checked={value === 'Y'}
                    onChange={checked =>
                      handleChangeItem(index, {
                        etcAnswerAt: checked ? 'Y' : 'N',
                      })
                    }
                  />
                ),
              },
              {
                title: '순서',
                key: 'order',
                align: 'center',
                width: '100px',
                render: (_value, _record, index) => (
                  <Space>
                    <Button
                      size="small"
                      icon={<UpSquareOutlined />}
                      onClick={() => handleChangeOrder(index, 'up')}
                      disabled={index === 0}
                    />
                    <Button
                      size="small"
                      icon={<DownSquareOutlined />}
                      onClick={() => handleChangeOrder(index, 'down')}
                      disabled={index === items.length - 1}
                    />
                  </Space>
                ),
              },
            ]}
            dataSource={items}
            rowKey="qustnrIemId"
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys,
              onChange: setSelectedRowKeys,
            }}
            pagination={false}
          />
        </Panel>
      </Collapse>
      {/* <div>
        <pre>items:{JSON.stringify(items, null, 2)}</pre>
      </div> */}
    </Container>
  );
};

export default forwardRef(SurveyItemTable);

SurveyItemTable.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  maxItemLength: PropTypes.number,
};

SurveyItemTable.defaultProps = {
  choices: [],
  maxItemLength: 1,
};
