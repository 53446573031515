import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Form, message, Modal } from 'antd';
import moment from 'moment';

import { GRID_LINK_CELL_STYLE } from '../../../config/contants';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import { getFileData } from '../../../config/utils/util';
import useStoreSite from '../../../hooks/useStoreSite';

import PopupView from './PopupView';

// const sleep = ms => new Promise(r => setTimeout(r, ms));
/**
 * 팝업관리 > 팝업상세관리
 * @returns
 */
function Popup() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // const storeSiteId = useSelector(state => state.site.siteId, shallowEqual);
  const { siteId: storeSiteId } = useStoreSite();
  const SITE_ID = !storeSiteId
    ? process.env.REACT_APP_DEFAULT_SITE_ID
    : storeSiteId;

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
    siteId: SITE_ID,
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: '',
      headerName: '',
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'rnum',
      headerName: 'No',
      width: 60,
    },
    {
      field: 'popupTitleNm',
      headerName: '팝업명',
      cellStyle: { ...GRID_LINK_CELL_STYLE },
      width: 150,
    },
    {
      headerName: '기간',
      width: 180,
      cellRenderer: params => {
        const { data } = params;
        return `${moment(data.ntceBgnde).format('YYYY-MM-DD')} ~ ${moment(
          data.ntcntceEnddeeBgnde,
        ).format('YYYY-MM-DD')}`;
      },
    },
    {
      field: 'stopvewSetupAt',
      headerName: '중지여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.stopvewSetupAt === 'Y' ? '사용' : '미사용';
      },
    },
    {
      field: 'ntceAt',
      headerName: '게시여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.ntceAt === 'Y' ? '사용' : '미사용';
      },
    },
    {
      field: 'useAt',
      headerName: '사용여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.useAt === 'Y' ? '사용' : '미사용';
      },
    },
    { field: 'frstRegisterId', headerName: '등록자', width: 100 },
    {
      field: 'frstRegistPnttm',
      headerName: '등록일',
      width: 120,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
  ]);

  const [visible, setVisible] = useState(false);

  // selectedRows
  const [selectedRows, setSelectedRows] = useState([]);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);

  // 팝업ID
  const [popupId, setPopupId] = useState('');

  // 파일 첨부 flag
  const [attach, setAttach] = useState(false);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchKeyword: '',
    },
    items: [
      {
        name: 'searchKeyword',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/popup/selectPopupList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * SITE_ID(리덕스 Store)의 정보가 변경 될 시
   * 목록 리로드
   */
  useEffect(() => {
    if (!!SITE_ID && SITE_ID.length > 0) {
      setSearch(() => {
        return { ...initialSearchValues, siteId: SITE_ID };
      });
    }
  }, [SITE_ID]);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/popup/selectPopupListDetail',
    { popupId: searchParams.get('popupId'), siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('popupId'),
    },
  );

  /**
   * API - 정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation('/api/admin/popup/insertPopup');

  /**
   * API - 정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation('/api/admin/popup/deletePopup');

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation('/api/admin/popup/updatePopup');

  /**
   * API - 파일Upload
   * @TODO - 업로드 API 수정필요
   */
  const saveFilesMutation = useMutation(
    // '/api/admin/file/insertFiles',
    '/api/file/insertFiles',
    {},
    true,
  );

  /**
   * File Upload
   * @param {*} fileObj
   * @returns
   */
  const saveFiles = async files => {
    let res;
    try {
      /* const uploadFiles = files.map(file => {
        return { file: file.originFileObj };
      }); */
      const uploadFiles = files.map(file => {
        return file;
      });
      res = await saveFilesMutation.mutateAsync(uploadFiles);
    } catch (error) {
      console.error('saveFiles', error);
    }
    return res;
  };

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {};

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    const { data, colDef } = params;
    if (colDef?.field === 'popupTitleNm' && data?.popupId) {
      // go Navigate
      navigate({
        pathname: '/cms/popup',
        search: `?${createSearchParams({
          popupId: data.popupId,
        })}`,
      });

      // refetchDetail
      if (searchParams.get('popupId')) refetchDetail();

      // Open Drawer
      // if (!visible) handleDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDraw = bool => {
    setVisible(bool);
  };

  /**
   * 유형 변경에 따른 조건 설정
   * @param {*} bool
   */
  const handleChangeType = bool => {
    setAttach(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/popup');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Row Selection Checked
   * @param {*} bool
   */
  const handleSelectionChanged = ({ api }) => {
    const _selectedRows = api.getSelectedRows();
    setSelectedRows(_selectedRows);
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = async values => {
    const { atchFile, atchFileId } = values;
    let fileId = atchFile.length > 0 && atchFile[0].name;

    // File Upload
    if (atchFile && atchFile.length > 0) {
      // File Upload
      if (atchFile && Array.isArray(atchFile) && atchFile[0].originFileObj) {
        try {
          const res = await saveFiles(atchFile);
          fileId = res.result.fileIds && res.result.fileIds[0];
        } catch (err) {
          console.error('saveFiles err', err.message);
          fileId = '';
        }
      }
    } else {
      fileId = '';
    }

    const dataValues = {
      ...values,
      fileId: values.popupTy === 'U' ? fileId : '',
      ntceBgnde: moment(values.ntceBgnde).format('YYYY-MM-DD'),
      ntceEndde: moment(values.ntceEndde).format('YYYY-MM-DD'),
      stopvewSetupAt: values.stopvewSetupAt ? 'Y' : 'N',
      ntceAt: values.ntceAt ? 'Y' : 'N',
      useAt: values.useAt ? 'Y' : 'N',
      userId: 'admin', // 필수항목 @TODO - 세션적용확인
      siteId: SITE_ID,
    };

    /* eslint no-unreachable: "off" */
    // return;

    if (isEdit) {
      const sendValues = {
        ...dataValues,
        lastUpdusrId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
        frstRegisterId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * 정보삭제 처리
   */
  const handleDelete = () => {
    if (selectedRows && Array.isArray(selectedRows)) {
      if (selectedRows.length < 1) {
        Modal.warning({
          content: '삭제할 대상을 선택해주세요.',
        });
        return;
      }
      const popupIds = selectedRows.map(item => {
        return {
          popupId: item.popupId,
        };
      });
      const sendDataValues = {
        idList: popupIds,
        lastUpdusrId: 'admin',
      };

      // API Call - 정보 삭제하기
      deleteMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '삭제 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // deselect
          if (gridApi) gridApi.deselectAll();
        },
      });
    }
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    if (detail?.result) {
      const formData = {
        ...detail.result,
        atchFile: getFileData(detail.result.fileId),
        ntceBgnde: moment(detail.result.ntceBgnde),
        ntceEndde: moment(detail.result.ntceEndde),
        stopvewSetupAt: detail.result.stopvewSetupAt === 'Y',
        ntceAt: detail.result.ntceAt === 'Y',
        useAt: detail.result.useAt === 'Y',
      };

      if (detail.result.popupTy === 'U') setAttach(true);
      else setAttach(false);
      // Set form Fileds value
      form.setFieldsValue({
        ...formData,
      });

      // Open Drawer
      if (!visible) handleDraw(true);
      // 상세정보 세팅
      setDetailData({ ...formData });
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [detail, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.resultList,
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    visible,
    // detail,
    selectedRows,
    isEdit,
    searchOption,
    popupId,
    attach,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onChangeType: handleChangeType,
    onDraw: handleDraw,
    onGridReady: handleGridOption,
    onSelectionChanged: handleSelectionChanged,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
  };

  return <PopupView {...props} />;
}

export default Popup;
