import { enableES5 } from 'immer';
import PropTypes from 'prop-types';
import React from 'react';
import Loading from '../components/molecules/Loading';
import QueryClientProvider from './QueryClientProvider';

function Provider({ children }) {
  return (
    <QueryClientProvider>
      {children}
      <Loading />
    </QueryClientProvider>
  );
}

// # immer를 ie11에서 작동
enableES5();

export default Provider;

Provider.propTypes = {
  children: PropTypes.element.isRequired,
};
