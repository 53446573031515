import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Pagination,
  Button,
  Form,
  Input,
  Popconfirm,
  Space,
} from 'antd';
import { AgGridReact } from 'ag-grid-react';

import StyledDrawer from '../../../atoms/StyledDrawer';

function CommentListView({
  form,
  list,
  paging,
  detailVisible,
  isEdit,
  columnDefs,
  gridOptions,
  onPaging,
  onCellClick,
  onGridReady,
  onDetailDraw,
  onFinish,
  onDelete,
  onReset,
  onMoveList,
  onGridSizeChanged,
}) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  onReset(false);
                  onDetailDraw(true);
                }}
                ghost
              >
                등록
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="ag-theme-alpine" style={{ height: 470 }}>
            <AgGridReact
              rowData={list}
              columnDefs={columnDefs}
              {...gridOptions}
              onGridReady={onGridReady}
              onCellClicked={onCellClick}
              onGridSizeChanged={onGridSizeChanged}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 20, textAlign: 'center' }}>
          <Pagination
            pageSize={paging?.pageSize ?? 10}
            current={paging?.currentPageNo ?? 1}
            total={paging?.totalRecordCount ?? 0}
            onChange={onPaging}
          />
        </Col>
      </Row>
      <StyledDrawer
        title={`댓글 ${isEdit ? '수정' : '등록'}`}
        placement="right"
        width="36vw"
        onClose={() => {
          onDetailDraw(false);
          onMoveList();
        }}
        visible={detailVisible}
        destroyOnClose
        maskClosable={false}
        footer={[
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                key="btn2"
                disabled={isEdit}
                htmlType="button"
                onClick={() => {
                  onReset(isEdit);
                }}
              >
                초기화
              </Button>
            </Col>
            <Col>
              <Space>
                <Popconfirm
                  key="btn1"
                  title="저장 하시겠습니까?"
                  placement="topRight"
                  onConfirm={() => {
                    form.submit();
                  }}
                  onCancel={() => {}}
                  okText="저장"
                  cancelText="취소"
                >
                  <Button
                    type="primary"
                    htmlType="button"
                    // onClick={() => form.submit()}
                  >
                    저장
                  </Button>
                </Popconfirm>
                {isEdit && (
                  <Popconfirm
                    key="btn2"
                    title="삭제 하시겠습니까?"
                    placement="topRight"
                    onConfirm={() => {
                      onDelete();
                    }}
                    onCancel={() => {}}
                    okText="삭제"
                    cancelText="취소"
                  >
                    <Button type="primary" htmlType="button">
                      삭제
                    </Button>
                  </Popconfirm>
                )}
                <Button
                  key="btn3"
                  onClick={() => {
                    onDetailDraw(false);
                    onMoveList();
                  }}
                >
                  닫기
                </Button>
              </Space>
            </Col>
          </Row>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          {/* Hidden Value */}
          <Form.Item name="bbsId" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="nttId" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="commentNo" noStyle>
            <Input hidden />
          </Form.Item>

          <Row>
            <Col span={24}>
              <Form.Item
                label="댓글"
                name="commentCn"
                rules={[{ required: true, message: '댓글을 입력해주세요.' }]}
                tooltip="댓글을 입력해주세요."
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="작성자" name="wrterId">
                <Input disabled placeholder="admin" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="등록일" name="frstRegisterPnttm">
                <Input disabled placeholder="등록 시 자동으로 입력됩니다." />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </StyledDrawer>
    </div>
  );
}

CommentListView.propTypes = {
  form: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  ),
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  detailVisible: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.func,
      ]),
    ),
  ),
  gridOptions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.func,
    PropTypes.object,
  ]),
  selectedRows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  isEdit: PropTypes.bool,
  searchOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onPaging: PropTypes.func,
  onGridReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  onDetailDraw: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onFinish: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  onDelete: PropTypes.func,
  onMoveList: PropTypes.func,
  onGridSizeChanged: PropTypes.func,
};

CommentListView.defaultProps = {
  form: {},
  list: [],
  paging: {},
  detailVisible: false,
  columnDefs: [],
  gridOptions: {},
  selectedRows: [],
  isEdit: false,
  searchOption: {},
  onPaging: () => {},
  onGridReady: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
  onDetailDraw: () => {},
  onSelectionChanged: () => {},
  onFinish: () => {},
  onReset: () => {},
  onSearch: () => {},
  onDelete: () => {},
  onMoveList: () => {},
  onGridSizeChanged: () => {},
};

export default CommentListView;
