import { produce } from 'immer';
import { createSlice } from '@reduxjs/toolkit';

/** 초기 값 */
const initialState = {
  menuInfo: undefined,
  siteInfo: undefined,
};

/** 슬라이스 인스턴스 */
const slice = createSlice({
  name: 'keep',
  initialState,
  reducers: {
    setMenuInfo: (state, action) =>
      produce(state, draft => {
        draft.menuInfo = action.payload;
      }),
    setSiteInfo: (state, action) =>
      produce(state, draft => {
        draft.siteInfo = action.payload;
      }),
    perge: (state, action) =>
      produce(state, draft => {
        draft.menuInfo = undefined;
        draft.siteInfo = undefined;
      }),
  },
});

/** actions */
export const { setMenuInfo, setSiteInfo, perge } = slice.actions;

export const selectKeep = state => state.keep;
/** reducer */
export default slice.reducer;
