import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Form, message, Modal, Button, Popconfirm } from 'antd';
import moment from 'moment';

import CommunityView from './CommunityView';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import useStoreSite from '../../../hooks/useStoreSite';
import { GRID_LINK_CELL_STYLE } from '../../../config/contants';

// const sleep = ms => new Promise(r => setTimeout(r, ms));

/**
 * 커뮤니티 관리
 * @returns
 */
function Community() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  /**
   * @todo-사이트ID 확인 필요
   * store state - siteId
   */
  // const storeSiteId = useSelector(state => state.site.siteId, shallowEqual);
  const { siteId: storeSiteId } = useStoreSite();
  const SITE_ID = !storeSiteId
    ? process.env.REACT_APP_DEFAULT_SITE_ID
    : storeSiteId;

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
    siteId: SITE_ID,
  };

  const ITEMS = {
    Y: {
      cName: '승인완료',
      cType: 'primary',
      name: '승인취소',
      type: 'danger',
      stat: 'N',
      title: '승인 취소 하시겠습니까?',
    },
    N: {
      cName: '승인취소',
      cType: 'danger',
      name: '승인완료',
      type: 'primary',
      stat: 'Y',
      title: '승인 하시겠습니까?',
    },
    C: { name: '신청취소' },
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: '',
      headerName: '',
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'rnum',
      headerName: 'No',
      width: 60,
    },
    {
      field: 'cmmntyNm',
      headerName: '커뮤니티명',
      cellStyle: { ...GRID_LINK_CELL_STYLE },
      width: 250,
    },
    { field: 'frstRegisterId', headerName: '등록자', width: 100 },
    {
      field: 'frstRegisterPnttm',
      headerName: '등록일',
      width: 120,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
    // {
    //   field: 'apprAt',
    //   headerName: '현재승인상태',
    //   width: 120,
    //   cellRenderer: ({ value }) => {
    //     let display = '승인취소';
    //     if (value === 'C') display = '신청취소';

    //     if (value === 'Y') display = '승인완료';

    //     return display;
    //   },
    // },
    {
      field: 'apprAt',
      headerName: '승인여부',
      align: 'center',
      alignItems: 'center',
      width: 120,
      cellRenderer: ({ value, data }) => {
        /* eslint react/no-unstable-nested-components: "off" */
        if (value === 'C') {
          return ITEMS[value].name;
        }
        return (
          <Popconfirm
            title={ITEMS[value].title}
            onConfirm={event => {
              event.stopPropagation();
              const sendValues = {
                cmmntyId: data.cmmntyId,
                apprAt: ITEMS[value].stat,
                message: ITEMS[value].name,
              };
              handleApproval(sendValues);
            }}
            onCancel={event => event.stopPropagation()}
            okText={ITEMS[value].name}
            okType={ITEMS[value].type}
            cancelText="취소"
          >
            <Button type={ITEMS[value].cType}>{ITEMS[value].cName}</Button>
          </Popconfirm>
        );
      },
    },
    {
      field: 'useAt',
      headerName: '사용여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.useAt === 'Y' ? '사용' : '미사용';
      },
    },
  ]);

  const [visible, setVisible] = useState(false);

  // selectedRows
  const [selectedRows, setSelectedRows] = useState([]);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchCnd: '0',
      searchWrd: '',
    },
    items: [
      {
        type: 'select',
        name: 'searchCnd',
        placeholder: '선택하세요.',
        codes: [{ value: '0', text: '커뮤니티명' }],
      },
      {
        name: 'searchWrd',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/community/selectCommuMasterList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * SITE_ID(리덕스 Store)의 정보가 변경 될 시
   * 목록 리로드
   */
  useEffect(() => {
    if (!!SITE_ID && SITE_ID.length > 0) {
      setSearch(() => {
        return { ...initialSearchValues, siteId: SITE_ID };
      });
    }
  }, [SITE_ID]);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/community/selectCommuMasterDetail',
    { cmmntyId: searchParams.get('cmmntyId'), siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('cmmntyId'),
    },
  );

  /**
   * API - 정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation('/api/admin/community/insertCommuMaster');

  /**
   * API - 정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation('/api/admin/community/deleteCommuMaster');

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation('/api/admin/community/updateCommuMaster');

  /**
   * API - 승인처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const approvalMutation = useMutation('/api/admin/community/apprCommuMaster');

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {
    console.log('🚀 ~ handleRowClick ~ params', params);
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    // console.log('🚀 handleCellClick ~ params', params);
    const { data, colDef } = params;
    if (colDef?.field === 'cmmntyNm' && data?.cmmntyId) {
      // go Navigate
      navigate({
        pathname: '/cms/community',
        search: `?${createSearchParams({
          cmmntyId: data.cmmntyId,
        })}`,
      });

      // refetchDetail
      if (searchParams.get('cmmntyId')) refetchDetail();

      // Open Drawer
      // if (!visible) handleDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDraw = bool => {
    setVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/community');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Row Selection Checked
   * @param {*} bool
   */
  const handleSelectionChanged = ({ api }) => {
    const _selectedRows = api.getSelectedRows();
    // console.log('🚀 ~ handleSelectionChanged ~ selectedRows', selectedRows);
    // Set SelectedRows
    setSelectedRows(_selectedRows);
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = values => {
    const dataValues = {
      ...values,
      useAt: values.useAt ? 'Y' : 'N',
      siteId: SITE_ID,
    };

    //  return;
    if (isEdit) {
      const sendValues = {
        ...dataValues,
        lastUpdusrId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
        frstRegisterId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          // console.log('🚀 ~ addMutation ~ onSuccess: ~ res', res);
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * 정보삭제 처리
   */
  const handleDelete = () => {
    if (selectedRows && Array.isArray(selectedRows)) {
      if (selectedRows.length < 1) {
        Modal.warning({
          content: '삭제할 대상을 선택해주세요.',
        });
        return;
      }
      const cmmntyIds = selectedRows.map(item => {
        return {
          cmmntyId: item.cmmntyId,
        };
      });
      const sendDataValues = {
        communityList: cmmntyIds,
      };

      // API Call - 정보 삭제하기
      deleteMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '삭제 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // deselect
          if (gridApi) gridApi.deselectAll();
        },
      });
    }
  };

  /**
   * 승인처리
   * @param {*} values
   */
  const handleApproval = data => {
    const sendValues = {
      cmmntyId: data.cmmntyId,
      apprAt: data.apprAt,
    };
    // API Call - 승인처리
    approvalMutation.mutate(sendValues, {
      onSuccess: res => {
        if (res?.status === 200) {
          // Message
          Modal.success({
            content: `${data.message} 하였습니다.`,
            onOk: () => {
              // Refetch - siteList
              refetchList();
            },
          });
        } else {
          const { status, statusText } = res;
          message.error(`[${status} - ${statusText}]`);
        }
      },
    });
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    if (detail?.result) {
      const viewData = {
        ...detail.result,
        useAt: detail.result.useAt === 'Y',
      };

      // Set form Fileds value
      form.setFieldsValue({
        ...viewData,
      });

      // Open Drawer
      if (!visible) handleDraw(true);
      // 상세정보 세팅
      setDetailData({ ...viewData });
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [detail, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.resultList,
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    visible,
    // detail,
    selectedRows,
    isEdit,
    searchOption,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onDraw: handleDraw,
    onGridReady: handleGridOption,
    onSelectionChanged: handleSelectionChanged,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
  };

  return <CommunityView {...props} />;
}

export default Community;
