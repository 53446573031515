import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Form, message, Modal } from 'antd';
import moment from 'moment';

import QnaAnswerView from './QnaAnswerView';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import useStoreSite from '../../../hooks/useStoreSite';
import { GRID_LINK_CELL_STYLE } from '../../../config/contants';

// const sleep = ms => new Promise(r => setTimeout(r, ms));
/**
 * QnaAnswer > 답변 관리
 * @returns
 */
function QnaAnswer() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  /**
   * @todo-사이트ID 확인 필요
   * store state - siteId
   */
  // const storeSiteId = useSelector(state => state.site.siteId, shallowEqual);
  const { siteId: storeSiteId } = useStoreSite();
  const SITE_ID = !storeSiteId
    ? process.env.REACT_APP_DEFAULT_SITE_ID
    : storeSiteId;

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
    siteId: SITE_ID,
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [columnDefs] = useState([
    {
      field: 'rnum',
      headerName: 'No',
      width: 60,
    },
    {
      field: 'qestnSj',
      headerName: '질문 제목',
      cellStyle: { ...GRID_LINK_CELL_STYLE },
      width: 250,
    },
    { field: 'qnaProcessSttusCodeNm', headerName: '진행상태', width: 100 },
    { field: 'wrterNm', headerName: '등록자', width: 100 },
    {
      field: 'frstRegisterPnttm',
      headerName: '등록일',
      width: 120,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
  ]);

  const [visible, setVisible] = useState(false);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchCnd: '0',
      searchWrd: '',
    },
    items: [
      {
        type: 'select',
        name: 'searchCnd',
        placeholder: '선택하세요.',
        codes: [
          { value: '0', text: '등록자' },
          { value: '1', text: '진행상태' },
        ],
      },
      {
        name: 'searchWrd',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/qna/selectQnaAnswerList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * SITE_ID(리덕스 Store)의 정보가 변경 될 시
   * 목록 리로드
   */
  useEffect(() => {
    if (!!SITE_ID && SITE_ID.length > 0) {
      setSearch(() => {
        return { ...initialSearchValues, siteId: SITE_ID };
      });
    }
  }, [SITE_ID]);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/qna/selectQnaAnswerDetail',
    { qaId: searchParams.get('qaId'), siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('qaId'),
    },
  );

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation('/api/admin/qna/updateQnaAnswer');

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {
    console.log('🚀 ~ handleRowClick ~ params', params);
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    // console.log('🚀 handleCellClick ~ params', params);
    const { data, colDef } = params;
    if (colDef?.field === 'qestnSj' && data?.qaId) {
      // go Navigate
      navigate({
        pathname: '/cms/qnaAnswer',
        search: `?${createSearchParams({
          qaId: data.qaId,
        })}`,
      });

      // refetchDetail
      if (searchParams.get('qaId')) refetchDetail();

      // Open Drawer
      // if (!visible) handleDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDraw = bool => {
    setVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/qnaAnswer');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = values => {
    const dataValues = {
      ...values,
      siteId: SITE_ID,
      emailAnswerAt: values.emailAnswerAt ? 'Y' : 'N',
    };

    //  return;
    if (isEdit) {
      const sendValues = {
        ...dataValues,
        siteId: SITE_ID,
        lastUpdusrId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    console.log('🚀 ~ handleReset ~ _isEdit, detailData', _isEdit, detailData);
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    // console.log('🚀 ~ useEffect formData', formData);
    if (detail?.result) {
      const formData = {
        ...detail.result,
        emailAnswerAt: detail.result.emailAnswerAt === 'Y',
      };
      // Set form Fileds value
      form.setFieldsValue({
        ...formData,
      });

      // Open Drawer
      if (!visible) handleDraw(true);
      // 상세정보 세팅
      setDetailData({ ...formData });
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [detail, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.resultList,
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    visible,
    isEdit,
    searchOption,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onDraw: handleDraw,
    onGridReady: handleGridOption,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
  };

  return <QnaAnswerView {...props} />;
}

export default QnaAnswer;
