import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Form, message, Modal } from 'antd';

import SiteView from './SiteView';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import { GRID_LINK_CELL_STYLE } from '../../../config/contants';
import { fetchSites } from '../../../store/site/reducer';

// const sleep = ms => new Promise(r => setTimeout(r, ms));
/**
 * 사이트 관리
 * @returns
 */
function Site() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: '',
      headerName: '',
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'rnum',
      headerName: 'No',
      width: 80,
    },
    {
      field: 'siteNm',
      headerName: '사이트명',
      width: 150,
      cellStyle: { ...GRID_LINK_CELL_STYLE },
    },
    { field: 'domain', headerName: '도메인', width: 200 },
    { field: 'actvtyAtNm', headerName: '활성여부', width: 80 },
    { field: 'useAtNm', headerName: '사용여부', width: 80 },
    { field: 'frstRegisterId', headerName: '등록자', width: 120 },
    { field: 'frstRegisterPnttm', headerName: '등록일', width: 120 },
  ]);

  const [visible, setVisible] = useState(false);

  // selectedRows
  const [selectedRows, setSelectedRows] = useState([]);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchCondition: '0',
      searchKeyword: '',
    },
    items: [
      {
        type: 'select',
        name: 'searchCondition',
        placeholder: '선택하세요.',
        codes: [
          { value: '0', text: '사이트명' },
          { value: '1', text: '도메인' },
        ],
      },
      {
        name: 'searchKeyword',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  /**
   * API - 코드 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/site/selectSiteList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  // console.log('🚀 ~ file: Site.js ~ line 64 ~ Site ~ restQuery', restQuery);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/site/selectSiteDetail',
    { siteId: searchParams.get('siteId') },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('siteId'),
    },
  );
  // console.log('🚀 ~ Site ~ detail', detail);
  // console.log('🚀 ~ Site ~ restDetailQuery', restDetailQuery);

  /**
   * API -  정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation('/api/admin/site/insertSite');

  /**
   * API -  정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation('/api/admin/site/deleteSite');

  /**
   * API -  정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation('/api/admin/site/updateSite');

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {
    console.log('🚀 ~ handleRowClick ~ params', params);
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    // console.log('🚀 handleCellClick ~ params', params);
    const { data, colDef } = params;
    if (colDef?.field === 'siteNm' && data?.siteId) {
      // go Navigate
      navigate({
        pathname: '/cms/site',
        search: `?${createSearchParams({
          siteId: data.siteId,
        })}`,
      });

      // refetchDetail
      if (searchParams.get('siteId')) refetchDetail();

      // Open Drawer
      // if (!visible) handleDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDraw = bool => {
    setVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/site');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Row Selection Checked
   * @param {*} bool
   */
  const handleSelectionChanged = ({ api }) => {
    const _selectedRows = api.getSelectedRows();
    // console.log('🚀 ~ handleSelectionChanged ~ selectedRows', selectedRows);
    // Set SelectedRows
    setSelectedRows(_selectedRows);
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = values => {
    const sendDataValues = {
      ...values,
      emplyrNm: 'admin',
      actvtyAt: values.actvtyAt ? 'Y' : 'N',
      useAt: values.useAt ? 'Y' : 'N',
    };

    if (values.siteId) {
      // API Call - 정보수정
      updateMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
          // Refresh Site Store
          dispatch(fetchSites());
        },
      });
    } else {
      // API Call - 사이트 정보보등록
      addMutation.mutate(sendDataValues, {
        onSuccess: res => {
          // console.log('🚀 ~ addMutation ~ onSuccess: ~ res', res);
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
          // Refresh Site Store
          dispatch(fetchSites());
        },
      });
    }
  };

  /**
   * 사이트 정보삭제 처리
   */
  const handleDelete = () => {
    if (selectedRows && Array.isArray(selectedRows)) {
      if (selectedRows.length < 1) {
        Modal.warning({
          content: '삭제할 대상을 선택해주세요.',
        });
        return;
      }
      const siteIds = selectedRows.map(site => {
        return { siteId: site.siteId };
      });
      const sendDataValues = {
        siteList: siteIds,
      };

      // API Call - 사이트 삭제하기
      deleteMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '삭제 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // deselect
          if (gridApi) gridApi.deselectAll();

          // 사이트 목록 Store 값 변경
          dispatch(fetchSites());
        },
      });
    }
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    // console.log('🚀 ~ handleReset ~ _isEdit, detailData', _isEdit, detailData);
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    // console.log('🚀 ~ useEffect formData', formData);
    if (detail?.result) {
      const viewData = {
        ...detail.result,
        actvtyAt: detail.result.actvtyAt === 'Y',
        useAt: detail.result.useAt === 'Y',
      };
      // Set form Fileds value
      form.setFieldsValue({
        ...viewData,
      });
      // Open Drawer
      if (!visible) handleDraw(true);
      // 상세정보 세팅
      setDetailData({ ...viewData });
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [detail, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.resultList,
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    visible,
    detail,
    selectedRows,
    isEdit,
    searchOption,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onDraw: handleDraw,
    onGridReady: handleGridOption,
    onSelectionChanged: handleSelectionChanged,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
  };

  return <SiteView {...props} />;
}

export default Site;
