/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';

import common from './common/reducer';
import site from './site/reducer';
import menu from './menu/reducer';
import auth from './auth/reducer';
import keep from './keep/reducer';

const reducer = combineReducers({
  common,
  site,
  menu,
  auth,
  keep,
});

const persistConfig = {
  key: 'root',
  // SessionStorage에 저장합니다.
  storage: session,
  // reducer 중에 keep reducer만 localstorage에 저장합니다.
  whitelist: ['keep'],
  // blacklist -> 그것만 제외합니다
};

export default persistReducer(persistConfig, reducer);
