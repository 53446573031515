import React, { useState, useEffect } from 'react';
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from 'react-router-dom';
import { Button, Form, message, Modal } from 'antd';
import moment from 'moment';

import SurveyView from './SurveyView';
import { useMutation, useQuery } from '../../../hooks/useQuery';
import useStoreSite from '../../../hooks/useStoreSite';
import { GRID_LINK_CELL_STYLE } from '../../../config/contants';

// const sleep = ms => new Promise(r => setTimeout(r, ms));

// 상태
const statusMap = {
  W: '대기',
  P: '진행',
  E: '종료',
};

/**
 * 설문관리 > 설문지 관리
 * @returns
 */
function Survey() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  /**
   * @todo-사이트ID 확인 필요
   * store state - siteId
   */
  // const storeSiteId = useSelector(state => state.site.siteId, shallowEqual);
  const { siteId: storeSiteId } = useStoreSite();
  const SITE_ID = !storeSiteId
    ? process.env.REACT_APP_DEFAULT_SITE_ID
    : storeSiteId;

  // initial search values
  const initialSearchValues = {
    pageIndex: 1,
    pageSize: 10,
    recordCountPerPage: 10,
    siteId: SITE_ID,
  };

  /** query string */
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState(initialSearchValues);
  const [paging, setPaging] = useState();

  const [gridApi, setGridApi] = useState();
  const [columnDefs] = useState([
    {
      field: '',
      headerName: '',
      width: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'rnum',
      headerName: 'No',
      width: 60,
    },
    {
      field: 'qustnrSj',
      headerName: '제목',
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        ...GRID_LINK_CELL_STYLE,
        whiteSpace: 'normal',
        // lineHeight: '1.5rem',
      },
      width: 250,
    },
    {
      field: 'qustnrRange',
      headerName: '기간',
      width: 150,
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        whiteSpace: 'normal',
        //  lineHeight: '1.5rem'
      },
      cellRenderer: params => {
        const { data } = params;
        const { qustnrBgnde, qustnrEndde } = data;
        return `${qustnrBgnde && moment(qustnrBgnde).format('YYYY-MM-DD')}~
            ${qustnrEndde && moment(qustnrEndde).format('YYYY-MM-DD')}`;
      },
    },
    {
      field: 'progrsSttus',
      headerName: '상태',
      width: 100,
      cellRenderer: params => {
        const { value } = params;
        return value ? statusMap[value] : '';
      },
    },
    {
      field: 'useAt',
      headerName: '사용여부',
      width: 100,
      valueGetter: params => {
        const { data } = params;
        return data.useAt === 'Y' ? '사용' : '미사용';
      },
    },
    {
      field: 'qestnrChoice',
      headerName: '문항선택',
      width: 120,
      cellRenderer: params => {
        /* eslint react/no-unstable-nested-components:'off' */
        const { data } = params;
        const { qestnrId } = data;
        return (
          <Button size="small" onClick={() => setChoiceId(qestnrId)}>
            선택
          </Button>
        );
      },
    },
    { field: 'frstRegisterId', headerName: '등록자', width: 100 },
    {
      field: 'frstRegistPnttm',
      headerName: '등록일',
      width: 120,
      cellRenderer: params => {
        const { value } = params;
        return value ? moment(value).format('YYYY-MM-DD') : '';
      },
    },
  ]);

  const [visible, setVisible] = useState(false);

  // selectedRows
  const [selectedRows, setSelectedRows] = useState([]);

  // 수정여부
  const [isEdit, setIsEdit] = useState(false);

  // 상세정보
  const [detailData, setDetailData] = useState(null);

  // 검색 Option
  const [searchOption] = useState({
    initialValues: {
      searchCondition: '0',
      searchKeyword: '',
    },
    items: [
      {
        type: 'select',
        name: 'searchCondition',
        placeholder: '선택하세요.',
        codes: [{ value: '0', text: '제목' }],
      },
      {
        name: 'searchKeyword',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  const [choiceId, setChoiceId] = useState('');

  /**
   * API - 목록 조회
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery('/api/admin/question/info/selectList', search, {
    cacheTime: 0,
    enabled: !!search,
    onSuccess: res => {
      if (res) {
        setPaging(res.paginationInfo);
      }
    },
  });

  /**
   * SITE_ID(리덕스 Store)의 정보가 변경 될 시
   * 목록 리로드
   */
  useEffect(() => {
    if (!!SITE_ID && SITE_ID.length > 0) {
      setSearch(() => {
        return { ...initialSearchValues, siteId: SITE_ID };
      });
    }
  }, [SITE_ID]);

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/admin/question/info/select',
    { qestnrId: searchParams.get('qestnrId'), siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !!searchParams.get('qestnrId'),
    },
  );

  /**
   * API - 정보등록
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const addMutation = useMutation('/api/admin/question/info/save');

  /**
   * API - 정보삭제
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const deleteMutation = useMutation('/api/admin/question/info/delete');

  /**
   * API - 정보수정
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const updateMutation = useMutation('/api/admin/question/info/save');

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    setSearch(preSearch => {
      return { ...preSearch, pageIndex, pageSize };
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = search => {
    setSearch(() => {
      return { ...initialSearchValues, ...search };
    });
  };

  /**
   * 행 클릭
   * @param {*} row
   * @param {*} column
   */
  const handleRowClick = params => {
    console.log('🚀 ~ handleRowClick ~ params', params);
  };

  /**
   * Cell 클릭
   * @param {*} bool
   */
  const handleCellClick = params => {
    // console.log('🚀 handleCellClick ~ params', params);
    const { data, colDef } = params;
    if (colDef?.field === 'qustnrSj' && data?.qestnrId) {
      // go Navigate
      navigate({
        pathname: '/cms/survey',
        search: `?${createSearchParams({
          qestnrId: data.qestnrId,
        })}`,
      });

      // refetchDetail
      if (searchParams.get('qestnrId')) refetchDetail();

      // Open Drawer
      // if (!visible) handleDraw(true);
    }
  };

  /**
   * draw 핸들러
   * @param {*} bool
   */
  const handleDraw = bool => {
    setVisible(bool);
  };

  /**
   * Move List page
   */
  const handleMoveList = () => {
    // Move List
    navigate('/cms/survey');
  };

  /**
   * grid api 추가
   * @param {*} param0
   */
  const handleGridOption = ({ api }) => {
    api.sizeColumnsToFit();
    setGridApi(api);
  };

  /**
   * grid size changed
   * @param {*} param0
   */
  const handleGridSizeChanged = ({ api }) => {
    api.sizeColumnsToFit();
  };

  /**
   * Row Selection Checked
   * @param {*} bool
   */
  const handleSelectionChanged = ({ api }) => {
    const _selectedRows = api.getSelectedRows();
    // console.log('🚀 ~ handleSelectionChanged ~ selectedRows', selectedRows);
    // Set SelectedRows
    setSelectedRows(_selectedRows);
  };

  /**
   * Form Finish
   * @param {*} values
   */
  const handleFinish = values => {
    const dataValues = {
      ...values,
      qustnrBgnde: values.qustnrRange
        ? values.qustnrRange[0].format('YYYYMMDD')
        : '',
      qustnrEndde: values.qustnrRange
        ? values.qustnrRange[1].format('YYYYMMDD')
        : '',
      useAt: values.useAt ? 'Y' : 'N',
      siteId: SITE_ID,
    };

    //  return;
    if (isEdit) {
      const sendValues = {
        ...dataValues,
        lastUpdusrId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보수정
      updateMutation.mutate(sendValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '수정 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ updateMutation : error2', JSON.stringify(error));
        },
        onSettled: async () => {
          // console.log('🚀 ~ updateMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    } else {
      const sendValues = {
        ...dataValues,
        frstRegisterId: 'admin', // 필수항목 @TODO - 세션적용확인
      };
      // API Call - 정보등록
      addMutation.mutate(sendValues, {
        onSuccess: res => {
          // console.log('🚀 ~ addMutation ~ onSuccess: ~ res', res);
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '등록 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ addMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // console.log('🚀 ~ addMutation onSettled!!!');
          // Form Reset
          handleReset();
          // Close Drawer
          handleDraw(false);
          // Move List page
          handleMoveList();
        },
      });
    }
  };

  /**
   * 정보삭제 처리
   */
  const handleDelete = () => {
    if (selectedRows && Array.isArray(selectedRows)) {
      if (selectedRows.length < 1) {
        Modal.warning({
          content: '삭제할 대상을 선택해주세요.',
        });
        return;
      }
      const qestnrIds = selectedRows.map(item => {
        return {
          qestnrId: item.qestnrId,
        };
      });
      const sendDataValues = {
        qestnrInfos: qestnrIds,
      };

      // API Call - 정보 삭제하기
      deleteMutation.mutate(sendDataValues, {
        onSuccess: res => {
          if (res?.status === 200) {
            // Message
            Modal.success({
              content: '삭제 하였습니다.',
              onOk: () => {
                // Refetch - siteList
                refetchList();
              },
            });
          } else {
            const { status, statusText } = res;
            message.error(`[${status} - ${statusText}]`);
          }
        },
        onError: async error => {
          console.error('🚀 ~ deleteMutation : error2', JSON.stringify(error));
          // message.error(error.message);
        },
        onSettled: async () => {
          // deselect
          if (gridApi) gridApi.deselectAll();
        },
      });
    }
  };

  /**
   * Form Reset
   */
  const handleReset = (_isEdit = false) => {
    console.log('🚀 ~ handleReset ~ _isEdit, detailData', _isEdit, detailData);
    // 수정시
    if (_isEdit) {
      form.setFieldsValue({ ...detailData });
    } else {
      form.resetFields();
      // 수정여부 - 등록처리
      setIsEdit(false);
    }
  };

  const handleChoiceId = () => {
    setChoiceId('');
  };

  /** ***************************************************************
   *  useEffect
   *************************************************************** */
  useEffect(() => {
    // console.log('🚀 ~ useEffect formData', formData);
    if (detail?.result) {
      const { useAt, qustnrBgnde, qustnrEndde } = detail.result;
      const formData = {
        ...detail.result,
        useAt: useAt === 'Y',
        qustnrRange: [
          qustnrBgnde ? moment(qustnrBgnde) : null,
          qustnrEndde ? moment(qustnrEndde) : null,
        ],
      };
      // Set form Fileds value
      form.setFieldsValue({
        ...formData,
      });

      // Open Drawer
      if (!visible) handleDraw(true);
      // 상세정보 세팅
      setDetailData({ ...formData });
      // 수정여부-수정
      setIsEdit(true);
    }
  }, [detail, dataUpdatedAt, form]);

  /**
   * props 세팅
   */
  const props = {
    form,
    paging,
    list: data?.resultList,
    columnDefs,
    gridOptions: {
      rowSelection: 'multiple', // single, multiple
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      headerHeight: 40,
      // rowHeight: 40,
    },
    visible,
    // detail,
    selectedRows,
    isEdit,
    searchOption,
    choiceId,
    onPaging: handlePagination,
    onRowClick: handleRowClick,
    onCellClick: handleCellClick,
    onDraw: handleDraw,
    onGridReady: handleGridOption,
    onSelectionChanged: handleSelectionChanged,
    onFinish: handleFinish,
    onReset: handleReset,
    onSearch: handleSearch,
    onDelete: handleDelete,
    onMoveList: handleMoveList,
    onGridSizeChanged: handleGridSizeChanged,
    onChoiceId: handleChoiceId,
  };

  return <SurveyView {...props} />;
}

export default Survey;
